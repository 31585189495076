import { useState } from 'react';
import cx from 'classnames';
import { autoUpdate, flip, FloatingPortal, offset, useFloating, useHover, useInteractions } from '@floating-ui/react';

export default function FloatingTooltip({ children, contents, tooltipClassName }) {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [offset(12), flip()],
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context, {
    delay: {
      open: 200,
      close: 0,
    },
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            className={cx(tooltipClassName, 'bg-white rounded p-2 shadow z-50')}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {contents}
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
