import { isNil } from 'lodash';
import { formatMention } from './MentionedInDealNotification';

export default function NoteNotification({ notification, noteBody, notificationTitle, children }) {
  const { seenAt, property: { photoUrl } } = notification.attributes;

  return (
    <div className={`relative mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'} focus-within:bg-primary-focus`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} alt="" />
        <div className="flex flex-col flex-1">
          <div className="text-neutral-dark font-medium text-xl">
            {notificationTitle}
          </div>
          <div className="mt-2 flex h-4">
            <span className="w-0 flex-1 text-neutral-medium text-body-sm line-clamp-1">
              {formatMention(noteBody, { disableLinks: true })}
            </span>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
