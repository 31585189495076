import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressSearchModalIsOpen: false,
  newDealProperties: [],
};

const newDealSlice = createSlice({
  name: 'newDeal',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearNewDealProperties: state => {
      state.newDealProperties = [];
    },
    setAddressSearchModalIsOpen: (state, { payload }) => {
      state.addressSearchModalIsOpen = payload;
    },
    setNewDealProperties: (state, { payload }) => {
      state.newDealProperties = payload;
    },
    updateNewDealProperty: (state, { payload }) => {
      state.newDealProperties.map(ndp => ((ndp.id === payload.id) ? Object.assign(ndp, payload) : ndp));
    },
  },
});

export const {
  clearNewDealProperties,
  setAddressSearchModalIsOpen,
  setNewDealProperties,
  updateNewDealProperty,
} = newDealSlice.actions;
export default newDealSlice.reducer;
