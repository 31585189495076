import { upperCase } from 'lodash';
import classNames from 'classnames';
import Input from 'components/Input';
import Select from 'components/Select';

export function FormContainer({ children, className }) {
  return (
    <div className={`w-max mx-auto p-8 bg-white rounded shadow ${className || ''}`}>
      {children}
    </div>
  );
}

export function FormField({ className, label, name, children, ...inputProps }) {
  const { type, required } = inputProps;
  const labelText = label || upperCase(name);

  const inputComponent = children ?? (type === 'select'
    ? <Select name={name} width="w-full" {...inputProps} />
    : <Input name={name} type={type} width="w-full" {...inputProps} />);

  return (
    <div className={className}>
      <label
        className={classNames(
          'mb-1 text-xs font-medium tracking-wide uppercase flex flex-row justify-between',
          {
            'text-red-600': inputProps.errorMessage,
            'text-tertiary-darker': !inputProps.errorMessage,
          },
        )}
        htmlFor={name}
      >
        {labelText}
        {required ? <small className="text-red-400">* Required</small> : null}
      </label>
      {inputComponent}
    </div>
  );
}

export function InlineFormField({ className, label, name, ...inputProps }) {
  const { type, noLabel, required } = inputProps;
  const labelText = label || upperCase(name);

  return (
    <div className={`flex flex-row justify-center gap-x-4 ${className || ''}`}>
      {!noLabel && (
        <label className="w-1/3 my-auto text-xs font-medium text-tertiary-darker text-right tracking-wide uppercase whitespace-pre-line" htmlFor={name}>
          {labelText}
          {required ? <small className="block text-red-400">* Required</small> : null}
        </label>
      )}
      <div className="w-2/3">
        {type === 'select'
          ? <Select name={name} width="w-full" {...inputProps} />
          : <Input name={name} width="w-full" {...inputProps} />}
      </div>
    </div>
  );
}

export function InfoField({ children, className, label, value, valueClassName }) {
  return (
    <div className={className}>
      {label && (
        <label className="block mb-1 text-xs font-medium text-tertiary-darker tracking-wide uppercase">
          {upperCase(label)}
        </label>
      )}
      <div className={classNames('w-full', { 'text-right': !label })}>
        {children || <div className={valueClassName || 'text-gray-700'}>{value}</div>}
      </div>
    </div>
  );
}

export function InlineInfoField({ children, className, justify, label, value, valueClassName }) {
  return (
    <div className={`flex flex-row justify-center gap-x-4 ${className}`}>
      <label className="w-1/3 my-auto text-xs font-medium text-tertiary-darker text-right tracking-wide uppercase">
        {upperCase(label || '')}
      </label>
      <div className={`w-2/3 flex items-center gap-x-2 ${justify || 'justify-end'}`}>
        {children || <div className={valueClassName || 'text-gray-700'}>{value}</div>}
      </div>
    </div>
  );
}
