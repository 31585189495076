import cx from 'classnames';
import { formatHourAgo } from 'components/utils';

const NOTIFICATION_ACTION = Object.freeze({
  mention: 'Mentioned by',
  create: 'Added by',
});

export default function NotifiedBy({ notifiedAt, notifiedByName, notifiedThrough, className }) {
  return (
    <div className={cx('h-4 flex gap-x-2 items-center', className)}>
      <div className="text-neutral-light font-medium text-vxs ">
        {`${NOTIFICATION_ACTION[notifiedThrough]} ${notifiedByName} • ${formatHourAgo(notifiedAt)}`}
      </div>
    </div>
  );
}
