import { identity } from 'lodash';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice, blobResponseQuery } from 'redux/apiSlice';
import { DEAL_CONTEXT_TAG, HOME_BUILDERS_TAG, HOME_MODEL_IMAGES_TAG, HOME_MODELS_TAG, SUBDIVISIONS_TAG } from './utils';

const pagedQuery = (argCreator, api, extraOptions, baseQuery, endpointDefinition, originalArgs) => {
  const fetchNextPage = ([result, pages]) => {
    if (result.error) {
      return {
        ...result,
        error: (endpointDefinition.transformError ?? identity)(result.error, result.meta, originalArgs),
      };
    }

    if (result.data.data.length === 0) {
      return {
        ...result,
        data: (endpointDefinition.transformResponse ?? identity)({ ...result.data, data: pages.flat(1) }, result.meta, originalArgs),
      };
    }

    pages.push(result.data.data);
    const maxId = result.data.data.at(-1).id;
    return baseQuery(argCreator({ maxId }), api, extraOptions).then((nextResult) => (
      fetchNextPage([nextResult, pages])
    ));
  };

  return baseQuery(argCreator(), api, extraOptions).then((result) => (
    fetchNextPage([result, []])
  ));
};

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchHomeModel: builder.query({
        query: (id) => ({ url: `/api/home_models/${id}` }),
        transformResponse: response => camelCaseKeys(response, ['info']),
        providesTags: [HOME_MODELS_TAG, HOME_MODEL_IMAGES_TAG, SUBDIVISIONS_TAG, HOME_BUILDERS_TAG],
      }),
      fetchHomeModelDeals: builder.query({
        query: () => '/api/home_models/deals',
        transformResponse: response => camelCaseKeys(response),
        providesTags: [HOME_MODELS_TAG],
      }),
      fetchHomeModels: builder.query({
        queryFn(arg, api, extraOptions, baseQuery) {
          const { market } = arg ?? {};

          const argCreator = ({ maxId } = {}) => ({
            url: '/api/home_models',
            params: snakeCaseKeys({ market, maxId }),
          });

          return pagedQuery(argCreator, api, extraOptions, baseQuery, this, arg);
        },
        transformResponse: response => {
          const transformed = camelCaseKeys(response, ['info']).data.map(obj => ({
            ...obj.attributes,
            propertyId: parseInt(obj.id, 10),
            // TODO: alias fields necessary to work with existing DealSourcing/List filtering logic
            propertyBathroomsTotal: obj.attributes.bathrooms,
            propertyBedroomsTotal: obj.attributes.bedrooms,
            propertySubType: obj.attributes.homeType,
          }));

          // remove null values to be compatible with react-table's sortUndefined feature
          return JSON.parse(JSON.stringify(transformed, (_, v) => (v === null ? undefined : v)));
        },
        providesTags: [HOME_MODELS_TAG, HOME_MODEL_IMAGES_TAG, SUBDIVISIONS_TAG, HOME_BUILDERS_TAG],
      }),
      fetchHomeModelImportTemplateCsv: builder.query({
        query: (homeBuilderId) => ({
          url: `/api/home_models/download_import_template?home_builder_id=${homeBuilderId}`,
          responseHandler: response => (response.ok ? response.blob() : response.json()),
        }),
        ...blobResponseQuery,
      }),
      fetchHomeModelsPipelineStatus: builder.query({
        query: ({ portfolio: { id: portfolioId }, subdivision: { id: subdivisionId } = {} }) => ({
          url: '/api/home_models/pipeline_status',
          params: snakeCaseKeys({ portfolioId, subdivisionId }),
        }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [DEAL_CONTEXT_TAG],
      }),
      fetchHomeModelImportStats: builder.query({
        query: () => '/api/home_models/import_stats',
        transformResponse: response => camelCaseKeys(response),
      }),
      postHomeModelCsvData: builder.mutation({
        query: ({ file, clearSubdivisionInventory, homeBuilderId }) => {
          const formData = new FormData();
          formData.append('file', file);

          return ({
            url: `/api/home_models/upload_csv_data?home_builder_id=${homeBuilderId}&clear_subdivision_inventory=${clearSubdivisionInventory}`,
            method: 'POST',
            // NOTE: We need to specify headers as empty object or this request will fail.
            //       Apparently it is due to a bug in fetch library (which apiSlice uses)
            //       when sending form data even if you specify 'multipart/form-data'.
            headers: {},
            body: formData,
          });
        },
        transformResponse: response => camelCaseKeys(response, ['homeModel', 'subdivision']),
      }),
      fetchHomeModelValuations: builder.query({
        query: (portfolioId) => ({ url: `/api/home_models/valuations?portfolio_id=${portfolioId}` }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: ['HomeModelValuations'],
      }),
      confirmHomeModelCsv: builder.mutation({
        query: ({ batchId, homeBuilderId }) => ({
          url: `/api/home_models/confirm_upload?home_builder_id=${homeBuilderId}`,
          method: 'POST',
          body: snakeCaseKeys({ batchId }),
        }),
        invalidatesTags: [HOME_MODELS_TAG],
      }),
      updateHomeModels: builder.mutation({
        query: ({ homeModels }) => ({
          url: '/api/home_models',
          method: 'POST',
          body: snakeCaseKeys({ homeModels }, ['info']),
        }),
        invalidatesTags: [HOME_MODELS_TAG],
      }),
      fetchHomeModelUnderwritingParameters: builder.query({
        query: () => '/api/home_models/underwriting_parameters',
        transformResponse: response => camelCaseKeys(response),
      }),
    };
  },
});

export const {
  useFetchHomeModelDealsQuery,
  useFetchHomeModelQuery,
  useFetchHomeModelImportStatsQuery,
  useFetchHomeModelsQuery,
  useFetchHomeModelValuationsQuery,
  useFetchHomeModelsPipelineStatusQuery,
  useFetchHomeModelUnderwritingParametersQuery,
  useLazyFetchHomeModelImportTemplateCsvQuery,
  usePostHomeModelCsvDataMutation,
  useConfirmHomeModelCsvMutation,
  useUpdateHomeModelsMutation,
} = extendedApiSlice;
