import {
  PureComponent,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { createPortal } from 'react-dom';
import { setAddressSearchModalIsOpen } from 'redux/newDealSlice';
import classNames from 'classnames';
import { Check } from 'components/icons';
import AddressSearchModal from 'components/DealSourcing/AddressSearchModal';
import { debounce, difference, isEmpty, isEqual, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchDealStatusesQuery } from 'redux/apiSlice';
import {
  CHANNEL_LISTED,
  CHANNEL_NEW_BUILD,
  CHANNEL_OFF_MARKET,
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
} from 'components/constants';
import { censusTractsInBounds, censusTractsShape, neighborhoodsInBounds, neighborhoodsShape } from 'components/routes';
import { camelCaseKeys, simpleDealStatus } from 'components/utils';
import { ALL_MARKETS, HOME_MODEL_ID_PREFIX } from './dealSourcing';
import HomeModelInfoWindow from './HomeModelInfoWindow';
import PropertyInfoWindow from './PropertyInfoWindow';
import {
  BOUNDS_TOLERANCE,
  DATA_LABELS,
  getRectangleArea,
  setCrimeRateColor,
  setDefaultColor,
  setHeatMapColor,
  setHouseholdGrowthRateColor,
  setMedHomeIncomeColor,
  setMedHomeValueColor,
  setOOHUGrowthRateColor,
  setPerCapitaIncomeColor,
  setPerCapitaIncomeGrowthRateColor,
  setPopDensityColor,
  setPopGrowthColor,
  setPropertyCrimeIndexColor,
  setRenterOccupiedHousingUnitsColor,
  setUnemploymentRateColor,
  setVacantHousingUnitsColor,
} from './map_utils';
import {
  closedPropertyIconOptions,
  deadIconOptions,
  focusedDeadIconOptions,
  focusedIconOptions,
  focusedPropertyIconOptions,
  MARKER_PIN_HEIGHT,
  markerIconOptions,
  offMarketIconOptions,
  pipelinePropertyIconOptions,
  useRenderMarkers,
} from './MapSettings';

const EMPTY_OBJ = {};

const DEBOUNCE_WAIT_TIME = 500;
const MAX_MAP_ZOOM = 20;
const MAX_DRAWN_AREA = 4_000_000;
const CENSUS_TRACT_PAGE_SIZE = 20;
const MARKER_LIMIT = 5_000;
const ANIMATE_CLASS_NAME = 'animate-pulse';
const PORTFOLIO_PROPERTY_Z_INDEX = window.google ? window.google.maps.Marker.MAX_ZINDEX + 1 : 1;
const FOCUSED_Z_INDEX = PORTFOLIO_PROPERTY_Z_INDEX + 1;
const INFO_WINDOW_OFFSET = window.google ? new window.google.maps.Size(0, -(MARKER_PIN_HEIGHT.md + 4)) : 0;

// these need to be updated every time we add a new market to the platform
// try: https://www.latlong.net/
const MARKET_CENTERS_LAT_LNG = {
  'Atlanta': [33.748997, -84.387985],
  'Birmingham': [33.518589, -86.810356],
  'Charlotte': [35.2031535, -80.8398289],
  'Cincinnati': [39.103119, -84.512016],
  'Columbus': [39.961178, -82.998795],
  'Dallas': [32.776665, -96.796989],
  'Indianapolis': [39.768403, -86.158068],
  'Jacksonville': [30.3515543, -81.7552011],
  'Kansas City': [39.099728, -94.578568],
  'Los Angeles': [34.0522, -118.2437],
  'Memphis': [35.1495343, -90.0489801],
  'Nashville': [36.1627, -86.7816],
  'Oklahoma City': [35.4827589, -97.8088521],
  'Orlando': [28.4814032, -81.4827496],
  'San Antonio': [29.4581674, -98.8440293],
  'San Diego': [32.7157, -117.1611],
  'Tampa': [27.9947147, -82.5943649],

  // https://en.wikipedia.org/wiki/Geographic_center_of_the_United_States#Contiguous_United_States
  [ALL_MARKETS]: [39.833, -98.583, 4],
};

const DEMOGRAPHIC_OPTIONS = [
  { value: 'MHIGRWCYFY', label: 'HH Income Growth' },
  { value: 'POPGRWCYFY', label: 'Population Growth' },
  { value: 'POPDENS_CY', label: 'Population Density' },
  { value: 'MEDVAL_CY', label: 'Median Household Value' },
  { value: 'MEDHINC_CY', label: 'Median Household Income' },
  { value: 'PCI_CY', label: 'Per Capita Income' },
  { value: 'RENTER_CY', label: 'Renter Occupied Housing Units' },
  { value: 'VACANT_CY', label: 'Vacant Housing Units' },
  { value: 'HHGRWCYFY', label: 'Growth Rate: Households' },
  { value: 'PCIGRWCYFY', label: 'Growth Rate: Per Capita Income' },
  { value: 'OWNGRWCYFY', label: 'Growth Rate: Owner Occupied Housing Units' },
  { value: 'UNEMPRT_CY', label: 'Unemployment Rate' },
  { value: 'CRMCYTOTC', label: 'Crime Rate' },
  { value: 'CRMCYPROC', label: 'Property Crime Index' },
];

const PRE_RENDER_MARKERS = {
  markerIconOptions,
  offMarketIconOptions,
  deadIconOptions,
  focusedDeadIconOptions,
  focusedIconOptions,
};

const selectDealStatus = createSelector(
  ({ currentData }) => currentData,
  (currentData) => ({ currentData: currentData || EMPTY_OBJ }),
);

function DemographicDropdown({ demographic, handleDemographicChange }) {
  return (
    <ul className="mt-0.5 bg-white z-10 ">
      {DEMOGRAPHIC_OPTIONS.map((option) => (
        <li
          key={option.value}
          className="text-sm px-4 py-2 flex cursor-pointer hover:bg-gray-100"
          onClick={() => handleDemographicChange(option)}
        >
          {option === demographic && (
            <Check className="w-5 h-5 mr-2" aria-hidden="true" />
          )}
          {option.label}
        </li>
      ))}
    </ul>
  );
}

function CensusTractDemographicDataWindow({ censusTractData }) {
  if (!censusTractData) return null;

  const censusTractDataKeys = Object.keys(censusTractData);
  return (
    <div className="absolute bottom-2 left-2 bg-white z-30 px-4 py-3.5 rounded-lg text-neutral">
      <ul className="w-90">
        {censusTractDataKeys.map((key) => (
          <li
            key={key}
            className="flex gap-x-24 text-xs mb-2.5 justify-between"
          >
            <span className="uppercase">{DATA_LABELS[key][0]}</span>
            <span className="font-semibold">
              {DATA_LABELS[key][1](censusTractData[key])}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function MapButton({ active, className, label, onClick, icon }) {
  const clazz = classNames(
    'w-max px-3 py-2.5 border text-xs font-light focus:outline-none',
    className,
    {
      'bg-primary-100 hover:bg-primary-200 active:bg-primary-300 border-primary-500': active,
      'bg-white hover:bg-gray-100 active:bg-gray-200': !active,
    },
  );
  return (
    <button type="button" className={clazz} onClick={onClick}>
      <span className="flex gap-x-2">
        {label}
        {icon && (icon)}
      </span>
    </button>
  );
}

function Map({
  items,
  filters,
  setFilters,
  settings: { channel, market },
  listRef,
  setListHeading,
  selectedItem,
  setSelectedItem,
  setIdVisibilityMap,
  portfolio,
}, ref) {
  const { currentData: dealStatus } = useFetchDealStatusesQuery(portfolio.id, { selectFromResult: selectDealStatus });
  const filteredDeals = useMemo(
    () => Object.values(dealStatus).filter(deal => {
      const markets = uniq(deal.properties.map(p => p.market));
      const marketMatches = market === ALL_MARKETS || markets.includes(market);
      const dealStageMatches = filters.pipeline?.includes(simpleDealStatus(deal));
      return dealStageMatches && marketMatches;
    }),
    [dealStatus, filters, market],
  );
  const { markersReady, renderedMarkers } = useRenderMarkers({ markers: PRE_RENDER_MARKERS });
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [censusTractVisible, setCensusTractVisible] = useState(false);
  const [neighborhoodVisible, setNeighborhoodVisible] = useState(false);
  const [demographic, setDemographic] = useState('');
  const [censusTractData, setCensusTractData] = useState(null);
  const { addressSearchModalIsOpen } = useSelector(state => state.newDeal);

  useImperativeHandle(
    ref,
    () => ({
      setMarkerFocused: canvasRef.current?.setMarkerFocused,
      selectedItemChanged: canvasRef.current?.selectedItemChanged,
    }),
    [],
  );

  useEffect(() => {
    if (canvasRef.current && items.length && markersReady) {
      let markerItems = items;

      // remove portfolio properties
      // so they're not drawn twice
      if (!isEmpty(filteredDeals)) {
        const dealIds = new Set();

        filteredDeals.forEach(({ id, stage }) => {
          if (stage === DEAL_STATE_DEAD) {
            return;
          }
          dealIds.add(id);
        });

        // markers that correspond to deals covered by portfolioDataLayer should be removed
        markerItems = markerItems.filter(({ dealId }) => !dealIds.has(dealId));
      }

      const drawMarkers = () => {
        canvasRef.current.control('draw_markers', markerItems);
        canvasRef.current.control('set_default_bounds');
      };

      if (typeof window.requestIdleCallback === 'function') {
        requestIdleCallback(drawMarkers);
      } else {
        // safari doesn't support requestIdleCallback
        drawMarkers();
      }
    } else if (canvasRef.current && !items.length) {
      // we use the pan_to_market_center to make sure we render the map even if there are no items
      if (!filters.area && !filters.circle && !filters.polygon) {
        canvasRef.current.control('pan_to_market_center', market);
      } else {
        // if shape filter is active, show the shape filter
        canvasRef.current.control('set_default_bounds');
      }
      canvasRef.current.clearMarkers();
    }
  }, [items, filteredDeals, markersReady, market]);

  useEffect(() => {
    if (canvasRef.current) {
      if (!isEmpty(filteredDeals)) {
        canvasRef.current.drawPortfolioLayer(filteredDeals);
      } else {
        canvasRef.current.clearPortfolioLayer();
      }
    }
  }, [filteredDeals, filters]);

  useEffect(() => {
    if (canvasRef.current && (channel === CHANNEL_OFF_MARKET)) {
      canvasRef.current.clearMarkers();
      canvasRef.current.control('pan_to_market_center', market);
    }
  }, [market, channel]);

  useEffect(() => {
    if (canvasRef.current && (!filters.area && !filters.circle && !filters.polygon)) {
      canvasRef.current.removeShapeFilter();
      canvasRef.current.hideClearBtn();
    }
  }, [filters.area, filters.circle, filters.polygon]);

  useEffect(() => {
    if (canvasRef.current && demographic) {
      canvasRef.current.control('set_demographic', demographic.value);
    }
  }, [demographic]);

  useEffect(() => {
    if (canvasRef.current && filteredDeals) {
      canvasRef.current.control('set_filtered_deals', filteredDeals);
    }
  }, [filteredDeals]);

  const toggleCensusVisibility = () => {
    setCensusTractVisible(!censusTractVisible);
  };

  const handleDemographicChange = (option) => {
    setDemographic(option);
    canvasRef.current.control('set_neighborhood_visibility', false);
    setNeighborhoodVisible(false);
    canvasRef.current.control('set_census_visibility', true);
    setCensusTractVisible(!censusTractVisible);
  };

  const toggleNeighborhoodVisibility = () => {
    canvasRef.current.control('set_census_visibility', false);
    setCensusTractVisible(false);
    canvasRef.current.control('set_neighborhood_visibility', !neighborhoodVisible);
    setNeighborhoodVisible(!neighborhoodVisible);
  };

  const clearOverlays = () => {
    setDemographic('');
    canvasRef.current.control('set_census_visibility', false);
    setCensusTractVisible(false);
    canvasRef.current.control('set_neighborhood_visibility', false);
    setNeighborhoodVisible(false);
  };

  const filterSetters = useMemo(() => {
    const updateFilter = update => setFilters(prevState => {
      const updated = { ...prevState, ...update };
      return isEqual(updated, prevState) ? prevState : updated;
    });

    return {
      setAreaFilter: (area) => updateFilter({ area, circle: null, polygon: null }),
      setPolygonFilter: (polygon) => updateFilter({ area: null, circle: null, polygon }),
      setCircleFilter: (circle) => updateFilter({ area: null, circle, polygon: null }),
      resetAreaFilters: () => updateFilter({ area: null, circle: null, polygon: null }),
    };
  }, [setFilters]);

  const [infoWindowContainer] = useState(() => document.createElement('div'));
  const [infoWindowItem, setInfoWindowItem] = useState();
  const InfoWindowComponent = channel === CHANNEL_NEW_BUILD ? HomeModelInfoWindow : PropertyInfoWindow;

  return (
    <div className="w-full relative">
      {/* TODO: uncomment when census data/neighborhood boundary are available */}
      {/* <div className="absolute top-1.5 left-0 right-0 w-max mx-auto z-10"> */}
      {/*   <div className="mx-auto w-max"> */}
      {/*     <MapButton */}
      {/*       label={demographic ? demographic.label : 'Census Tracts'} */}
      {/*       className={`rounded-l ${demographic ? 'font-semibold' : ''}`} */}
      {/*       active={censusTractVisible} */}
      {/*       onClick={toggleCensusVisibility} */}
      {/*       icon={<Chevron direction={censusTractVisible ? 'up' : 'down'} className="h-4" />} */}
      {/*     /> */}
      {/*     <MapButton label="Neighborhoods" active={neighborhoodVisible} onClick={toggleNeighborhoodVisibility} /> */}
      {/*     <MapButton label="None" className="rounded-r" onClick={clearOverlays} /> */}
      {/*     {censusTractVisible && <DemographicDropdown demographic={demographic} handleDemographicChange={handleDemographicChange} />} */}
      {/*   </div> */}
      {/* </div> */}
      <div className="absolute top-1.5 right-2 z-10">
        <MapButton label="Address Search" className="rounded-lg px-6 py-2.5 text-neutral text-sm font-normal" onClick={() => dispatch(setAddressSearchModalIsOpen(true))} />
        {addressSearchModalIsOpen && <AddressSearchModal />}
      </div>
      {censusTractData && <CensusTractDemographicDataWindow censusTractData={censusTractData} />}
      <MapCanvas
        ref={canvasRef}
        listRef={listRef}
        filters={filters}
        channel={channel}
        setCensusTractData={setCensusTractData}
        setAreaFilter={filterSetters.setAreaFilter}
        setPolygonFilter={filterSetters.setPolygonFilter}
        setCircleFilter={filterSetters.setCircleFilter}
        resetAreaFilters={filterSetters.resetAreaFilters}
        setListHeading={setListHeading}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setIdVisibilityMap={setIdVisibilityMap}
        markers={renderedMarkers}
        infoWindowContent={infoWindowContainer}
        setInfoWindowItem={setInfoWindowItem}
      />
      {createPortal(<InfoWindowComponent item={infoWindowItem} />, infoWindowContainer)}
    </div>
  );
}

export default forwardRef(Map);

/*
 * !important: rendered once, never re-rendered
 * passing dynamic props, or implementing internal state could result in map malfunction
 */
class MapCanvas extends PureComponent {
  containerRef = null;

  initialRender = true;

  loadedCensusGids = [];

  loadedNeighborhoodIds = [];

  currentGroupId = null;

  markerMap = new window.Map();

  censusVisibility = false;

  getCensusShapeStyle = setDefaultColor;

  neighborhoodVisibility = false;

  censusDataLayer = new window.google.maps.Data();

  neighborhoodDataLayer = new window.google.maps.Data();

  items = [];

  portfolioDataLayer = null;

  shapefilter = null;

  market = null;

  filteredDeals = null;

  styles = {
    default: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          { saturation: '100' },
          { lightness: '-14' },
        ],
      },
    ],
    show: [],
  };

  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.debouncedLoadInboundCensusTractData = debounce(this.loadInboundCensusTractData, DEBOUNCE_WAIT_TIME);
    this.debouncedLoadInboundNeighborhoodData = debounce(this.loadInboundNeighborhoodData, DEBOUNCE_WAIT_TIME);
  }

  componentDidMount() {
    this.initMap();
  }

  control = (action, payload) => {
    switch (action) {
      case 'draw_markers': {
        this.drawMarkers(false, payload);
        break;
      }
      case 'set_default_bounds': {
        this.setBounds(null);
        break;
      }
      case 'remove_shape_filter': {
        this.removeShapeFilter();
        break;
      }
      case 'set_styles': {
        this.map.setOptions({ styles: this.styles[payload ? 'show' : 'default'] });
        break;
      }
      case 'set_census_visibility': {
        this.forceReRender();
        this.censusDataLayer.setMap(payload ? this.map : null);
        this.censusVisibility = payload;
        break;
      }
      case 'set_sort': {
        this.forceReRender();
        break;
      }
      case 'set_demographic': {
        if (payload) {
          this.forceReRender();
          if (payload === 'MHIGRWCYFY') {
            this.getCensusShapeStyle = setHeatMapColor;
          } else if (payload === 'POPGRWCYFY') {
            this.getCensusShapeStyle = setPopGrowthColor;
          } else if (payload === 'POPDENS_CY') {
            this.getCensusShapeStyle = setPopDensityColor;
          } else if (payload === 'MEDVAL_CY') {
            this.getCensusShapeStyle = setMedHomeValueColor;
          } else if (payload === 'MEDHINC_CY') {
            this.getCensusShapeStyle = setMedHomeIncomeColor;
          } else if (payload === 'PCI_CY') {
            this.getCensusShapeStyle = setPerCapitaIncomeColor;
          } else if (payload === 'RENTER_CY') {
            this.getCensusShapeStyle = setRenterOccupiedHousingUnitsColor;
          } else if (payload === 'VACANT_CY') {
            this.getCensusShapeStyle = setVacantHousingUnitsColor;
          } else if (payload === 'HHGRWCYFY') {
            this.getCensusShapeStyle = setHouseholdGrowthRateColor;
          } else if (payload === 'PCIGRWCYFY') {
            this.getCensusShapeStyle = setPerCapitaIncomeGrowthRateColor;
          } else if (payload === 'OWNGRWCYFY') {
            this.getCensusShapeStyle = setOOHUGrowthRateColor;
          } else if (payload === 'UNEMPRT_CY') {
            this.getCensusShapeStyle = setUnemploymentRateColor;
          } else if (payload === 'CRMCYTOTC') {
            this.getCensusShapeStyle = setCrimeRateColor;
          } else if (payload === 'CRMCYPROC') {
            this.getCensusShapeStyle = setPropertyCrimeIndexColor;
          }
          this.censusDataLayer.setStyle(this.getCensusShapeStyle);
        } else {
          this.getCensusShapeStyle = setDefaultColor;
          this.censusDataLayer.setStyle(this.getCensusShapeStyle);
        }
        break;
      }
      case 'set_neighborhood_visibility': {
        // hack to force a re-render
        this.map.panBy(1, 0);
        this.map.panBy(-1, 0);
        this.neighborhoodDataLayer.setMap(payload ? this.map : null);
        this.neighborhoodVisibility = payload;
        break;
      }
      case 'pan_to_market_center': {
        const [lat, lng, zoom = 10] = MARKET_CENTERS_LAT_LNG[payload] ?? [];
        if (Number.isFinite(lat) && Number.isFinite(lng)) {
          this.map.setCenter({ lat, lng });
          this.map.setZoom(zoom);
        }

        this.market = payload;
        break;
      }
      case 'set_filtered_deals': {
        this.filteredDeals = payload;
        break;
      }
      default:
        break;
    }
  };

  initMap = () => {
    this.map = new google.maps.Map(this.containerRef.current, {
      maxZoom: MAX_MAP_ZOOM,
      center: new google.maps.LatLng(39.8097343, -98.5556199),
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_LEFT,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
      },
      fullscreenControl: true,
      fullscreenControlOptions: { position: google.maps.ControlPosition.BOTTOM_RIGHT },
      gestureHandling: 'greedy',
    });

    this.setDataLayerBehaviors();
    this.initializeAreaSelector();
    // listen on idle instead of bounds_changed
    // bounds_changed fires too frequently
    this.map.addListener('idle', this.handleMapZoomPan);
    this.map.setOptions({ styles: this.styles.default });
  };

  drawMarkers = (fitBounds, items) => {
    const {
      channel,
      markers,
      infoWindowContent,
      selectedItem,
      setInfoWindowItem,
    } = this.props;

    const newMarkers = this.cleanMarkers(items);
    newMarkers.forEach(item => {
      const { id, latitude, longitude, dealStatus } = item;

      const markerId = channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${id}` : id;
      const isHighlighted = markerId === (selectedItem?.id ?? '');
      const isDead = DEAL_STATE_DEAD === dealStatus;

      let unfocusedIcon;
      if (isDead) {
        unfocusedIcon = markers.deadIconOptions;
      } else if ([CHANNEL_LISTED, CHANNEL_NEW_BUILD].includes(channel)) {
        unfocusedIcon = markers.markerIconOptions;
      } else {
        unfocusedIcon = markers.offMarketIconOptions;
      }

      let focusedIcon;
      if (isDead) {
        focusedIcon = markers.focusedDeadIconOptions;
      } else {
        focusedIcon = markers.focusedIconOptions;
      }

      const markerOpts = {
        position: {
          lat: latitude,
          lng: longitude,
        },
        icon: isHighlighted ? focusedIcon : unfocusedIcon,
        unfocusedIcon,
        focusedIcon,
        optimized: true,
        visible: false,
        map: this.map,
        anchorPoint: new google.maps.Point(0, 0),
      };
      const marker = new google.maps.Marker(markerOpts);
      let infoWindow;
      marker.addListener('mouseover', () => {
        setInfoWindowItem(item);
        this.setMarkerFocused(markerId, true);

        infoWindow ??= new google.maps.InfoWindow({
          content: infoWindowContent,
          disableAutoPan: true,
          pixelOffset: INFO_WINDOW_OFFSET,
        });
        infoWindow.open(null, marker);
      });
      marker.addListener('mouseout', () => {
        infoWindow?.close();
        setInfoWindowItem(null);
        this.setMarkerFocused(markerId, false);
      });
      marker.addListener('click', () => {
        this.markerOnClick(markerId);
      });
      this.markerMap.set(markerId, marker);
    });

    // needs to trigger in order to show markers
    if ((fitBounds || this.initialRender) && newMarkers.length) {
      this.setBounds(null);
      this.initialRender = false;
    }

    this.items = items;
  };

  cleanMarkers = (items) => {
    const { channel } = this.props;
    const markerIds = items.map(({ id }) => (channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${id}` : id));
    const existingMarkerIds = [...this.markerMap.keys()];
    const newMarkerIds = difference(markerIds, existingMarkerIds);
    const deprecatedMarkerIds = difference(existingMarkerIds, markerIds);

    deprecatedMarkerIds.forEach(markerId => {
      this.markerMap.get(markerId).setMap(null);
      this.markerMap.delete(markerId);
    });

    return items.filter(({ id }) => newMarkerIds.includes(channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${id}` : id));
  };

  clearMarkers = () => {
    this.markerMap.forEach(marker => marker.setMap(null));
    this.markerMap.clear();
  };

  markerOnClick = (id) => {
    const { listRef, setSelectedItem } = this.props;
    setSelectedItem({ id });
    listRef.current.scrollToProperty(id, 'center');

    // use setTimeout to give the browser time to scroll
    setTimeout(() => {
      const el = document.querySelector(`#items .item[data-id='${id}']`);
      if (el) {
        el.classList.add(ANIMATE_CLASS_NAME);
        setTimeout(() => el.classList.remove(ANIMATE_CLASS_NAME), 3000);
      }
    });
  };

  selectedItemChanged = (prevValue, newValue) => {
    const prevId = prevValue?.id;
    const idToHighlight = newValue?.id;

    if (prevId === idToHighlight) {
      // no-op
      return;
    }

    // un-highlight the currently highlighted marker then highlight the new marker
    const prevHighlightedMarker = this.getMarker(prevId);
    if (prevHighlightedMarker) {
      if (prevHighlightedMarker instanceof google.maps.Marker) {
        prevHighlightedMarker.setIcon(prevHighlightedMarker.get('unfocusedIcon'));
      } else if (prevHighlightedMarker instanceof google.maps.Data.Feature) {
        this.portfolioDataLayer?.revertStyle(prevHighlightedMarker);
      }
    }

    this.setMarkerFocused(idToHighlight, true);
  };

  getMarker = (id) => (
    this.portfolioDataLayer?.getFeatureById(id) ?? this.markerMap.get(id)
  );

  drawPortfolioLayer = (filteredDeals) => {
    const updatedLayer = new google.maps.Data({
      style: feature => ({
        icon: (feature.getProperty('pipelineActive') ? pipelinePropertyIconOptions : closedPropertyIconOptions)[feature.getProperty('propertyType')],
        zIndex: PORTFOLIO_PROPERTY_Z_INDEX,
      }),
    });

    // keep track of active properties that have been drawn
    // so that in case the portfolio contains a property more than once,
    // an inactive deal doesn't overwrite an active deal
    // but allow an active deal to be drawn over an inactive deal
    const pipelineActiveProperties = new Set();

    filteredDeals?.forEach(({ stage, properties = [] }) => {
      // do not include dead deals
      if (stage === DEAL_STATE_DEAD) {
        return;
      }

      properties.forEach(({ id, subdivision, subdivisionId, address, latitude, longitude, singleFamily }) => {
        const featureId = this.props.channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${subdivisionId}` : id;

        // skip if
        // 1. no coordinates
        // 2. already drawn an active deal
        if (!latitude || !longitude || pipelineActiveProperties.has(featureId)) {
          return;
        }

        const pipelineActive = stage !== DEAL_STATE_CLOSED;
        if (pipelineActive) {
          pipelineActiveProperties.add(featureId);
        }

        updatedLayer.add({
          id: featureId,
          geometry: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          properties: {
            data: this.props.channel === CHANNEL_NEW_BUILD ? subdivision : { streetAddress: address },
            pipelineActive,
            propertyType: singleFamily ? 'SF' : 'MF',
          },
        });
      });
    });

    const infoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
      pixelOffset: INFO_WINDOW_OFFSET,
    });

    updatedLayer.addListener('mouseover', ({ feature }) => {
      this.props.setInfoWindowItem(feature.getProperty('data'));
      this.setMarkerFocused(feature.getId(), true);

      infoWindow.setPosition(feature.getGeometry().get());
      infoWindow.setContent(this.props.infoWindowContent);
      infoWindow.open({ map: this.map });
    });

    updatedLayer.addListener('mouseout', ({ feature }) => {
      this.setMarkerFocused(feature.getId(), false);
      infoWindow.close();
      this.props.setInfoWindowItem(null);
    });

    updatedLayer.addListener('click', ({ feature }) => {
      this.markerOnClick(feature.getId());
    });

    this.clearPortfolioLayer();
    this.portfolioDataLayer = updatedLayer;
    updatedLayer.setMap(this.map);
  };

  clearPortfolioLayer = () => {
    // release the previous layer
    this.portfolioDataLayer?.setMap(null);
  };

  setMarkerFocused = (id, focused) => {
    // no-op for highlighted property
    if (id === this.props.selectedItem?.id) {
      return;
    }

    const pin = this.getMarker(id);
    if (!pin) {
      console.warn(`Marker id=[${id}] not found`);
      return;
    }

    if (pin instanceof google.maps.Marker) {
      if (focused) {
        pin.setIcon(pin.get('focusedIcon'));
        pin.setZIndex(FOCUSED_Z_INDEX);
      } else {
        pin.setIcon(pin.get('unfocusedIcon'));
        pin.setZIndex(null);
      }
    } else if (pin instanceof google.maps.Data.Feature) {
      if (focused) {
        this.portfolioDataLayer?.revertStyle(pin);
        this.portfolioDataLayer?.overrideStyle(pin, {
          icon: focusedPropertyIconOptions[pin.getProperty('propertyType')],
          zIndex: FOCUSED_Z_INDEX,
        });
      } else {
        this.portfolioDataLayer?.revertStyle(pin);
      }
    }
  };

  setBounds = (coords) => {
    const bounds = new google.maps.LatLngBounds();
    if (coords) {
      bounds.extend(new google.maps.LatLng(+coords[0], +coords[1]));
      bounds.extend(new google.maps.LatLng(+coords[2], +coords[3]));
    } else {
      this.markerMap.forEach(marker => bounds.extend(marker.position));
      if (this.shapefilter) {
        const shapeFilterBounds = this.shapefilter.getBounds?.();
        if (shapeFilterBounds) {
          bounds.union(shapeFilterBounds);
        } else if (this.shapefilter.getPaths) {
          this.shapefilter.getPaths().forEach((path) => path.forEach((pt) => bounds.extend(pt)));
        } else {
          console.warn('Unknown shapefilter', this.shapefilter);
        }
      }
      // expand bounds for properties in the filtered market that are part of live deals
      this.filteredDeals?.filter(({ stage }) => stage !== DEAL_STATE_DEAD)?.forEach(({ stage, properties }) => {
        if (stage === DEAL_STATE_DEAD) {
          return;
        }
        properties.filter(({ market }) => market === this.market).forEach(({ latitude, longitude }) => {
          if (latitude && longitude) {
            bounds.extend({ lat: Number(latitude), lng: Number(longitude) });
          }
        });
      });
    }
    this.map.fitBounds(bounds, 0);
    return true;
  };

  setDataLayerBehaviors = () => {
    const { setCensusTractData } = this.props;
    [this.censusDataLayer, this.neighborhoodDataLayer].forEach(layer => {
      layer.addListener('mouseover', ({ feature }) => {
        if (feature) {
          setCensusTractData(feature.getProperty('data')[0]);
        }
      });

      layer.addListener('mouseover', (event) => {
        layer.revertStyle();
        layer.overrideStyle(event.feature, { strokeWeight: 2, fillOpacity: 0.7 });
      });
      layer.addListener('mouseout', (event) => {
        layer.revertStyle();
      });

      layer.addListener('mouseout', () => {
        setCensusTractData(null);
      });
    });
  };

  defaultDrawOptions = (extra = {}) => ({
    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeWeight: 3,
    clickable: false,
    editable: false,
    zIndex: 1,
    ...extra,
  });

  createClearBtn = () => {
    const controlUI = document.createElement('div');

    controlUI.style.backgroundColor = '#fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '1px 1px 5px rgba(0,0,0,.2)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '5px';
    controlUI.style.marginLeft = '-5px';
    controlUI.style.padding = '5px';
    controlUI.style.textAlign = 'center';
    controlUI.style.fontSize = '12px';
    controlUI.style.color = 'rgb(25,25,25)';
    controlUI.style.display = 'none';
    controlUI.textContent = 'Clear';

    controlUI.addEventListener('click', () => {
      if (this.props.selectedItem) {
        this.props.setSelectedItem(null);
      }
      this.removeShapeFilter();
      this.props.resetAreaFilters();
      controlUI.style.display = 'none';
    });

    return controlUI;
  };

  hideClearBtn = () => {
    this.clearBtn.style.display = 'none';
  };

  initializeAreaSelector = () => {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          google.maps.drawing.OverlayType.RECTANGLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE,
        ],
      },
      rectangleOptions: this.defaultDrawOptions(),
      circleOptions: this.defaultDrawOptions({ editable: false }),
    });
    drawingManager.setMap(this.map);
    this.clearBtn = this.createClearBtn();
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.clearBtn);

    // initialize shape if one already exists in filters
    this.createShape();

    drawingManager.addListener('rectanglecomplete', (rectangle) => {
      this.clearBtn.style.display = 'block';
      const bounds = rectangle.getBounds();
      const rectangleArea = getRectangleArea(rectangle);

      const areaBounds = {
        north: bounds.getNorthEast().lat(),
        south: bounds.getSouthWest().lat(),
        east: bounds.getNorthEast().lng(),
        west: bounds.getSouthWest().lng(),
      };
      this.removeShapeFilter();
      this.shapefilter = rectangle;
      if ((this.props.channel === CHANNEL_OFF_MARKET) && (rectangleArea > MAX_DRAWN_AREA)) {
        this.props.setListHeading('Drawn area is too large, try again.');
      } else {
        this.props.setListHeading(null);
        this.props.setAreaFilter(areaBounds);
        rectangle.setOptions({ fillOpacity: 0 });
        this.map.fitBounds(bounds, 0);
      }
      drawingManager.setDrawingMode(null);
    });

    drawingManager.addListener('polygoncomplete', (polygon) => {
      this.clearBtn.style.display = 'block';
      const bounds = new google.maps.LatLngBounds();
      polygon.getPath().forEach((element) => { bounds.extend(element); });

      const polygonVertices = [];
      polygon.getPath().getArray().forEach(vertex => {
        polygonVertices.push({ lat: vertex.lat(), lng: vertex.lng() });
      });
      const polygonArea = google.maps.geometry.spherical.computeArea(polygon.getPath());

      this.removeShapeFilter();
      this.shapefilter = polygon;
      if ((this.props.channel === CHANNEL_OFF_MARKET) && (polygonArea > MAX_DRAWN_AREA)) {
        this.props.setListHeading('Drawn area is too large, try again.');
      } else {
        this.props.setListHeading(null);
        this.props.setPolygonFilter(polygonVertices);
        polygon.setOptions({ fillOpacity: 0 });
        this.map.fitBounds(bounds, 0);
      }
      drawingManager.setDrawingMode(null);
    });

    drawingManager.addListener('circlecomplete', (circle) => {
      this.clearBtn.style.display = 'block';
      const bounds = circle.getBounds();
      const radius = circle.getRadius();
      const center = { lat: circle.getCenter().lat(), lng: circle.getCenter().lng() };
      const circleArea = Math.PI * (radius * radius);

      this.removeShapeFilter();
      this.shapefilter = circle;
      if ((this.props.channel === CHANNEL_OFF_MARKET) && (circleArea > MAX_DRAWN_AREA)) {
        this.props.setListHeading('Drawn area is too large, try again.');
      } else {
        this.props.setListHeading(null);
        this.props.setCircleFilter({ center, radius });
        circle.setOptions({ fillOpacity: 0 });
        this.map.fitBounds(bounds, 0);
      }
      drawingManager.setDrawingMode(null);
    });
  };

  createShape = () => {
    const { area, circle, polygon } = this.props.filters;
    const { map } = this;

    // show clear button if shape is drawn
    if (area || circle || polygon) {
      this.clearBtn.style.display = 'block';
    }
    if (area) {
      const options = this.defaultDrawOptions({
        map,
        bounds: {
          north: area.north,
          south: area.south,
          east: area.east,
          west: area.west,
        },
      });
      this.shapefilter = new google.maps.Rectangle(options);
    }
    if (circle) {
      const { center, radius } = circle;
      const options = this.defaultDrawOptions({
        map,
        radius,
        center,
        editable: false,
      });
      this.shapefilter = new google.maps.Circle(options);
    }
    if (polygon) {
      const options = this.defaultDrawOptions({
        map,
        paths: polygon,
      });
      this.shapefilter = new google.maps.Polygon(options);
    }
  };

  handleMapZoomPan = () => {
    if (!this.items.length) {
      return;
    }

    const bounds = this.map.getBounds();

    this.censusDataLayer.setStyle(this.getCensusShapeStyle);
    this.neighborhoodDataLayer.setStyle(setDefaultColor);
    if (this.censusVisibility) {
      this.debouncedLoadInboundCensusTractData(bounds);
    } else if (this.neighborhoodVisibility) {
      this.debouncedLoadInboundNeighborhoodData(bounds);
    }

    this.props.setIdVisibilityMap(prevMap => {
      const updatedMap = new window.Map();
      let visibleCount = 0;
      let visibilityChanged = false;

      this.items.forEach(({ id }) => {
        const markerId = this.props.channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${id}` : id;
        const marker = this.markerMap.get(markerId);
        if (!marker) {
          return;
        }

        const withinBounds = bounds.contains(marker.getPosition());
        const withinMarkerLimit = visibleCount <= MARKER_LIMIT;
        // never hide a highlighted property
        const isHighlighted = markerId === (this.props.selectedItem?.id ?? '');
        const isVisible = isHighlighted || (withinMarkerLimit && withinBounds);
        if (isVisible) {
          // eslint-disable-next-line no-plusplus
          visibleCount++;
        }

        updatedMap.set(markerId, isVisible);
        visibilityChanged ||= isVisible !== prevMap?.get(markerId);

        // only hide a marker if it's within bounds to reduce marker updates
        if (withinBounds && !isVisible && marker.getVisible()) {
          marker.setVisible(false);
        } else if (isVisible && !marker.getVisible()) {
          marker.setVisible(true);
        }
      });

      return visibilityChanged ? updatedMap : prevMap;
    });
  };

  forceReRender() {
    // hack to force a re-render
    this.map.panBy(1, 0);
    this.map.panBy(-1, 0);
  }

  loadInBoundShapeData(bounds, loadedIds, inBoundRoute, shapeLoader, shouldLoad) {
    if (!bounds || !shouldLoad()) {
      return;
    }
    this.currentGroupId = new Date().getTime();
    const groupId = this.currentGroupId;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      // if bounds is changed during the call, ignore it
      if (this.currentGroupId !== groupId) return;

      if (xhr.readyState === 4) {
        const gids = JSON.parse(xhr.responseText);
        const newGids = gids.filter(gid => !loadedIds.includes(gid));

        shapeLoader(0, CENSUS_TRACT_PAGE_SIZE, groupId, newGids);
      }
    };

    const NE = bounds.getNorthEast(); // North East a.k.a. Bottom Right
    const SW = bounds.getSouthWest(); // South West a.k.a. Top Left
    xhr.open(
      'GET',
      inBoundRoute({
        swLat: SW.lat() - BOUNDS_TOLERANCE,
        swLng: SW.lng() - BOUNDS_TOLERANCE,
        neLat: NE.lat() + BOUNDS_TOLERANCE,
        neLng: NE.lng() + BOUNDS_TOLERANCE,
      }),
    );
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
    xhr.send();
  }

  loadInboundCensusTractData(bounds) {
    this.loadInBoundShapeData.bind(this)(bounds, this.loadedCensusGids, censusTractsInBounds, this.loadCensusTractShapes.bind(this), () => this.censusVisibility);
  }

  loadInboundNeighborhoodData(bounds) {
    this.loadInBoundShapeData.bind(this)(bounds, this.loadedNeighborhoodIds, neighborhoodsInBounds, this.loadNeighborhoodShapes.bind(this), () => this.neighborhoodVisibility);
  }

  loadShapes(page, perPage, groupId, gids, shapeRoute, onShapeLoaded, shouldLoad) {
    // Halt the process when the bounds is changed, another process will emerge
    if (groupId !== this.currentGroupId) return;
    if (!shouldLoad()) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (!shouldLoad()) {
          return;
        }

        const res = camelCaseKeys(JSON.parse(xhr.responseText), ['geoJson', 'censusTractMetric']);
        res.forEach(shape => onShapeLoaded(shape));

        if (res.length === perPage) {
          this.loadShapes(page + 1, perPage, groupId, gids, shapeRoute, onShapeLoaded, shouldLoad);
        }
      }
    };

    const paginatedGids = gids.slice(page * perPage, (page + 1) * perPage);
    if (!paginatedGids.length) return;

    xhr.open('GET', shapeRoute(paginatedGids));
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
    xhr.send();
  }

  loadCensusTractShapes(page, perPage, groupId, gids) {
    this.loadShapes.bind(this)(page, perPage, groupId, gids, censusTractsShape, this.addCensusTractShape.bind(this), () => this.censusVisibility);
  }

  loadNeighborhoodShapes(page, perPage, groupId, gids) {
    this.loadShapes.bind(this)(page, perPage, groupId, gids, neighborhoodsShape, this.addNeighborhoodShape.bind(this), () => this.neighborhoodVisibility);
  }

  addCensusTractShape(shape) {
    this.censusDataLayer.addGeoJson({
      type: 'Feature',
      geometry: shape.geoJson,
      properties: { data: [{ tractId: shape.name20, ...shape?.censusTractMetric?.data }] },
    });

    this.loadedCensusGids.push(shape.gid);
  }

  addNeighborhoodShape(shape) {
    this.neighborhoodDataLayer.addGeoJson({
      type: 'Feature',
      geometry: shape.geoJson,
      properties: {
        data: [
          { neighborhoodName: shape.name },
          ...shape.ancestors.reverse().map(ancestor => ({ ancestorNeighborhoodName: ancestor })),
        ],
        zHeight: shape.zHeight,
      },
    });

    this.loadedNeighborhoodIds.push(shape.id);
  }

  removeShapeFilter() {
    if (this.shapefilter) {
      this.shapefilter.setMap(null);
      this.shapefilter = null;
    }
  }

  render() {
    return (<div ref={this.containerRef} className="focus:outline-none w-full h-full" />);
  }
}
