// *** Deal Sourcing *** //

export const CHANNEL_LISTED = 'listed';
export const CHANNEL_NEW_BUILD = 'new_build';
export const CHANNEL_OFF_MARKET = 'off_market';
export const CHANNEL_OFF_MARKET_MARKETPLACE = 'off_market_marketplace';

export const CHANNEL_LABELS = {
  [CHANNEL_LISTED]: 'MLS',
  [CHANNEL_NEW_BUILD]: 'New Build',
  [CHANNEL_OFF_MARKET]: 'Off-Market',
  [CHANNEL_OFF_MARKET_MARKETPLACE]: 'Off-Market Marketplace',
};

export const MARKETS = [
  'Atlanta',
  'Birmingham',
  'Charlotte',
  'Cincinnati',
  'Columbus',
  'Dallas',
  'Indianapolis',
  'Jacksonville',
  'Kansas City',
  'Los Angeles',
  'Memphis',
  'Nashville',
  'Oklahoma City',
  'Orlando',
  'San Antonio',
  'San Diego',
  'Tampa',
];

// *** Unit Mix *** //

export const UNIT_MIX_KEYS = [
  'numberOfStudio',
  'numberOf1Br',
  'numberOf2Br',
  'numberOf3Br',
  'numberOf4Br',
];
export const UNIT_MIX_RENT_KEYS = UNIT_MIX_KEYS.map(key => key.replace('number', 'rent'));
export const UNIT_MIX_LABEL_MAP = {
  numberOfStudio: 'Studio',
  numberOf1Br: '1 bd',
  numberOf2Br: '2 bd',
  numberOf3Br: '3 bd',
  numberOf4Br: '4+ bd',
};

// *** Listing *** //

export const LISTING_SOURCE_MANUAL = 'manual';
export const LISTING_SOURCE_BUILDER = 'builder';
export const LISTING_SOURCE_OFF_MARKET_MARKETPLACE = 'off-market marketplace';

export const RESIDENTIAL_PROPERTY_TYPE = 'Residential';
export const RESIDENTIAL_INCOME_PROPERTY_TYPE = 'Residential Income';

export const PARCEL_LAND_USE_CODE_TO_PROPERTY_TYPE = Object.freeze({
  SF: RESIDENTIAL_PROPERTY_TYPE,
  MF: RESIDENTIAL_INCOME_PROPERTY_TYPE,
});

export const ACTIVE_STATUS = 'Active';
export const CLOSED_STATUS = 'Closed';
export const COMING_SOON_STATUS = 'Coming Soon';

export const LISTING_STATUSES = [
  ACTIVE_STATUS,
  'Active Under Contract',
  COMING_SOON_STATUS,
];

export const INACTIVE_STATUSES = [
  'Cancelled',
  CLOSED_STATUS,
  'Deleted',
  'Expired',
  'Withdrawn',
];

// this is based on running MarketDataListing.distinct.pluck("PropertySubType")
// so will need to be updated as we add new MLS sources
export const PROPERTY_TYPES = [
  'Apartment',
  'Business',
  'Church',
  'Condominium',
  'Converted Residence',
  'Cooperative',
  'Duplex',
  'Eightplex',
  'Eightplex+',
  'Farm',
  'Fiveplex',
  'Flat Condo',
  'Fourplex',
  'Garden',
  'Half Duplex',
  'High Rise',
  'Horizontal Property Regime - Attached',
  'Horizontal Property Regime - Detached',
  'Loft',
  'Manufactured Home',
  'Manufactured On Land',
  'Mobile Home',
  'Modular Home',
  'Multifamily',
  'Multi Family',
  'Multi-Family 5+',
  'Multiple Single Units',
  'Office',
  'Other',
  'Other Condo',
  'Package of Properties',
  'Portfolio',
  'Quadruplex',
  'Residential Units - 1 to 4',
  'Retail',
  'Rooming Houses',
  'Sevenplex',
  'Single Family Residence',
  'Sixplex',
  'Stock Cooperative',
  'Townhouse',
  'Triplex',
  'Unimproved Land',
  'Villa',
  'Zero Lot Line',
];

// *** Default Names *** //

export const DEFAULT_SCENARIO_NAME = 'Portfolio Defaults';
export const DEFAULT_COMP_SET_NAME = 'Current Comps';
export const FIRST_SCENARIO_NAME = 'Scenario 1';

// *** Deal Workflow Enums *** //

export const DEAL_ATTACHMENT_TYPE = 'deal';
export const TASK_ATTACHMENT_TYPE = 'task';

// *** Deal State *** //

export const DEAL_STATE_ACTIVE = 'Active';
export const DEAL_STATE_CLOSED = 'Closed';
export const DEAL_STATE_DEAD = 'Dead';
export const DEAL_STATE_WITHDRAWN = 'Withdrawn';

export const INACTIVE_DEAL_STATES = [DEAL_STATE_CLOSED, DEAL_STATE_DEAD];

export const OFFER_STATUS_ACCEPTED = 'accepted';
export const OFFER_STATUS_BUYER_COUNTERED = 'buyer_counter';
export const OFFER_STATUS_RE_OPENED = 're_opened';
export const OFFER_STATUS_RECEIVED = 'received';
export const OFFER_STATUS_REJECTED = 'rejected';
export const OFFER_STATUS_SELLER_COUNTERED = 'seller_counter';
export const OFFER_STATUS_TERMINATED = 'terminated';

// *** Deal Modelling Methods //

export const MODELLED_INDIVIDUALLY = 'individual';
export const MODELLED_COMBINED = 'combined';

// *** Organization *** //

export const ORG_SINGLE_FAMILY_TYPE = 'SF';
export const ORG_MULTI_FAMILY_TYPE = 'MF';
export const ORG_BOTH_TYPE = 'Both';

export const ORG_DEFAULT_WORKFLOW_TYPE = 'MF';
export const ORG_DARWIN_WORKFLOW_TYPE = 'SF';

// *** Layout *** //
// used for calculating height values of full-screen components

const NAV_RAIL_WIDTH = 80;

// values are in pixels
const LAYOUT = {};
LAYOUT.dealNavHeight = 68;
LAYOUT.navTabsHeight = 51;
LAYOUT.dealHeaderHeight = LAYOUT.dealNavHeight + LAYOUT.navTabsHeight + 1; // add 1 for bottom border height

LAYOUT.assetManagementHeaderHeight = 82;
LAYOUT.assetManagementNavTabsHeight = 44;
LAYOUT.assetManagementFilterHeight = 70;
LAYOUT.dealImportMenuHeight = 82;
LAYOUT.modelOverviewHeight = 82;
LAYOUT.notificationDrawerWidth = 600;
LAYOUT.outputModalHeaderHeight = 143;
LAYOUT.portfolioHeaderHeight = 98;
LAYOUT.rentCompAllFiltersWidth = 625;
LAYOUT.rentCompStaticContentHeight = 435;
LAYOUT.rightNavWidth = NAV_RAIL_WIDTH;
LAYOUT.saleCompTopBarHeight = 74;
LAYOUT.sidebarWidth = NAV_RAIL_WIDTH;
LAYOUT.portfolioSidebarWidth = 160;
LAYOUT.unauthenticatedNavbarHeight = 88;
LAYOUT.workflowEmailPreviewModalWidth = 800;

export { LAYOUT };

export const ABBR_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// Rents comps
export const GENERALIZED_PIPELINE_STATUS = [DEAL_STATE_ACTIVE, DEAL_STATE_CLOSED, DEAL_STATE_DEAD];
export const BED_OPTIONS = ['Studio', 1, 2, 3, 4, 5];
export const BATH_OPTIONS = [1, 1.5, 2, 2.5, 3, 4];
export const UNIT_RENT_SOURCE_ZILLOW = 'Zillow';
export const UNIT_RENTS_SOURCES = [UNIT_RENT_SOURCE_ZILLOW, 'MLS', 'Other'];
export const UNIT_RENT_ACTIVE_STATUS = 'Active';
export const UNIT_RENT_CANCELLED_STATUS = 'Cancelled';
export const UNIT_RENT_CLOSED_STATUS = 'Closed';
export const UNIT_RENT_INACTIVE_STATUS = 'Inactive';
export const UNIT_RENTS_STATUSES = [UNIT_RENT_ACTIVE_STATUS, UNIT_RENT_CANCELLED_STATUS, UNIT_RENT_INACTIVE_STATUS, UNIT_RENT_CLOSED_STATUS];

export const HISTORY_RECORD_TYPES = {
  LISTING: 'listing',
  RECORDER: 'recorder',
  RENT_LISTING: 'rent_listing',
  VALUATION: 'valuation',
};

export const HOA_FEE_EXPENSE_ITEM_NAME = 'HOA Fee';

// this is a subset of the TRANSACTION_INFO_FIELDS from the Deal model
// representing what the user should be able to edit anytime via the Deal modal
export const TRANSACTION_INFO_FIELDS = {
  seller: 'text',
  titleContact: 'text',
  brokerageFee: 'number',
  commission: 'number',
  referralFee: 'number',
  commissionPaidBy: 'text',
};

export const ALL_DEALS_STAGE = 'All';
export const DEAL_STAGES = {
  review: 'Under Review',
  offer: 'Offer & Offer Negotiation',
  diligence: 'Diligence',
  closing: 'Closing',
  closed: DEAL_STATE_CLOSED,
  dead: DEAL_STATE_DEAD,
};

// used for shortening deal stage name in areas of UI where size matters
export const DEAL_STAGE_ABBREVIATIONS = {
  [DEAL_STAGES.offer]: 'Offer',
};

export const ACQUISITION = 'acquisition';
export const DISPOSITION = 'disposition';
export const TRANSACTION_TYPES = {
  [ACQUISITION]: ACQUISITION,
  [DISPOSITION]: DISPOSITION,
};

export const LISTING_AND_OFFER_MILESTONE_NAME = 'Listing & Offer Process';

export const SCORE_COLORS = {
  3: 'bg-green-600',
  2: 'bg-lime-500',
  1: 'bg-warning-300',
  0: 'bg-error-500',
};
