import FilterChipButton from './FilterChipButton';

export default function BooleanFilterChip({ children, leadingIcon = true }) {
  return (
    <FilterChipButton
      leadingIcon={leadingIcon}
      // use overflow-clip-margin to contain content within the rounded border
      className="relative overflow-clip [overflow-clip-margin:content-box]"
    >
      {children}
    </FilterChipButton>
  );
}
