import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { compact, groupBy, isEmpty, isEqual, isNil, map, mean, meanBy, sortBy, sum, sumBy } from 'lodash';
import { formatDistanceStrict } from 'date-fns';
import { useUpdateScenarioMutation } from 'redux/apiSlice';
import {
  DEAL_STAGES,
  DEFAULT_SCENARIO_NAME,
  LISTING_SOURCE_BUILDER,
  LISTING_SOURCE_MANUAL,
  LISTING_SOURCE_OFF_MARKET_MARKETPLACE,
} from 'components/constants';
import {
  formatAddress,
  formatCounty,
  formatCurrency,
  formatCurrencyAbbreviated,
  formatDate,
  formatEquityMultiple,
  formatInteger,
  formatListingSource,
  formatPercentage,
  parseArrayString,
  parseEventValue,
  titleCase,
} from 'components/utils';
import { Arrow, Check, Chevron, ContentCopy, Home, MultiFamily, Pencil, Undo } from 'components/icons';
import Button from 'components/shared/NewButton';
import { calcCashFlows, calcOpExRatio, calcReturnMetrics } from 'components/dcf/dcf';
import { resetPendingParams, setPendingParams, setBaseParams } from 'actions/model';
import { setShowCreateDealModal } from 'actions/deal_navigation';
import Input from 'components/Input';
import Badge from 'components/shared/Badge';
import StatChip from 'components/shared/StatChip';
import DataTable from 'components/shared/Table/DataTable';
import { UnderwritingSimpleStat, UnderwritingStatMultiplicity } from 'components/shared/metrics/UnderwritingStat';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import PhotoModal from 'components/property/components/PhotoModal';
import EmailLink from 'components/common/EmailLink';
import StreetView from 'components/property/components/StreetView';
import ListingStatusBadge from 'components/listing/ListingStatusBadge';
import RoundButton from 'components/common/RoundButton';
import Disclaimer from 'components/property/components/Disclaimer';
import { FutureDeliveriesGridHeader, FutureDeliveriesGridCell } from 'components/Subdivision/HomeModelFutureDeliveriesCell';

const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
  zoom: 12,
  minZoom: 7,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
  },
};

function OverviewHeader({ homeBuilder, homeModel, subdivision, property, listing, returnMetrics }) {
  const IRR = [
    { label: 'Unlevered', value: `${formatPercentage(returnMetrics?.unleveredIrr)}` },
    { label: 'Levered', value: `${formatPercentage(returnMetrics?.leveredIrr)}` },
  ];
  const YIELDS = [
    { label: 'Net', value: `${formatPercentage(returnMetrics?.stabilizedYield, 2)}` },
    { label: 'Gross', value: `${formatPercentage(returnMetrics?.grossStabilizedYield, 2)}` },
  ];

  const fullAddress = formatAddress(property);

  const propertyBreadcrumbs = [];
  propertyBreadcrumbs.push(<div className="text-xs text-gray-500">{titleCase(property?.market)}</div>);
  if (property?.county) {
    propertyBreadcrumbs.push(<Chevron direction="right" className="w-3 text-gray-500" />);
    propertyBreadcrumbs.push(<div className="text-xs text-gray-600">{`${formatCounty(property?.county)} County`}</div>);
  }
  if (homeBuilder?.name) {
    propertyBreadcrumbs.push(<Chevron direction="right" className="w-3 text-gray-500" />);
    propertyBreadcrumbs.push(<div className="text-xs text-gray-600">{homeBuilder?.name}</div>);
  }
  if (subdivision?.name) {
    propertyBreadcrumbs.push(<Chevron direction="right" className="w-3 text-gray-500" />);
    propertyBreadcrumbs.push(<div className="text-xs text-gray-600">{subdivision?.name}</div>);
  }

  return (
    <div className="grid grid-cols-4 mb-3">
      <div className="col-span-3 flex flex-col justify-center items-start">
        <div className="flex gap-x-2 mb-1">
          {propertyBreadcrumbs.map((breadcrumb, index) => <Fragment key={index}>{breadcrumb}</Fragment>)}
        </div>
        <div className="flex items-center gap-x-4 text-lg">
          <div className="flex items-center gap-x-1 text-gray-800">
            <div>{fullAddress}</div>
            {navigator.clipboard && (
              <RoundButton padding="p-2.5" onClick={() => navigator.clipboard.writeText(fullAddress)}>
                <ContentCopy className="text-gray-700 h-4 w-4" />
              </RoundButton>
            )}
          </div>
          {property?.isSingleFamily ? (
            <div className="h-full text-xs border px-2 py-1 flex gap-x-1 justify-center items-center rounded-lg">
              <Home className="w-4" />
              <div className="ml-1 text-xs font-medium">SF</div>
            </div>
          ) : (
            <div className="h-full text-xs border px-2 py-1 flex gap-x-1 justify-center items-center rounded-lg">
              <MultiFamily className="w-4" />
              <div className="ml-1 text-xs font-medium">MF</div>
            </div>
          )}
        </div>
      </div>
      {listing && !homeModel && (
        <div className="col-span-1 flex justify-end items-center gap-x-4">
          <StatChip title="IRR" backgroundColor="bg-primary-dark" data={IRR} />
          <StatChip title="Yield" backgroundColor="bg-secondary-400" data={YIELDS} />
        </div>
      )}
    </div>
  );
}

export function PhotoGrid({ listing, pictures, property, showModal, setShowModal, primaryPhotoIndex, setPrimaryPhotoIndex, showMapModal, setShowMapModal }) {
  const handlePhotoSelection = (index) => {
    setPrimaryPhotoIndex(index);
    setShowModal(true);
  };

  const streetViewElem = <StreetView property={property} setShowMapModal={setShowMapModal} showMapModal={showMapModal} />;

  const mapElem = (property.latitude && property.longitude) ? (
    <>
      {streetViewElem}
      <div className="relative">
        <Map properties={[property]} options={DEFAULT_MAP_OPTIONS} borderRadius="0.5rem" />
        <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={property}>
          <Map properties={[property]} />
        </MapModal>
        <Badge label="Click to expand" className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg" visible onClick={() => setShowMapModal(true)} />
      </div>
    </>
  ) : null;

  if (!isEmpty(pictures)) {
    return (
      <div className="grid grid-cols-6 grid-rows-2 col-span-6 gap-6">
        <div className="relative w-auto rounded-lg max-h-128 cursor-pointer row-span-full col-span-4" onClick={() => handlePhotoSelection(0)}>
          <Badge label={listing ? `Listed - ${formatCurrency(listing.price)}` : 'Unlisted'} className="absolute top-5 left-5 bg-white border" />
          <img src={pictures ? pictures[0].url : ''} className="w-full object-cover object-center h-full rounded-lg indent-96 whitespace-nowrap truncate" referrerPolicy="no-referrer" alt={pictures ? pictures[0].url : ''} />
          <PhotoModal photos={pictures} property={property} showModal={showModal} setShowModal={setShowModal} primaryPhotoIndex={primaryPhotoIndex} setPrimaryPhotoIndex={setPrimaryPhotoIndex} />
          <Badge label={pictures ? `${pictures.length} Photos` : ''} className="absolute bottom-5 right-5 bg-white border" />
        </div>
        {mapElem && (
          <div className="grid grid-cols-1 grid-rows-2 gap-6 col-span-2 rounded-lg max-h-128 row-span-full">
            {mapElem}
          </div>
        )}
      </div>
    );
  }

  if (mapElem) {
    return (
      <div className="w-full h-80 grid grid-flow-col auto-cols-fr gap-x-2">
        {mapElem}
      </div>
    );
  }

  return null;
}

function ListingSummary({ homeModel, listing, priceChange, property }) {
  const { isSingleFamily, livingArea, numberOfUnits } = property;
  const { listAgent, listedOn, price, source, sourceId, updatedAt } = listing;

  return (
    <div className="pl-6">
      <div className="flex items-center justify-between pr-6">
        <div>
          <div className="text-lg">{homeModel ? 'Home Model' : 'Listing'}</div>
          {listedOn && <div className="text-xs text-gray-400">{`Listed ${formatDate(listedOn, 'MMM d, yyyy')} (${formatDistanceStrict(new Date(listedOn), new Date(), { addSuffix: true, unit: 'day' })})`}</div>}
        </div>
        {/* TODO: off-market listing status not yet implemented */}
        {(source !== LISTING_SOURCE_MANUAL && source !== LISTING_SOURCE_OFF_MARKET_MARKETPLACE && !homeModel) && <ListingStatusBadge listing={listing} />}
      </div>
      <div className="flex gap-x-4 mt-4">
        <div className="text-3xl">{formatCurrency(price)}</div>
        {priceChange && (
          <div className="flex gap-x-2 items-center">
            <div className={`flex justify-center items-center rounded-lg text-sm px-2 py-1 ${priceChange.backgroundColor} ${priceChange.textColor}`}>
              <Arrow direction={priceChange.difference > 0 ? 'up' : 'down'} fill={priceChange.difference > 0 ? '#006D41' : '#BA1A1A'} className="w-4" />
              <div className="ml-1">{formatPercentage(priceChange.difference)}</div>
            </div>
            <div className={`text-xs ${priceChange.textColor}`}>{formatDate(priceChange.date, 'MMM d')}</div>
          </div>
        )}
      </div>
      <div className="flex gap-x-4 mt-1 text-xs text-gray-700">
        {isSingleFamily ? (
          livingArea && <div>{ `${formatCurrency(price / livingArea)} PSF` }</div>
        ) : (
          numberOfUnits && <div>{ `${formatCurrencyAbbreviated(price / numberOfUnits)} / Unit` }</div>
        )}
      </div>
      {listAgent && (
        <>
          <div className="flex gap-x-2 mt-4 text-sm text-gray-700">
            { listAgent.fullName && <div>{`Listed by ${listAgent.fullName || ''}`}</div> }
            { (listAgent.fullName && listAgent.brokerageName) && <div>•</div> }
            { listAgent.brokerageName && <div>{listAgent.brokerageName || ''}</div> }
          </div>
          <div className="flex gap-x-2 mt-2 text-xs text-gray-700">
            { listAgent.directPhone && <div>{listAgent.directPhone || ''}</div> }
            { (listAgent.directPhone && listAgent.email) && <div>•</div> }
            { listAgent.email && <EmailLink address={listAgent.email || ''} /> }
          </div>
        </>
      )}
      <div className="flex items-center gap-x-4 mt-4">
        {source && (source !== LISTING_SOURCE_MANUAL && source !== LISTING_SOURCE_OFF_MARKET_MARKETPLACE) && (
          <div className="flex justify-center items-center px-2 py-1 border rounded-lg">
            <div className="text-xs text-gray-700">{`${formatListingSource(source)} ${source !== LISTING_SOURCE_BUILDER ? '#' : ''}${sourceId}`}</div>
          </div>
        )}
        <div className="text-xs text-gray-500">{`Updated ${formatDate(updatedAt, 'PPpp')}`}</div>
      </div>
    </div>
  );
}

function Underwriting({ model, property, returnMetrics }) {
  const { cashFlows, dcfParams, listing, scenario } = model;
  const { purchasePrice, units } = dcfParams;
  const acquisitionCost = cashFlows.acquisition.cost[0] * -1;
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const originationFee = cashFlows.financing.loanOriginationFees[0] * -1;
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const marketRent = formatCurrency(meanBy(dcfParams.units, 'marketRent'));
  const purchasePriceSubText = property.isSingleFamily
    ? `${formatCurrency(purchasePrice / property.livingArea)} PSF`
    : `${formatCurrencyAbbreviated(purchasePrice / property.numberOfUnits)} / Unit`;
  const capExBudget = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const totalAcquisitionCost = totalAcquisitionCapital + followOnCapitalProjects;
  const totalAcquisitionCostSubText = property.isSingleFamily
    ? `${formatCurrency(totalAcquisitionCost / property.livingArea)} PSF`
    : `${formatCurrencyAbbreviated(totalAcquisitionCost / property.numberOfUnits)} / Unit`;
  const inPlaceRentTotal = sum(compact(map(units, unit => unit.inPlaceRent)));
  const grm = inPlaceRentTotal > 0 ? `${(purchasePrice / (inPlaceRentTotal * 12)).toFixed(1)}x` : '-';

  return (
    <div className="border-l px-6">
      <div className="text-lg">Underwriting</div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-400 mb-6">{`${scenario?.name || DEFAULT_SCENARIO_NAME} assumptions`}</div>
        <div className="w-full flex gap-x-6 pb-4 border-b">
          <div className="w-1/2 flex flex-col">
            <div className="text-xs text-gray-500">Total Acquisition Capital</div>
            <div>{formatCurrency(totalAcquisitionCost)}</div>
            <div className="text-xs text-gray-600">{totalAcquisitionCostSubText}</div>
          </div>
          <div className="w-1/2 flex flex-col">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <div className="text-xs text-gray-500">Purchase Price ({formatPercentage(purchasePrice / listing.price, 0)} of asking)</div>
            <div>{formatCurrency(purchasePrice)}</div>
            <div className="text-xs text-gray-600">{purchasePriceSubText}</div>
          </div>
        </div>
        <div className="pt-4 flex justify-between gap-x-4">
          <div className="text-center">
            <div>{property.isSingleFamily ? marketRent : grm}</div>
            <div className="text-xs text-gray-500">{property.isSingleFamily ? 'Mkt. Rent' : 'In-Place GRM' }</div>
          </div>
          <div className="text-center">
            <div>{formatPercentage(calcOpExRatio(cashFlows))}</div>
            <div className="text-xs text-gray-500">OpEx Ratio</div>
          </div>
          <div className="text-center">
            <div>{property.isSingleFamily ? formatCurrencyAbbreviated(capExBudget) : `${formatCurrencyAbbreviated(capExBudget / property.numberOfUnits)} / Unit` }</div>
            <div className="text-xs text-gray-500">CapEx Budget</div>
          </div>
          <div className="text-center">
            <div>{formatPercentage(returnMetrics.stabilizedYield, 2)}</div>
            <div className="text-xs text-gray-500">Net Yield</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const unitMixString = (units) => {
  const unitsByBedrooms = groupBy(units, 'bedrooms');
  const numOfUnitsByBedrooms = map(unitsByBedrooms, (unitsForBedrooms, numOfBedrooms) => [unitsForBedrooms.length, numOfBedrooms]);
  return sortBy(numOfUnitsByBedrooms, pair => pair[1]).map(pair => `${pair[0]} x ${pair[1]}`).join(', ');
};

export function PropertyDetails({ homeBuilder, homeModel, owner, parcels, property, units, listing }) {
  const parcel = parcels[0];
  const items = property.isSingleFamily
    ? [
      ['Property Type', listing?.propertySubType || property.propertyUseType || 'Single Family'],
      ['Beds', property.bedrooms],
      ['Baths', property.bathrooms],
      ['Garage Spaces', formatInteger(property.numberOfParkingSpaces || listing?.garageSpaces)],
    ] : [
      ['Property Type', listing?.propertySubType || property.propertyUseType || 'Multi Family'],
      ['Units', property.numberOfUnits],
      ['Unit Mix', unitMixString(units)],
      ['Parking Spaces', formatInteger(property.numberOfParkingSpaces || listing?.parkingTotal)],
    ];

  const otherItems = [
    ['Stories', property.numberOfFloors || parseArrayString(listing?.levels)],
    ['Building Area', `${formatInteger(property.livingArea)} SF`],
    ['Lot Area', parcel?.lotSizeSqFt ? `${formatInteger(Math.floor(parcel?.lotSizeSqFt))} SF` : null],
    ['Year Built', property.yearBuilt || listing?.yearBuilt],
    ['Construction', parseArrayString(property.constructionMaterial || listing?.constructionMaterials)],
    ['Solar', homeModel?.info?.solar?.[0]],
    ['Water Source', parseArrayString(listing?.waterSource) || homeModel?.info?.waterWell?.[0]],
    ['Sewer', parseArrayString(listing?.sewer) || property.sewer || homeModel?.info?.sewerSeptic?.[0]],
    ['Subdivision', titleCase(listing?.subdivisionName || property.subdivisionName || parcel?.subdivision)],
    ['County', parcel?.situsCounty || property.county],
    homeModel ? ['Builder', homeBuilder.name]
      : ['Owner', titleCase(owner?.name || owner?.ownerName || owner?.ownerNameLatestDeed)],
  ];
  const allItems = items.concat(otherItems).filter(tuple => tuple[1]);

  const title = property.homeModelId ? 'Home Model Details' : 'Property Details';
  return (
    <div className="w-full py-3 px-4 bg-white border border-gray-200 rounded">
      <div className="pb-3">{title}</div>
      {allItems.map((item, index) => (
        <div key={item[0]} className={`flex justify-between items-center py-2${index === (allItems.length - 1) ? '' : ' border-b'}`}>
          <div className="text-label-md font-medium text-gray-500 uppercase">{item[0]}</div>
          <div>{item[1] || '-'}</div>
        </div>
      ))}
    </div>
  );
}

function MlsListingBody({ listing, model, property, returnMetrics }) {
  return (
    <>
      <div className="grid grid-cols-2 py-3 bg-white rounded-lg">
        <ListingSummary property={property} listing={listing} />
        <Underwriting model={model} property={property} returnMetrics={returnMetrics} />
      </div>
      <div className="mt-6">
        <div className="pb-2 border-b border-gray-200">About this property</div>
        <div className="grid grid-cols-2 py-3 px-6 bg-white rounded-lg">
          <div className="pr-4">
            <div className="text-sm">Remarks</div>
            <div className="pt-2 font-light break-words">{listing?.remarks || '-'}</div>
          </div>
          <div className="border-l pl-4">
            <div className="text-sm">Private Remarks</div>
            <div className="pt-2 font-light break-words">{listing?.privateRemarks || listing?.listingPrivateRemarks || '-'}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function OffMarketMarketplaceBody({ currentUser, listing, model, property, returnMetrics }) {
  const isAgent = currentUser.settings.offMarketBroker;
  const {
    expirationDate,
    listingPrivateRemarks,
    publicRemarks,
    details: {
      leaseAmount,
      leaseExpiration,
      occupantType,
      preInspectionAvailableYn,
      section8Yn,
    },
  } = listing;
  return (
    <>
      <div className="grid grid-cols-2 py-3 bg-white rounded-lg">
        <ListingSummary property={property} listing={listing} />
        <Underwriting model={model} property={property} returnMetrics={returnMetrics} />
      </div>
      <div className="mt-6">
        <div className="pb-2 border-b border-gray-200">About this listing</div>
        <div className="grid grid-cols-2 py-3 px-6 bg-white rounded-lg">
          <div className="pr-4">
            <div className="text-sm">Remarks</div>
            <div className="pt-2 font-light break-words">{publicRemarks}</div>
            {isAgent && (
              <>
                <div className="mt-6 text-sm">Private Remarks</div>
                <div className="pt-2 font-light break-words">{listingPrivateRemarks}</div>
              </>
            )}
            <div className="mt-6 text-sm">Occupancy Status</div>
            <div className="pt-2 font-light">{occupantType && titleCase(occupantType)}</div>
            <div className="mt-6 text-sm">Lease Expiration Date</div>
            <div className="pt-2 font-light">{formatDate(leaseExpiration)}</div>
            <div className="mt-6 text-sm">Lease Amount</div>
            <div className="pt-2 font-light">{formatCurrency(leaseAmount)}</div>
          </div>
          <div className="border-l pl-4">
            <div className="text-sm">Pre-Inspection Available</div>
            <div className="pt-2 font-light">{isNil(preInspectionAvailableYn) ? '-' : (preInspectionAvailableYn ? 'Yes' : 'No')}</div>
            <div className="mt-6 text-sm">Listing Expiration Date</div>
            <div className="pt-2 font-light">{formatDate(expirationDate)}</div>
            <div className="mt-6 text-sm">Section 8</div>
            <div className="pt-2 font-light">{isNil(section8Yn) ? null : titleCase(section8Yn.toString())}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function UnderwritingMainStat({ canEdit, className, formatter, label, value, subValue, subText, onChange, inputOptions, multiplicity, showMultiplicity = false }) {
  const isEdit = inputOptions && canEdit;

  return (
    <div className={cx(className, 'grid row-span-3 grid-rows-subgrid items-start max-w-40 gap-y-2')}>
      <div className="text-xs text-gray-500">{label}</div>
      <div className={cx('flex flex-col justify-start self-start', isEdit ? 'text-right' : 'text-left')}>
        {isEdit ? <Input width="w-full" value={value} onChange={onChange} {...inputOptions} /> : <div className="text-lg">{formatter(value)}</div>}
        {showMultiplicity && <UnderwritingStatMultiplicity value={value} multiplicity={multiplicity} formatter={formatter} />}
      </div>
      <div className="flex justify-between items-center">
        <div className="text-xs text-gray-600">{subValue}</div>
        {subText && <div className="text-xs opacity-80">{subText}</div>}
      </div>
    </div>
  );
}

function HomeModelUnderwriting({ deal, homeModel, modelData, subdivision }) {
  const model = useSelector(state => state.model);
  const [updateScenarioMutation, { isLoading }] = useUpdateScenarioMutation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (modelData) {
      dispatch(setBaseParams(modelData.model));
    }
  }, [dispatch, modelData]);
  // default to editable if no deal exists or deal is still Under Review
  const [canEdit, setCanEdit] = useState(!deal || (deal.pipelineStatus === DEAL_STAGES.review));

  const baseParams = model?.baseParams;
  const modelParams = model?.pendingParams;
  const paramsUnsaved = !isEqual(baseParams?.dcfParams, modelParams?.dcfParams);

  if (!modelParams) {
    return null;
  }

  const { annualHoa: homeModelHoa, priceMin, sqft, taxRate: homeModelTaxRate } = homeModel;
  const { annualHoa: subdivisionHoa, taxRate: subdivisionTaxRate } = subdivision;
  const { dcfParams, scenario } = modelParams;
  const { acquisitionCostPercent, purchasePrice, units, multiplicity } = dcfParams;

  const onChange = (event) => {
    let param = event.target.name;
    let newValue = parseEventValue(event);
    if (['marketRent', 'turnBudget'].includes(param)) {
      newValue = [{ ...dcfParams.units[0], [param]: newValue }];
      param = 'units';
    } else if (param === 'annualHoa') {
      const expenseItems = [...dcfParams.expenseItems];
      param = 'expenseItems';
      const hoaItem = expenseItems.find(expenseItem => expenseItem.name === 'HOA Fee');
      const hoaItemIndex = expenseItems.indexOf(hoaItem);
      expenseItems.splice(hoaItemIndex, 1, { ...hoaItem, inputValue: newValue });
      newValue = expenseItems;
    }
    dispatch(setPendingParams({ param, newValue }));
  };

  const onSave = async () => {
    if (scenario) {
      // TODO: handle error
      await updateScenarioMutation({
        id: scenario.id,
        dealId: scenario.dealId,
        parameters: dcfParams,
      });
    } else {
      dispatch(setShowCreateDealModal(true));
    }
  };

  const cashFlows = calcCashFlows(dcfParams);
  const returnMetrics = calcReturnMetrics(cashFlows, dcfParams);
  const { stabilizedYield, unleveredEquityMultiple, unleveredIrr } = returnMetrics;

  const listPsf = priceMin / sqft;
  const purchasePsf = purchasePrice / sqft;
  const marketRent = meanBy(units, 'marketRent');
  const rentPsf = marketRent / sqft;
  const capExBudget = sum(cashFlows.capital.followOnCapitalExpenses) * -1;

  const acquisitionCost = cashFlows.acquisition.cost[0] * -1;
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const originationFee = cashFlows.financing.loanOriginationFees[0] * -1;
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const totalAcquisitionCost = totalAcquisitionCapital + followOnCapitalProjects;
  const percentOfList = (purchasePrice / priceMin) - 1;

  const returnMetricStats = [
    {
      label: 'Yield',
      value: stabilizedYield,
      formatter: (value) => formatPercentage(value, 2),
    },
    {
      label: 'IRR',
      value: unleveredIrr,
      formatter: (value) => formatPercentage(value, 1),
    },
    {
      label: 'Equity Multiple',
      value: unleveredEquityMultiple,
      formatter: formatEquityMultiple,
    },
  ];
  const percentOfListNegative = purchasePrice < priceMin;
  const percentOfListString = `${percentOfListNegative ? '↓' : '↑'}${formatPercentage(Math.abs(percentOfList), 0)} of List`;

  const inputRow = [
    {
      label: 'List Price',
      value: priceMin,
      formatter: formatCurrency,
      subValue: `${formatCurrency(listPsf, 0)} PSF`,
      showMultiplicity: true,
    },
    {
      label: 'Purchase Price',
      value: purchasePrice,
      formatter: formatCurrency,
      subValue: `${formatCurrency(purchasePsf, 0)} PSF`,
      subText: (purchasePrice === priceMin) ? null : percentOfListString,
      inputOptions: { name: 'purchasePrice', type: 'currency' },
      showMultiplicity: true,
    },
    {
      label: 'UW Rent',
      value: marketRent,
      formatter: formatCurrency,
      subValue: `${formatCurrency(rentPsf, 2)} PSF`,
      inputOptions: { name: 'marketRent', type: 'currency' },
    },
    {
      label: 'CapEx Budget',
      value: capExBudget,
      formatter: formatCurrency,
      inputOptions: { name: 'turnBudget', type: 'currency' },
    },
  ];
  const statRow = [
    {
      label: 'Tax Rate',
      value: homeModelTaxRate || subdivisionTaxRate,
      formatter: (value) => formatPercentage(value, 2),
    },
    {
      label: 'Annual HOA',
      value: homeModelHoa || subdivisionHoa,
      formatter: formatCurrency,
    },
    {
      label: 'Acq. Cost',
      value: acquisitionCostPercent,
      formatter: formatPercentage,
    },
    {
      label: 'Total Acq. Cost',
      value: totalAcquisitionCost,
      formatter: formatCurrency,
      showMultiplicity: true,
    },
    {
      label: 'OpEx Ratio',
      value: calcOpExRatio(cashFlows),
      formatter: formatPercentage,
    },
  ];

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex justify-between items-start mb-10">
        <div className="grow flex items-center gap-x-6">
          <div>
            <div className="flex items-center gap-x-2">
              <div className="text-lg">Underwriting</div>
              <Pencil className="w-6 h-6 cursor-pointer hover:text-gray-500" onClick={() => setCanEdit(!canEdit)} />
            </div>
            <div className="text-xs text-gray-400">{`${scenario?.name || DEFAULT_SCENARIO_NAME} assumptions`}</div>
          </div>
          {paramsUnsaved && (
            <div className="grow flex items-center gap-x-6">
              <Button textOnly leadingIcon={<Undo className="mr-4 w-6 text-primary-dark" />} label="Undo" onClick={() => dispatch(resetPendingParams())} />
              <Button filled leadingIcon={<Check className="mr-4 w-6" />} label="Save Changes" onClick={onSave} isLoading={isLoading} />
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 divide-x border rounded shadow">
          {returnMetricStats.map(returnMetricStat => (
            <UnderwritingSimpleStat
              key={returnMetricStat.label}
              className="text-center px-6 py-2"
              {...returnMetricStat}
            />
          ))}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-6 pb-6 border-b">
        {inputRow.map(statProperties => (
          <UnderwritingMainStat
            key={statProperties.label}
            {...statProperties}
            onChange={onChange}
            canEdit={canEdit}
            multiplicity={multiplicity}
          />
        ))}
      </div>
      <div className="grid grid-cols-5 mt-6">
        {statRow.map(statProperties => (
          <UnderwritingSimpleStat
            key={statProperties.label}
            {...statProperties}
            multiplicity={multiplicity}
          />
        ))}
      </div>
    </div>
  );
}

const ADDRESS_TABLE_COLUMNS = [
  {
    header: 'Address',
    accessorKey: 'address',
  },
  {
    header: 'Delivery Date',
    accessorKey: 'deliveryDate',
    cell: ({ getValue }) => formatDate(getValue(), 'MMM yyyy'),
  },
  {
    header: 'Available',
    accessorKey: 'available',
    cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
  },
];

function AddressTable({ inventoryAddresses }) {
  return (
    <DataTable
      columns={ADDRESS_TABLE_COLUMNS}
      data={inventoryAddresses}
      theadClassName="text-label-md text-neutral-medium font-medium uppercase"
      tdClassName="py-2"
    />
  );
}

function HomeModelAbout({ homeModel, subdivision }) {
  const { amenities: subdivisionAmenities } = subdivision;
  const { amenities: homeModelAmenities, futureDeliveries, inventoryAddresses, numAvailable } = homeModel;
  const amenities = homeModelAmenities.length ? homeModelAmenities : subdivisionAmenities;
  const totalAvailable = numAvailable + sumBy(futureDeliveries, pair => pair[1]);
  const { elementarySchoolRating, juniorHighSchoolRating, highSchoolSchoolRating } = subdivision;
  const averageSchoolRating = (elementarySchoolRating || juniorHighSchoolRating || highSchoolSchoolRating) ? mean(compact([elementarySchoolRating, juniorHighSchoolRating, highSchoolSchoolRating])).toFixed(1) : '-';

  return (
    <div className="mt-12">
      <div className="pb-3 mb-3 text-xl text-gray-700 border-b border-gray-200">About this home model</div>
      <div className="mt-6 grid grid-cols-2 gap-x-12">
        <div>
          <div className="text-sm text-gray-600">Inventory</div>
          <div className="mt-3 grid grid-rows-2 grid-flow-col tabular-nums *:p-2 bg-white rounded">
            <FutureDeliveriesGridHeader className="border-b">Available Now</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell>{numAvailable}</FutureDeliveriesGridCell>
            {futureDeliveries.flatMap(([date, value]) => [
              <FutureDeliveriesGridHeader key={date} className="border-b">{formatDate(date, 'MMM yyyy')}</FutureDeliveriesGridHeader>,
              <FutureDeliveriesGridCell key={`${date}-value`}>{value}</FutureDeliveriesGridCell>,
            ])}
            <FutureDeliveriesGridHeader className="border-b border-l">Total</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell className="border-l">{totalAvailable}</FutureDeliveriesGridCell>
          </div>
          <div className="mt-6 text-sm text-gray-600">Addresses</div>
          <div className="mt-3 px-3 bg-white rounded">
            {inventoryAddresses.length ? (
              <AddressTable inventoryAddresses={inventoryAddresses} />
            ) : <div className="py-3 text-gray-500">No address information available</div>}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-600">School Rating</div>
          <div className="mt-3 grid grid-rows-2 grid-flow-col tabular-nums *:p-2 bg-white rounded">
            <FutureDeliveriesGridHeader className="border-b">Elementary</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell>{elementarySchoolRating || '-'}</FutureDeliveriesGridCell>
            <FutureDeliveriesGridHeader className="border-b">Junior High</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell>{juniorHighSchoolRating || '-'}</FutureDeliveriesGridCell>
            <FutureDeliveriesGridHeader className="border-b">High School</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell>{highSchoolSchoolRating || '-'}</FutureDeliveriesGridCell>
            <FutureDeliveriesGridHeader className="border-b border-l">Average</FutureDeliveriesGridHeader>
            <FutureDeliveriesGridCell className="border-l">{averageSchoolRating}</FutureDeliveriesGridCell>
          </div>
          <div className="mt-6 text-sm text-gray-600">Amenities</div>
          <div className="mt-3 p-3 bg-white rounded">
            {amenities.length ? (
              <div className="font-light">{amenities.join(', ')}</div>
            ) : <div className="text-gray-500">No amenitity information available</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

function HomeModelBody(props) {
  const {
    deal,
    homeBuilder,
    homeModel,
    listing,
    modelData,
    parcels,
    property,
    subdivision,
    showMapModal,
    setShowMapModal,
    showPhotoModal,
    setShowPhotoModal,
    primaryPhotoIndex,
    setPrimaryPhotoIndex,
  } = props;

  const { images: pictures } = homeModel;

  const handlePhotoSelection = (index) => {
    setPrimaryPhotoIndex(index);
    setShowPhotoModal(true);
  };

  return (
    <PropertyLayoutContainer className="bg-gray-100" px="px-8" py="py-6">
      <OverviewHeader
        homeBuilder={homeBuilder}
        homeModel={homeModel}
        subdivision={subdivision}
        property={property}
        listing={listing}
      />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <HomeModelUnderwriting
            deal={deal}
            homeModel={homeModel}
            modelData={modelData}
            subdivision={subdivision}
          />
          <HomeModelAbout homeModel={homeModel} subdivision={subdivision} />
        </div>
        <div>
          {!!pictures.length && (
            <div className="relative w-auto rounded-lg cursor-pointer row-span-full col-span-4" onClick={() => handlePhotoSelection(0)}>
              <img
                src={pictures ? pictures[0].url : ''}
                className="w-full h-full object-cover object-center rounded-lg"
                referrerPolicy="no-referrer"
                alt={pictures ? pictures[0].url : ''}
              />
              <PhotoModal
                photos={pictures}
                property={property}
                showModal={showPhotoModal}
                setShowModal={setShowPhotoModal}
                primaryPhotoIndex={primaryPhotoIndex}
                setPrimaryPhotoIndex={setPrimaryPhotoIndex}
              />
              <Badge label={pictures ? `${pictures.length} Photos` : ''} className="absolute bottom-5 right-5 bg-white border" />
            </div>
          )}
          <div className={cx('relative h-60', pictures.length ? 'mt-6' : '')}>
            <Map properties={[property]} options={DEFAULT_MAP_OPTIONS} borderRadius="0.5rem" />
            <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={undefined}>
              <Map properties={[property]} />
            </MapModal>
            <Badge label="Click to expand" className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg" visible onClick={() => setShowMapModal(true)} />
          </div>
          <div className="mt-6">
            <PropertyDetails
              homeBuilder={homeBuilder}
              homeModel={homeModel}
              listing={listing}
              parcels={parcels}
              property={property}
              subdivision={subdivision}
              units={modelData.model.dcfParams.units}
            />
          </div>
        </div>
      </div>
    </PropertyLayoutContainer>
  );
}

function OffMarketBody() {
  return (
    <>
      <div className="pb-2 border-b border-gray-200">About this property</div>
      <div className="mt-4 font-light">Off Market</div>
    </>
  );
}

function OverviewBody({ currentUser, listing, model, property, returnMetrics }) {
  if (!listing) {
    return <OffMarketBody property={property} />;
  }

  if (listing.offMarketMarketplace) {
    return (
      <OffMarketMarketplaceBody
        currentUser={currentUser}
        listing={listing}
        model={model}
        property={property}
        returnMetrics={returnMetrics}
      />
    );
  }

  return (
    <MlsListingBody
      listing={listing}
      model={model}
      property={property}
      returnMetrics={returnMetrics}
    />
  );
}

export default function Overview({ context }) {
  const { data, modelData } = context;
  const [showMapModal, setShowMapModal] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [primaryPhotoIndex, setPrimaryPhotoIndex] = useState(0);

  const { currentUser, deal, listing, homeModel, owners, parcels, property, properties, subdivision, homeBuilder } = data;
  const pictures = listing ? listing.pictures : [];
  const owner = owners[0]; // TODO: figure out most appropriate owner to select
  const { model } = modelData;
  const returnMetrics = calcReturnMetrics(model.cashFlows, model.dcfParams);

  if (homeModel) {
    return (
      <HomeModelBody
        deal={deal}
        homeBuilder={homeBuilder}
        homeModel={homeModel}
        listing={listing}
        modelData={modelData}
        parcels={parcels}
        property={property}
        returnMetrics={returnMetrics}
        subdivision={subdivision}
        showMapModal={showMapModal}
        setShowMapModal={setShowMapModal}
        showPhotoModal={showPhotoModal}
        setShowPhotoModal={setShowPhotoModal}
        primaryPhotoIndex={primaryPhotoIndex}
        setPrimaryPhotoIndex={setPrimaryPhotoIndex}
      />
    );
  }

  return (
    <PropertyLayoutContainer py="pt-2">
      <OverviewHeader homeBuilder={homeBuilder} subdivision={subdivision} property={property} listing={listing} returnMetrics={returnMetrics} />
      <PhotoGrid
        listing={listing}
        pictures={pictures}
        property={property}
        properties={properties}
        showModal={showPhotoModal}
        setShowModal={setShowPhotoModal}
        primaryPhotoIndex={primaryPhotoIndex}
        setPrimaryPhotoIndex={setPrimaryPhotoIndex}
        showMapModal={showMapModal}
        setShowMapModal={setShowMapModal}
      />
      <div className="py-6 w-full flex gap-x-6">
        <div className="w-8/12">
          <OverviewBody
            currentUser={currentUser}
            listing={listing}
            homeModel={homeModel}
            model={model}
            property={property}
            returnMetrics={returnMetrics}
          />
        </div>
        <div className="w-4/12">
          <PropertyDetails
            parcels={parcels}
            property={property}
            owner={owner}
            units={model.dcfParams.units}
            listing={listing}
          />
        </div>
      </div>
      <Disclaimer listing={listing} />
    </PropertyLayoutContainer>
  );
}
