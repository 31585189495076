import { nanoid } from '@reduxjs/toolkit';
import { startTransition, useCallback, useEffect, useState } from 'react';
import Alert from 'components/Alert';
import { formatMention } from 'components/Features/Notification/MentionedInDealNotification';
import { Plus } from 'components/icons';
import MentionUsersTextarea from 'components/shared/MentionUsersTextarea';
import Button from 'components/shared/NewButton';
import { formatTimeZone } from 'components/utils';
import { useCreateListingNotesMutation, useFetchListingNotesQuery } from '../../redux/listingNoteApiSlice';
import { useFetchMentionableOffMarketBrokersQuery, useFetchSelfQuery } from '../../redux/apiSlice';

const createStubNote = ({ id, email, fullName, organization: { id: orgId, name: orgName } = {} } = {}) => ({
  id: nanoid(),
  body: '',
  noteGeneratedBy: {
    id,
    email,
    fullName,
    organization: { id: orgId, name: orgName },
  },
});

function AddListingNote({ listingId, organizationId, setPendingNotes, isFetchingNotes }) {
  const { currentData: offMarketBrokers } = useFetchMentionableOffMarketBrokersQuery();
  const [note, setNote] = useState(createStubNote);

  const { currentData: user } = useFetchSelfQuery();
  useEffect(() => {
    if (!user) {
      return;
    }

    const { id, email, fullName, organization: { id: orgId, name: orgName } = {} } = user;
    setNote((prev) => ({
      ...prev,
      noteGeneratedBy: {
        id,
        email,
        fullName,
        organization: { id: orgId, name: orgName },
      },
    }));
  }, [user]);

  const onChange = useCallback((event, value) => {
    setNote((prev) => ({ ...prev, body: value }));
  }, []);

  const [trigger, { isLoading, isSuccess, isError, reset }] = useCreateListingNotesMutation();
  const createNote = () => {
    startTransition(() => {
      setPendingNotes([note]);
    });
    trigger({ listingId, body: note.body });
  };

  useEffect(() => {
    if (isSuccess && !isFetchingNotes) {
      setNote(createStubNote(user));
      setPendingNotes([]);
      reset();
    }
  }, [isSuccess, setPendingNotes, user, isFetchingNotes, reset]);

  if (!listingId || !organizationId) {
    return null;
  }

  return (
    <li className="p-0.5">
      <MentionUsersTextarea
        // pass orgId to fetch users from the org that uploaded the listing
        organizationId={organizationId}
        additionalUsers={offMarketBrokers}
        placeholder="Add note. Mention people using '@'"
        value={note.body}
        onChange={onChange}
      />
      <Button
        textOnly
        small
        label="Add"
        leadingIcon={<Plus className="size-5" />}
        isLoading={isLoading}
        disabled={!note.body?.trim()?.length}
        onClick={createNote}
        className="ml-auto mt-1"
      />
      {isError && <Alert className="whitespace-pre-line" type="danger" text="Failed to create note" />}
      <p className="text-body-sm text-neutral-light text-right text-pretty">
        Notes are visible to anyone in your organization and the seller&apos;s broker.
      </p>
    </li>
  );
}

function ListingNote({ note: { id, body, createdAt, noteGeneratedBy: { fullName, email, organization: { name: orgName } } = {} } }) {
  return (
    <li key={id} className="py-2">
      <div className="flex flex-row justify-between gap-x-0.5">
        <div className="text-label-md text-neutral-dark whitespace-nowrap">{fullName ?? email}</div>
        <div className="text-body-sm tracking-tight text-neutral-dark text-right tabular-nums whitespace-nowrap">
          {createdAt && formatTimeZone(createdAt, 'MMM d, yyyy HH:mm (zzz)')}
        </div>
      </div>
      <div className="text-label-md text-neutral-medium whitespace-nowrap">{orgName}</div>
      <p className="mt-2 text-body-md text-neutral-dark break-words text-pretty whitespace-pre-wrap">{formatMention(body)}</p>
    </li>
  );
}

export default function OffMarketListingNotes({ listingId, organizationId }) {
  const { currentData: notes, isFetching, isError } = useFetchListingNotesQuery({ listingId }, { refetchOnMountOrArgChange: true, pollingInterval: 5000 });
  const [pendingNotes, setPendingNotes] = useState([]);

  return (
    <ul className="px-0.5 flex flex-col-reverse divide-y divide-y-reverse">
      {notes?.map((note) => <ListingNote key={note.id} note={note} />)}
      {pendingNotes?.map((note) => <ListingNote key={note.id} note={note} />)}
      {(isFetching && !notes) && <li>Loading...</li>}
      {isError && <li>Failed to load notes</li>}
      <AddListingNote
        listingId={listingId}
        organizationId={organizationId}
        setPendingNotes={setPendingNotes}
        isFetchingNotes={isFetching}
      />
    </ul>
  );
}
