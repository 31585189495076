import { useCallback, useMemo } from 'react';
import { get, set, noop, unset } from 'lodash';
import {
  getCachedUserPreferenceValue,
  useFetchUserPreferenceQuery,
  useUpdateUserPreferenceMutation,
  useUpdateFetchUserPreferenceQueryData,
} from '../redux/userPreferencesApiSlice';

/**
 * A hook used to persist and recall a value using browser localStorage
 *
 * @param {string} key - Used as the unique key to store the value in localStorage
 * @param {any} defaultValue - An initial value returned if no value is stored
 * @param {number} version - An integer representing the current version of the value format.
 *                           Incrementing this value in the code will cause the hook to return
 *                           the defaultValue upon load instead of the previously stored value
 *                           which can be used to migrate the value structure.
 * @param {(string | number)[]} [path] - The path of the value to operate on
 * @param {boolean} [skip] - true to always return the defaultValue.
 *                           The value will still be fetched but not returned.
 *                           Useful for when only the setter is used.
 */
const useUserPreference = (key, defaultValue, version, path, { skip = false } = {}) => {
  const selector = useCallback(({ originalArgs, currentData }) => {
    let data = null;
    if (!skip) {
      data = path?.length ? get(currentData?.preferenceValue, path) : currentData?.preferenceValue;
    }

    return {
      originalArgs,
      currentData: data,
      // currentData should only ever be undefined/null for a short window before it's initialized from localStorage
      // even if localStorage doesn't have the key or request failed
      isUninitialized: currentData === undefined,
    };
  }, [path, skip]);

  const {
    originalArgs: queryArgs,
    currentData: remoteValue,
    isUninitialized,
  } = useFetchUserPreferenceQuery({ preferenceKey: key, preferenceVersion: version }, { selectFromResult: selector });
  const [updateUserPref] = useUpdateUserPreferenceMutation();

  const updateQueryData = useUpdateFetchUserPreferenceQueryData(queryArgs);
  const setValue = useCallback((update) => {
    // need to optimistically update query data so subsequent mutations don't overwrite this mutation
    const updatedValue = updateQueryData((prev) => {
      let updatedPrefVal;
      if (typeof update === 'function') {
        const currPrefVal = (path?.length ? get(prev.preferenceValue, path) : prev.preferenceValue) ?? defaultValue;
        updatedPrefVal = update(currPrefVal);
      } else {
        updatedPrefVal = update;
      }

      updatedPrefVal ??= null;
      if (path?.length) {
        if (updatedPrefVal === null) {
          unset(prev, ['preferenceValue', ...path]);
        } else {
          // append 'preferenceValue' to path to prevent set from operating on a null root value
          set(prev, ['preferenceValue', ...path], updatedPrefVal);
        }

        updatedPrefVal = prev.preferenceValue;
      }

      return {
        ...prev,
        preferenceValue: updatedPrefVal,
      };
    });

    updateUserPref(updatedValue);
  }, [defaultValue, path, updateQueryData, updateUserPref]);

  let returnVal = remoteValue;
  if (!skip && isUninitialized) {
    returnVal = getCachedUserPreferenceValue({ preferenceKey: key, preferenceVersion: version });
    if (path?.length) {
      returnVal = get(returnVal, path);
    }
  }
  returnVal ??= defaultValue;

  const setter = isUninitialized ? noop : setValue;

  return useMemo(() => [returnVal, setter], [returnVal, setter]);
};

export default useUserPreference;
