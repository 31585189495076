import { isNil } from 'lodash';

export const STORAGE_VERSION = 0;
export const FALLBACK_VISIBILITY = true;
export const FALLBACK_ORDER = Object.freeze([]);

export const EMPTY_PRESET = Object.freeze({
  name: 'Default',
  defaultVisibility: true,
  toggleSection: Object.freeze({}),
  visibility: Object.freeze({}),
  order: FALLBACK_ORDER,
});

/**
 * @param {string} tableId
 */
export const getDefaultPresetId = ({ tableId }) => `${tableId}.default`;

const visibilityRootPath = Object.freeze(['visibility']);
/**
 * @param {import('@tanstack/react-table').Column['id']} [columnId]
 */
export const visibilityConfigPath = ({ columnId } = {}) => (
  isNil(columnId) ? visibilityRootPath : [...visibilityRootPath, columnId]
);

/**
 * @param {string | null | undefined} toggleSection
 */
export const visibilitySectionConfigPath = ({ toggleSection }) => (
  isNil(toggleSection) ? ['toggleDefaultSection'] : ['toggleSection', toggleSection]
);

export const orderConfigPath = Object.freeze(['order']);

export const activePresetConfigPath = Object.freeze(['activePreset']);

/**
 * @param {string} tableId
 */
export const tableConfigStorageKey = ({ tableId }) => `DataTableConfig.${tableId}`;

/**
 * @param {string} tableId
 */
export const presetConfigStorageKey = ({ presetId }) => `DataTableConfig.${presetId}`;

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
const isTableConfigDisabled = (column) => column.columnDef.meta?.tableConfig === false;

/** @type {(column: import('@tanstack/react-table').Column) => string | boolean} */
export const getColumnVisibilityConfigurable = (column) => {
  if (isTableConfigDisabled(column)) {
    return false;
  }

  // note 'false' value for visibilityConfigurable should only be defined statically with table/column definition
  const { columnDef: { meta: { tableConfig: { visibilityConfigurable } = {} } = {} } } = column;
  if (visibilityConfigurable === false) {
    return false;
  }

  if (!column.getCanHide()) {
    return 'readonly';
  }

  return visibilityConfigurable ?? true;
};

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
export const isColumnVisibilityToggleable = (column) => getColumnVisibilityConfigurable(column) === true;

/** @type {(column: import('@tanstack/react-table').Column) => string | boolean} */
export const getColumnOrderConfigurable = (column) => {
  if (isTableConfigDisabled(column)) {
    return false;
  }

  const { columnDef: { meta: { tableConfig: { orderConfigurable } = {} } = {} } } = column;
  return orderConfigurable ?? true;
};

/** @type {(column: import('@tanstack/react-table').Column) => boolean} */
export const isColumnOrderDraggable = (column) => getColumnOrderConfigurable(column) === true;
