import { camelCaseKeys, coalesceListing, snakeCaseKeys, standardizeListing } from 'components/utils';
import { apiSlice } from './apiSlice';
import { OFF_MARKET_LISTINGS_TAG, OFF_MARKET_OFFERS_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createListing: builder.mutation({
        query: listing => ({
          url: '/api/off_market_marketplace/listings',
          method: 'POST',
          body: snakeCaseKeys(listing),
        }),
        invalidatesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
      fetchOffMarketMarketplaceBuyBox: builder.query({
        query: () => '/api/off_market_marketplace/buy_box',
        transformResponse: (response) => camelCaseKeys(response),
      }),
      fetchOffMarketMarketplaceDashboardStatistics: builder.query({
        query: () => '/api/off_market_marketplace/dashboard',
        transformResponse: (response) => camelCaseKeys(response),
      }),
      fetchOffMarketMarketplaceInventory: builder.query({
        query: () => '/api/off_market_marketplace/listings',
        transformResponse: (response) => camelCaseKeys(response).data.map(({ attributes: listing }) =>
          coalesceListing({ listing: standardizeListing(listing), parcel: listing.parcel })),
        providesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
      fetchOffMarketMarketplaceOffers: builder.query({
        query: () => '/api/off_market_marketplace/offers',
        transformResponse: (response) => camelCaseKeys(response).data.map(d => d.attributes),
        providesTags: [OFF_MARKET_OFFERS_TAG],
      }),
      updateOfferStatus: builder.mutation({
        query: ({ id, ...offerStatusUpdates }) => ({
          url: `/api/off_market_marketplace/${id}/status`,
          method: 'POST',
          body: snakeCaseKeys(offerStatusUpdates),
        }),
        invalidatesTags: [OFF_MARKET_OFFERS_TAG],
      }),
    };
  },
});

export const {
  useCreateListingMutation,
  useFetchOffMarketMarketplaceBuyBoxQuery,
  useFetchOffMarketMarketplaceDashboardStatisticsQuery,
  useFetchOffMarketMarketplaceInventoryQuery,
  useFetchOffMarketMarketplaceOffersQuery,
  useUpdateOfferStatusMutation,
} = extendedApiSlice;
