import { Link } from 'react-router-dom';
import cx from 'classnames';
import { sortBy } from 'lodash';
import { ACTIVE_STATUS } from 'components/constants';
import Badge from 'components/shared/Badge';
import { formatCurrency } from 'components/utils';

function ListingBadge({ color, status, price, onClick, listingId }) {
  return (
    <Link to={`/listings/${listingId}`} target="_blank">
      <Badge
        onClick={onClick}
        className={cx('text-white max-w-max text-shadow', color)}
        label={`${status} • ${formatCurrency(price)}`}
      />
    </Link>
  );
}

export default function MlsStatusCell({ getValue }) {
  const mlsListings = getValue();

  if (!mlsListings) return null;

  const activeListing = mlsListings.find(l => l.standardStatus === ACTIVE_STATUS);

  if (activeListing) {
    return (
      <ListingBadge
        listingId={activeListing.id}
        status="Active"
        color="bg-green-500"
        price={activeListing.listPrice}
      />
    );
  }

  const mostRecentListing = sortBy(mlsListings, l => l.listingContractDate)[0];

  return (
    <ListingBadge
      listingId={mostRecentListing.id}
      status={mostRecentListing.standardStatus}
      color="bg-gray-400"
      price={mostRecentListing.closePrice || mostRecentListing.listPrice}
    />
  );
}
