/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, startCase } from 'lodash';
import { useFetchDealImportJobQuery, useUpdateDealImportJobMutation } from 'redux/dealImportJobApiSlice';
import { CheckCircle, ExclamationCircle, LoadingIndicator } from 'components/icons';
import { dealPath, portfolioPath } from 'components/routes';
import Button from 'components/shared/NewButton';
import Input from 'components/Input';
import { PARCEL_MATCHING_NEW_BUILD } from 'components/Import/Deal/SettingsForm';

const POLLING_FREQUENCY = 5_000;

const formatAddress = (streetAddress, city, state, zip) => (
  <>
    <div>{startCase(streetAddress)}</div>
    <div className="text-sm">{`${startCase(city)}, ${state} ${zip}`}</div>
  </>
);

function MatchRow({ rowData, matchResult, importResult, isImporting, rowNumber, lastRow, settings }) {
  const [, parcel, property] = matchResult || [];
  const { parcelMatching } = settings;

  const inputMatchAddress = (parcelMatching === 'parcel')
    ? [rowData.parcelNumber, rowData.county].join(', ')
    : formatAddress(rowData.address, rowData.city, rowData.state, rowData.zip);

  let matchDataComponent = null;
  if (parcel && property) {
    matchDataComponent = (
      <>
        <div className="w-1/3 whitespace-pre-wrap">
          {formatAddress(property.address, property.city, property.state, property.zipCode)}
        </div>
        <div className="w-1/6">
          <div>{parcel.apn}</div>
          <div className="text-sm">{parcel.county}</div>
        </div>
        <div className="w-1/12 items-center">
          {(isImporting && !importResult) ? (
            <LoadingIndicator className="text-gray-500 w-7 h-7" />
          ) : (
            <CheckCircle filled className="text-green-500 w-7 h-7" />
          )}
        </div>
      </>
    );
  } else if (matchResult && parcelMatching !== PARCEL_MATCHING_NEW_BUILD) {
    matchDataComponent = (
      <>
        <div className="w-1/2 whitespace-pre-wrap">
          <div className="text-gray-500 font-light">
            <div>Parcel not found</div>
            <div className="text-sm">Confirm that the address matches the county assessor&apos;s situs address</div>
          </div>
        </div>
        <div className="w-1/12 items-center">
          {isImporting ? (
            importResult ? (
              <CheckCircle filled className="text-green-500 w-7 h-7" />
            ) : (
              <LoadingIndicator className="text-gray-500 w-7 h-7" />
            )
          ) : (
            <ExclamationCircle filled className="text-yellow-500 w-7 h-7" />
          )}
        </div>
      </>
    );
  } else if (matchResult && parcelMatching === PARCEL_MATCHING_NEW_BUILD) {
    matchDataComponent = (
      <div className="w-1/2 text-sm truncate">
        {Object.entries(rowData).map(entry => `${entry[0]}: ${entry[1]}`).join(', ')}
      </div>
    );
  } else {
    matchDataComponent = (
      <>
        <div className="w-1/2 whitespace-pre-wrap">
          <div className="text-gray-500 font-light">
            <div>Matching in progress...</div>
          </div>
        </div>
        <div className="w-1/12 items-center">
          <LoadingIndicator className="text-gray-500 w-7 h-7" />
        </div>
      </>
    );
  }

  return (
    <div className={classNames('flex flex-row items-center mb-3 pb-3', lastRow ? '' : 'border-b')}>
      <div className="w-1/12">{rowNumber}</div>
      <div className="w-1/3 whitespace-pre-wrap">{inputMatchAddress}</div>
      {matchDataComponent}
    </div>
  );
}

const STATUS_VALIDATING = 'validating';
const STATUS_MATCHED = 'matched';
const STATUS_IMPORTING = 'importing';
const STATUS_COMPLETE = 'complete';
const STATUS_ERROR = 'error';

const getImportStatus = (dealImportJob) => {
  if (!dealImportJob) return;

  const { importData: { csvData }, resultData, validationData } = dealImportJob;
  const { matchResults } = validationData;

  if (!isEmpty(resultData)) {
    // TODO: check if we can update incrementally when importing individual deals
    const { importError, results } = resultData;
    if (importError) {
      return STATUS_ERROR;
    }
    return (results && !isEmpty(results)) ? STATUS_COMPLETE : STATUS_IMPORTING;
  }

  // TODO: worth adding an enqueued status to indicate that the job is waiting to be processed?
  if (isEmpty(validationData)) {
    return STATUS_VALIDATING;
  }

  return (matchResults.length === csvData.length) ? STATUS_MATCHED : STATUS_VALIDATING;
};

export default function ParcelMatchForm() {
  const [pollingInterval, setPollingInterval] = useState();
  const navigate = useNavigate();
  const { dealImportJobId } = useParams();
  const [createParcels, setCreateParcels] = useState(true);

  const { data: dealImportJob, isLoading: isFetching } = useFetchDealImportJobQuery(dealImportJobId, { pollingInterval });
  const [updateDealImportJobMutation, { isLoading: isUpdating }] = useUpdateDealImportJobMutation();
  // TODO: handle errors

  const importStatus = getImportStatus(dealImportJob);

  useEffect(() => {
    if (importStatus === STATUS_COMPLETE) {
      if (dealImportJob.resultData.results.length === 1) {
        // single deal created, so navigate to that deal page
        navigate(dealPath(dealImportJob.resultData.results[0].deal));
      } else {
        navigate(portfolioPath({ id: dealImportJob.importData.settings.portfolioId }));
      }
    } else if (!importStatus || [STATUS_ERROR, STATUS_MATCHED].includes(importStatus)) {
      setPollingInterval(null);
    } else {
      setPollingInterval(POLLING_FREQUENCY);
    }
  }, [importStatus]);

  if (isFetching) {
    return <LoadingIndicator className="w-8 text-blue-400" />;
  }

  const { importData, resultData: { importError, results }, validationData: { matchResults } } = dealImportJob;
  const { csvData, settings } = importData;

  const showUnmatchedParcelsWarning = matchResults?.find(rowData => !rowData[1]) && (importStatus === STATUS_MATCHED);

  const confirmParcelMatches = () => {
    updateDealImportJobMutation({ ...dealImportJob, importData: { ...importData, settings: { ...settings, createParcels } } });
  };

  const canConfirm = !isUpdating && (importStatus === STATUS_MATCHED);

  return (
    <div className="w-3/4 mt-12 mx-auto p-6 bg-white rounded">
      <div className="mb-6 text-lg text-gray-900">{[STATUS_ERROR, STATUS_IMPORTING].includes(importStatus) ? 'Creating Deals' : 'Confirm Parcel Matching'}</div>
      <div className="flex flex-row mb-4 text-sm text-gray-500">
        <div className="w-1/12">Row</div>
        <div className="w-1/3">Input Address</div>
        {(settings.parcelMatching === PARCEL_MATCHING_NEW_BUILD) ? (
          <div className="w-1/2">Property Data</div>
        ) : (
          <>
            <div className="w-1/3">Parcel Situs Address</div>
            <div className="w-1/6">Parcel Number</div>
          </>
        )}
      </div>
      {csvData.map((rowData, index) => (
        <MatchRow
          key={index}
          rowData={rowData}
          matchResult={matchResults?.[index]}
          importResult={results?.[index]}
          isImporting={importStatus === STATUS_IMPORTING}
          rowNumber={index + 1}
          lastRow={index === (csvData.length - 1)}
          settings={settings}
        />
      ))}
      {showUnmatchedParcelsWarning && (settings.parcelMatching !== PARCEL_MATCHING_NEW_BUILD) && (
        <div className="p-4 border-warning-300 bg-warning-100 rounded">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label className="cursor-pointer" htmlFor="createParcel" onClick={() => setCreateParcels(!createParcels)}>
            <Input toggleClassName="mr-6" type="checkbox" value={createParcels} />
            Create deals for unmatched parcels?
          </label>
          <div className="mt-2 pl-16 text-sm">Honeycomb will use the provided latitude, longitude and property information for any deals that were not able to be parcel matched</div>
        </div>
      )}
      {importError && (
        <div className="p-4 border-error-300 bg-error-100 rounded">
          <div className="pl-16">Failed to import data</div>
          <div className="mt-2 pl-16 text-sm">{importError}</div>
        </div>
      )}
      <div className="mt-12 flex justify-center gap-x-12 items-center">
        <Button
          outlined
          label="Back"
          onClick={() => navigate('/import/deals')}
        />
        <Button
          filled
          label="Confirm"
          isLoading={isUpdating}
          disabled={!canConfirm}
          onClick={confirmParcelMatches}
        />
      </div>
    </div>
  );
}
