import { Fragment, useCallback } from 'react';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';

const DEFAULT_ZOOM = 12;

export default function Map({ blockGeom, zipCodeGeom, censusTractGeom, properties, options = {}, borderRadius = 0 }) {
  const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius,
  };

  const getCenter = () => {
    const prop = properties[0];
    const center = { lat: parseFloat(prop.latitude), lng: parseFloat(prop.longitude) };
    return center;
  };

  const createPolygonComponent = (geom, fillColor) => {
    if (!geom) return { polygonComponent: null, polygonPoints: [] };
    const polygonOptions = {
      fillColor,
      strokeWeight: 1,
    };

    let polygonPoints = [];

    const polygonCoords = geom.type === 'Polygon' ? geom.coordinates : geom.coordinates.map(paths => paths[0]);

    const polygonComponent = polygonCoords.map((path, idx) => {
      polygonPoints = polygonPoints.concat(path);
      return (
        <Fragment key={idx}>
          <Polygon path={path.map(pair => ({ lat: pair[1], lng: pair[0] }))} options={polygonOptions} />
        </Fragment>
      );
    });

    return { polygonComponent, polygonPoints };
  };

  const { polygonComponent: zipPolygonComponent, polygonPoints: zipPolygonPoints } = createPolygonComponent(zipCodeGeom, '#002D6F', 'Zip');
  const { polygonComponent: censusTractPolygonComponent, polygonPoints: censusTractPolygonPoints } = createPolygonComponent(censusTractGeom, '#00FF00', 'Census Tract');
  const { polygonComponent: blockPolygonComponent, polygonPoints: blockPolygonPoints } = createPolygonComponent(blockGeom, '#0000FF', 'Census Block');

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (zipCodeGeom || censusTractGeom || blockGeom) {
      if (zipPolygonPoints) zipPolygonPoints.forEach(pair => bounds.extend({ lat: pair[1], lng: pair[0] }));
      if (censusTractPolygonComponent) censusTractPolygonPoints.forEach(pair => bounds.extend({ lat: pair[1], lng: pair[0] }));
      if (blockPolygonComponent) blockPolygonPoints.forEach(pair => bounds.extend({ lat: pair[1], lng: pair[0] }));
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    } else if (properties?.length && properties.length > 1) {
      properties.forEach(property => {
        if (property.latitude && property.longitude) {
          bounds.extend({ lat: parseFloat(property.latitude), lng: parseFloat(property.longitude) });
        }
      });
      map.fitBounds(bounds);
    }
  }, [zipPolygonPoints, censusTractPolygonPoints, blockPolygonPoints, properties]);

  const markers = properties.map((prop, index) => (
    <Marker
      key={`marker-${index}`}
      icon={{
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
        fillColor: '#34d399',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 3,
        scale: 3,
        anchor: new window.google.maps.Point(11, 23),
      }}
      position={{
        lat: parseFloat(prop.latitude),
        lng: parseFloat(prop.longitude),
      }}
    />
  ));

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={getCenter()}
      options={options}
      onLoad={onLoad}
      zoom={options.zoom || DEFAULT_ZOOM}
    >
      {markers}
      {zipPolygonComponent}
      {censusTractPolygonComponent}
      {blockPolygonComponent}
    </GoogleMap>
  );
}
