/* eslint-disable import/prefer-default-export */
import { isNil } from 'lodash';

export const childScenarios = ({ scenarios, primaryOnly = false, parentScenarioId = undefined }) => (
  scenarios?.filter(({ scenarioId, primary }) => (
    (!primaryOnly || primary)
    && (isNil(parentScenarioId) || scenarioId === parentScenarioId)
    && !isNil(scenarioId)
  )) ?? []
);
