import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import { meanBy } from 'lodash';
import { useMemo } from 'react';
import { useFetchSaleCompsQuery } from 'redux/apiSlice';
import { SCORE_COLORS } from 'components/constants';
import { defaultActiveSaleComp } from 'components/saleComps/saleComps';
import {
  calcCompScoreTier,
  formatCurrency,
  formatEquityMultiple,
  formatPercentage,
  parsePropertyIdFromComp,
} from 'components/utils';
import ListingStat from './ListingStat';

function SaleCompStat({ propertyId }) {
  const meanSelector = useMemo(() => (
    createSelector(
      [
        ({ property } = {}) => property,
        ({ comps } = {}) => comps,
      ],
      (
        property,
        { data: comps = [] } = {},
      ) => {
        const compsAttributes = comps
          .map(({ attributes }) => attributes)
          .sort(({ distance: left }, { distance: right }) => left - right);
        const activeSet = defaultActiveSaleComp(compsAttributes, property);
        const activeComps = compsAttributes.filter(c => activeSet[parsePropertyIdFromComp(c)]);
        const meanValue = meanBy(activeComps, 'sale.salePrice');

        return (Number.isFinite(meanValue) && meanValue > 0) ? meanValue : null;
      },
    )
  ), []);

  const { status: queryStatus, meanValue } = useFetchSaleCompsQuery(
    { propertyId },
    {
      skip: !propertyId,
      selectFromResult: ({ status, currentData }) => ({
        status,
        meanValue: meanSelector(currentData),
      }),
    },
  );

  let displayValue;
  switch (queryStatus) {
    case QueryStatus.fulfilled: {
      displayValue = formatCurrency(meanValue);
      break;
    }
    case QueryStatus.rejected: {
      displayValue = formatCurrency(null);
      break;
    }
    default: {
      displayValue = formatCurrency(0);
      break;
    }
  }

  return (
    <ListingStat
      value={displayValue}
      label="Sale Comp Avg."
      loading={queryStatus === QueryStatus.pending || queryStatus === QueryStatus.uninitialized}
    />
  );
}

export default function ListingStatsGrid({
  item: {
    compRent,
    compRentScore,
    customCompRent,
    goingInCapRate,
    grossStabilizedYield,
    leveredIrr,
    stabilizedYield,
    unleveredCashOnCash,
    unleveredEquityMultiple,
    unleveredIrr,
    propertyId,
  },
  portfolio,
  singleFamily,
}) {
  let marketRentValue, marketRentAvgScore;
  if (singleFamily) {
    marketRentValue = compRent;
    marketRentAvgScore = compRentScore;
    if (customCompRent) {
      marketRentValue = customCompRent.rent;
      marketRentAvgScore = customCompRent.score;
    }
  }
  const leveredUnleveredValue = `${formatPercentage(unleveredIrr, 2)} • ${formatPercentage(leveredIrr, 2)}`;
  const leveredUnleveredText = 'Unlevered • Levered IRR';
  const avgCashYieldEquityMultipleTextValue = `${formatPercentage(unleveredCashOnCash, 2)} • ${formatEquityMultiple(unleveredEquityMultiple)}`;
  const avgCashYieldEquityMultipleText = 'Avg Cash Yield • EM';
  const stabilizedYieldAndGrossStabilizedYieldValue = `${formatPercentage(stabilizedYield, 2)} • ${singleFamily ? formatPercentage(grossStabilizedYield, 2) : formatPercentage(goingInCapRate, 2)}`;
  const stabilizedYieldAndGrossStabilizedYieldText = `Stab. Yield • ${singleFamily ? 'Gross Yield' : 'Asking Cap'}`;

  const stats = [];
  if (portfolio && portfolio.autoValuations) {
    stats.push(<ListingStat key="irr" value={leveredUnleveredValue} label={leveredUnleveredText} />);
    stats.push(<ListingStat key="stbYield" value={stabilizedYieldAndGrossStabilizedYieldValue} label={stabilizedYieldAndGrossStabilizedYieldText} />);

    if (!singleFamily) {
      stats.push(<ListingStat key="cashEm" value={avgCashYieldEquityMultipleTextValue} label={avgCashYieldEquityMultipleText} />);
    }
  }

  if (singleFamily || !portfolio) {
    let marketRentString;
    if (!marketRentValue) {
      marketRentString = '-';
    } else {
      marketRentString = marketRentAvgScore ? (
        <div className="flex justify-center items-center gap-x-2">
          <div>{formatCurrency(marketRentValue)}</div>
          <div className={cx(SCORE_COLORS[calcCompScoreTier(marketRentAvgScore)], 'size-3 rounded-full')} />
        </div>
      ) : formatCurrency(marketRentValue);
    }
    stats.push(<ListingStat key="marketRent" value={marketRentString} label="Market Rent Avg." />);
  }

  if (!portfolio) {
    stats.push(<SaleCompStat key="saleComp" propertyId={propertyId} />);
  }

  return (
    <div className={`flex divide-x items-center ${(stats.length === 1) ? 'justify-center' : 'justify-between'}`}>
      {stats}
    </div>
  );
}
