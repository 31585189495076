import { useMemo } from 'react';
import { naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { useFetchHomeBuildersQuery } from '../../../redux/homeBuilderApiSlice';

const selectHomeBuilders = ({ currentData, isFetching, isUninitialized }) => ({
  currentData,
  isFetching: isFetching || isUninitialized,
});

const useSortedOptions = (currentData) => (
  useMemo(() => {
    const comparator = naturalSortComparator();

    return currentData
      ?.map(({ id, name }) => ({ id, name }))
      ?.toSorted(({ name: left }, { name: right }) => comparator(left, right))
      ?? null;
  }, [currentData])
);

export default function HomeBuilderFilter() {
  const { currentData, isFetching } = useFetchHomeBuildersQuery(undefined, { selectFromResult: selectHomeBuilders });
  const sortedHomeBuildersOptions = useSortedOptions(currentData);

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={sortedHomeBuildersOptions}
      keyBy="id"
      labelBy="name"
    />
  );
}
