import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useOnApply, useOnClear } from './filterHooks';
import { useFilterInputState } from './useFilterInputState';

export default function TrueFalseToggle() {
  const onApply = useOnApply();
  const onClear = useOnClear();
  const [value] = useFilterInputState();

  /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
  const onClick = useCallback(() => {
    if (isNil(value)) {
      onApply({ value: true });
    } else if (value === true) {
      onApply({ value: false });
    } else {
      onClear();
    }
  }, [onApply, onClear, value]);

  return (
    <div className="absolute inset-0" onClick={onClick} />
  );
}
