import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { partial } from 'lodash';
import { setAddressSearchModalIsOpen, setNewDealProperties } from 'redux/newDealSlice';
import Button from 'components/shared/NewButton';
import Modal from 'components/Modal';
import { ExclamationCircle, ExternalLink, LoadingIndicator, X } from 'components/icons';
import GoogleAddressSearch, { PARCEL_UNMATCHED_VALUE } from 'components/shared/GoogleAddressSearch';

function SelectedPropertyRow({ selectedProperty, onRemove }) {
  const { oneLineAddress, parcel } = selectedProperty;

  let parcelMatchIcon;
  if (!parcel) {
    parcelMatchIcon = <LoadingIndicator className="w-6" />;
  } else if (parcel === PARCEL_UNMATCHED_VALUE) {
    parcelMatchIcon = <ExclamationCircle className="w-6 text-warning-500" />;
  } else {
    const { fipsApn } = parcel;
    parcelMatchIcon = (
      <Link className="cursor-pointer" to={`/properties/${fipsApn}/fips_apn`} target="_blank">
        <ExternalLink className="w-6 hover:text-gray-500" />
      </Link>
    );
  }

  return (
    <div className="py-2 flex items-center justify-between">
      <X className="w-9 pr-3 hover:text-error-500 cursor-pointer" onClick={onRemove} />
      <div className="grow">{oneLineAddress}</div>
      {parcelMatchIcon}
    </div>
  );
}

export default function AddressSearchModal() {
  const [selectedProperties, setSelectedProperties] = useState([]);

  const onPropertySelected = (selectedProperty) => {
    setSelectedProperties(prevSelectedProperties => [...prevSelectedProperties, selectedProperty]);
  };
  const onPropertyUpdated = (updatedProperty) => {
    setSelectedProperties(prevSelectedProperties => prevSelectedProperties.map(psp => ((psp.id === updatedProperty.id) ? Object.assign(psp, updatedProperty) : psp)));
  };
  const onRemoveSelectedProperty = removeId => {
    setSelectedProperties(prevSelectedProperties => prevSelectedProperties.filter(p => p.id !== removeId));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal show showCloseAction={false}>
      <div className="w-128">
        <h3 className="text-2xl mb-8">Address Search</h3>
        <label className="text-gray-700 font-medium text-sm" htmlFor="addressSearchInput">Address</label>
        <GoogleAddressSearch
          allowManual
          onPropertySelected={onPropertySelected}
          onPropertyUpdated={onPropertyUpdated}
        />
        {selectedProperties.map(selectedProperty => (
          <SelectedPropertyRow
            key={selectedProperty.id}
            selectedProperty={selectedProperty}
            onRemove={partial(onRemoveSelectedProperty, selectedProperty.id)}
          />
        ))}
        <div className="mt-12 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            className="font-medium text-sm"
            onClick={() => dispatch(setAddressSearchModalIsOpen(false))}
          />
          <Button
            filled
            label="Create Deal"
            className="font-medium text-sm"
            onClick={() => {
              dispatch(setNewDealProperties(selectedProperties));
              dispatch(setAddressSearchModalIsOpen(false));
              return navigate('/deal_sourcing/create_deal');
            }}
            disabled={!selectedProperties.length}
          />
        </div>
      </div>
    </Modal>
  );
}
