import FloatingTooltip from 'components/shared/FloatingTooltip';

export default function RemarksCell({ getValue }) {
  const cellValue = getValue();

  return (
    <FloatingTooltip contents={cellValue} tooltipClassName="w-48">
      <p className="max-w-60 truncate text-ellipsis line-clamp-1">
        {cellValue}
      </p>
    </FloatingTooltip>
  );
}
