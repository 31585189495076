import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { isMlsSource } from 'components/utils';

const renderTabs = (listing, homeModel, parcel) => {
  const tabs = [
    ['Overview', null],
    ['Neighborhood', 'neighborhood'],
  ];

  if (parcel?.fipsApn) {
    tabs.push(['History', 'history']);
  }

  if (isMlsSource(listing?.source)) {
    tabs.push(['Listing Details', 'listing_details']);
  }

  if (!homeModel?.id) {
    tabs.push(['Assessor Details', 'assessor_details']);
  }

  if (homeModel?.id) {
    tabs.push(['Subdivision', 'subdivision']);
  }

  return tabs;
};

const linkClassName = (active) => classNames(
  'hover:text-gray-700',
  'flex h-14 items-center',
  'tracking-wider text-xs font-semibold',
  'cursor-pointer',
  {
    'text-gray-700 pl-8 border-l-8 border-primary-500': active,
    'text-gray-400 pl-10': !active,
  },
);

function ParameterTab({ label, path }) {
  return (
    <NavLink end to={path}>
      {({ isActive }) => (
        <div className={linkClassName(isActive)}>{label}</div>
      )}
    </NavLink>
  );
}

const tabPath = (base, part, propertyId) => {
  if (propertyId) {
    return `${base}/properties/${propertyId}${part ? `/${part}` : ''}`;
  } else {
    return `${base}${part ? `/${part}` : ''}`;
  }
};

export default function PropertySidebar({ context }) {
  const { id, path, data, propertyId } = context;
  const { homeModel, listing, parcel } = data;

  return (
    <div className="h-full min-w-max pr-4 bg-white border-r z-10">
      {
        renderTabs(listing, homeModel, parcel).map((tab) => (
          <ParameterTab
            key={tab[0]}
            label={tab[0]}
            path={tabPath(`/${path}/${id}`, tab[1], propertyId)}
          />
        ))
      }
    </div>
  );
}
