import cx from 'classnames';
import { StrictMode } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useFetchSelfQuery } from 'redux/apiSlice';
import HiveLogoPath from 'assets/hive_logo.png';

function NavTab({ label, path }) {
  return (
    <NavLink
      to={path}
      className={cx(
        'text-label-md font-semibold text-neutral-dark',
        'content-center h-14 px-6 rounded-full',
        // :hover twice to increase CSS specificity
        'hover:hover:text-gray-700',
        'aria-[current="page"]:bg-primary-dark/8 aria-[current="page"]:text-gray-800',
      )}
    >
      {label}
    </NavLink>
  );
}

export default function OffMarketMarketplaceBase() {
  const { data: currentUser } = useFetchSelfQuery();

  return (
    <StrictMode>
      <div className="flex h-container w-container overflow-clip bg-gray-100">
        <div className="flex flex-col gap-y-0.5 w-52 h-full py-8 px-4 flex-none">
          <div className="mx-auto mb-8">
            <img
              className="h-18"
              src={HiveLogoPath}
              alt="logo"
            />
          </div>
          <NavTab label="Dashboard" path="dashboard" />
          <NavTab label="Inventory" path="inventory" />
          <NavTab label="Offers" path="offers" />
          <NavTab label="Buy Box" path="buy_box" />
          {currentUser.organization.config.importOffMarketMarketplace && (
            <NavTab label="Import" path="import" />
          )}
        </div>
        <div className="flex-1 [container-type:size]">
          <Outlet />
        </div>
      </div>
    </StrictMode>
  );
}
