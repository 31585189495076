import { createColumnHelper } from '@tanstack/react-table';
import { groupBy, isArray, sortBy } from 'lodash';
import { useFetchSelfQuery } from 'redux/apiSlice';
import { useFetchOffMarketMarketplaceBuyBoxQuery } from 'redux/offMarketMarketplaceApiSlice';
import DataTable from 'components/shared/Table/DataTable';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';
import { formatInteger } from 'components/utils';

function BuyerBuyBox({ active, name, parameters }) {
  return (
    <div>
      <h2>{`Buy Box Name: ${name}`}</h2>
      <div>{`Active: ${active}`}</div>
      <div className="whitespace-pre">{JSON.stringify(parameters, null, 2)}</div>
    </div>
  );
}

function BrokerBuyBoxSummary({ buyBoxes }) {
  const buyBoxesByOrg = sortBy(Object.entries(groupBy(buyBoxes, 'organization.name')), pair => pair[0]);
  return (
    <div className="overflow-y-scroll">
      {buyBoxesByOrg.map(([orgName, orgBuyBoxes]) => (
        <div key={orgName}>
          <h2 className="text-lg">{`Organization: ${orgName}`}</h2>
          <div>
            {orgBuyBoxes.map(buyBox => <BuyerBuyBox key={buyBox.id} {...buyBox} />)}
          </div>
        </div>
      ))}
    </div>
  );
}

const columnHelper = createColumnHelper();

const SELLER_BUY_BOX_COLUMNS = [
  columnHelper.accessor('market', { header: 'Market' }),
  columnHelper.accessor('bedroomsMin', {
    header: 'Min Bed',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('bedroomsMax', {
    header: 'Max Bed',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('bathroomsMin', {
    header: 'Min Bath',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('buildingAreaMin', {
    header: 'Min Sq Ft',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('buildingAreaMax', {
    header: 'Max Sq Ft',
    cell: ({ getValue }) => formatInteger(getValue()),
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('yearBuiltMin', {
    header: 'Min Year Built',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('privatePool', {
    header: 'Private Pool',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('zipCodes', {
    header: 'Zip Codes',
    cell: ({ getValue }) => (isArray(getValue()) ? getValue().sort().join(', ') : 'Any'),
  }),
];

function SellerBuyBoxSummary({ buyBoxes }) {
  return (
    <DataTable
      columns={SELLER_BUY_BOX_COLUMNS}
      data={sortBy(buyBoxes, 'market')}
      tableContainerClassName="whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      enableMultiRowSelection={false}
    />
  );
}

export default function OffMarketBuyBox() {
  const { data: buyBoxData, isLoading: buyBoxIsLoading } = useFetchOffMarketMarketplaceBuyBoxQuery();
  const { data: currentUser, isLoading: isUserLoading } = useFetchSelfQuery();

  if (buyBoxIsLoading || isUserLoading) {
    return (
      <div className="w-full h-screen">
        <EmptyLoadingState />
      </div>
    );
  }

  return (
    <div className="py-12 px-8 flex flex-col h-container w-container">
      <h1 className="mb-12 text-3xl text-gray-700">Buy Box</h1>
      {currentUser.settings.offMarketBroker ? (
        <BrokerBuyBoxSummary buyBoxes={buyBoxData} />
      ) : (
        <SellerBuyBoxSummary buyBoxes={buyBoxData} />
      )}
    </div>
  );
}
