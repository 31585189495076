import {
  addMonths,
  differenceInDays,
  format as dateFnFormat,
  formatISO,
  isEqual as dateFnIsEqual,
  parseISO,
  startOfDay,
  startOfMonth,
  formatDistanceToNow,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  cloneDeep,
  cloneDeepWith,
  forEach,
  groupBy,
  isDate,
  isEmpty,
  isFunction,
  isNil,
  isNull,
  isNumber,
  isString,
  lowerCase,
  mergeWith,
  omitBy,
  property,
  startCase,
  orderBy,
  sum,
  sumBy,
  unzip,
} from 'lodash';
import { camelCase, isArray, isPlainObject, snakeCase } from 'lodash/fp';
import * as ReactDOMServer from 'react-dom/server';
import {
  ACTIVE_STATUS,
  CLOSED_STATUS,
  DEAL_STATE_CLOSED,
  DEAL_STATE_DEAD,
  DEAL_STATE_WITHDRAWN,
  LISTING_SOURCE_BUILDER,
  LISTING_SOURCE_OFF_MARKET_MARKETPLACE,
  PARCEL_LAND_USE_CODE_TO_PROPERTY_TYPE,
  RESIDENTIAL_PROPERTY_TYPE,
  TRANSACTION_TYPES,
} from 'components/constants';

const DATE_FORMAT = 'yyyy-MM-dd';
const DATE_TIME_FORMAT = 'MMM dd (h:mm a)';

// *** General Helpers *** //

export const deepMapKeys = (input, iteratee, excludeKeys = []) => {
  if (isArray(input)) {
    return input.map(v => deepMapKeys(v, iteratee, excludeKeys));
  }

  if (!isPlainObject(input)) return input;
  const result = {};

  Object.keys(input).forEach(key => {
    let value = input[key];
    const mappedKey = iteratee(key, value);

    if (!excludeKeys?.includes(mappedKey) && (isPlainObject(value) || isArray(value))) {
      value = deepMapKeys(value, iteratee, excludeKeys);
    }
    result[mappedKey] = value;
  });

  return result;
};

export const deepFlattenKeys = (obj) => {
  const result = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (isPlainObject(value)) {
      deepFlattenKeys(value).forEach(nestedKey => {
        result.push(`${key}.${nestedKey}`);
      });
    } else {
      result.push(key);
    }
  });

  return result;
};

export const camelCaseKeys = (input, excludeKeys = []) => deepMapKeys(input, key => camelCase(key), excludeKeys);
export const lowerCaseKeys = (input, excludeKeys = []) => deepMapKeys(input, key => key.toLowerCase(), excludeKeys);
export const snakeCaseKeys = (input, excludeKeys) => deepMapKeys(input, key => snakeCase(key), excludeKeys);
export const compactObj = obj => omitBy(obj, val => isNil(val));

export const groupById = (arr, idFieldOrFunc = 'id', valueFieldOrFunc = null) => arr.reduce((result, obj) => {
  let val;
  if (!valueFieldOrFunc) {
    val = obj;
  } else {
    val = isFunction(valueFieldOrFunc) ? valueFieldOrFunc(obj) : obj[valueFieldOrFunc];
  }
  const resultKey = isFunction(idFieldOrFunc) ? idFieldOrFunc(obj) : obj[idFieldOrFunc];
  // eslint-disable-next-line no-param-reassign
  result[resultKey] = val;
  return result;
}, {});

export const titleCase = string => startCase(lowerCase(string));

export const deepStringifyDates = input => cloneDeepWith(input, value => (isDate(value) ? formatISO(value) : undefined));

export const sumReducer = (total, lineItem) => total.map((val, index) => lineItem[index] + val);

export const arrayMax = (array, max) => array.map(elem => Math.max(elem, max));

export const sumArrays = (...arrays) => unzip(arrays).map(sum);

export const cumulativeSum = array => {
  let runningTotal = 0;
  return array.map(value => (runningTotal += value));
};

// Resizes the array to targetLength, filling in with the last element of the array
export const resizeArray = (array, targetLength) => {
  const arrayCurrLength = array.length;

  array.length = targetLength;
  if (targetLength > arrayCurrLength) {
    array.fill(array[arrayCurrLength - 1], arrayCurrLength);
  }

  return array;
};

export const naturalSortComparator = ({ locale = 'en', sensitivity = 'base' } = {}) => (
  (left, right) => left.localeCompare(right, locale, { sensitivity, numeric: true })
);

export const dotProductBy = (collection, iterateeA, iterateeB) => {
  if (!collection?.length) {
    return 0;
  }

  const accessorA = isFunction(iterateeA) ? iterateeA : property(iterateeA);
  const accessorB = isFunction(iterateeB) ? iterateeB : property(iterateeB);
  return sumBy(collection, (...args) => accessorA(...args) * accessorB(...args));
};

export const weightedMeanBy = (collection, iterateeValue, iterateeWeight, { defaultValue = Number.NaN } = {}) => {
  if (!collection?.length) {
    return defaultValue;
  }

  const totalWeight = sumBy(collection, iterateeWeight);
  return dotProductBy(collection, iterateeValue, iterateeWeight) / totalWeight;
};

// *** Event Helpers *** //

export const parseNumericInput = (value) => {
  if (value.endsWith('%')) {
    // little hack here e.g 3.7 / 100 results in repating decimal places
    return (parseFloat(value.replaceAll(',', '').replaceAll('%', '')) * 100) / 10000;
  }
  if (value.startsWith('$')) {
    return parseFloat(value.replaceAll(',', '').replaceAll('$', ''));
  }
  return parseFloat(value.replaceAll(',', ''));
};

export function parseEventValue(event) {
  let parsedValue;
  if (event.target.type === 'number' || event.target.type === 'range') {
    parsedValue = event.target.value ? parseFloat(event.target.value) : null;
  } else if (event.target.type === 'checkbox') {
    parsedValue = event.target.checked;
  } else if (event.target.type === 'date') {
    parsedValue = event.target.value
      ? dateFromString(event.target.value)
      : null;
  } else if (isString(event.target.value) && (event.target.value.startsWith('$') || event.target.value.endsWith('%'))) {
    parsedValue = parseNumericInput(event.target.value);
  } else {
    parsedValue = event.target.value;
  }
  return parsedValue;
}
export function arrayFieldOnChangeListener(onChange, array, arrayIndex) {
  return event => {
    const name = `${event.target.name}s`;
    const updatedParam = Array.from(array);
    updatedParam[arrayIndex] = parseEventValue(event);

    onChange({
      target: {
        name,
        value: updatedParam,
      },
    });
  };
}

export const stopEventPropagation = (evt) => evt.stopPropagation();

// *** Date Helpers *** //

// dateString is expected to be of format yyyy-MM-dd
export function dateFromString(dateString) {
  if (dateString.getDate) {
    // return self if already a date object
    return dateString;
  }
  return startOfDay(parseISO(dateString));
}

export function datesEqual(dateLeft, dateRight) {
  if (isNil(dateLeft) && isNil(dateRight)) {
    return true;
  }

  const dateLeftParsed = isDate(dateLeft) ? dateLeft : parseISO(dateLeft);
  const dateRightParsed = isDate(dateRight) ? dateRight : parseISO(dateRight);

  return dateFnIsEqual(dateLeftParsed, dateRightParsed);
}

// *** Formatting Helpers *** //

export function formatDate(date, format = DATE_FORMAT) {
  if (!date) {
    return null;
  }
  date = isString(date) ? parseISO(date) : date;
  return dateFnFormat(date, format);
}

export function formatTimeZone(date, format = DATE_TIME_FORMAT, { timeZone } = {}) {
  date = isString(date) ? parseISO(date) : date;
  return formatInTimeZone(
    date,
    timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    format,
  );
}

// ** Calculate Days on Market **//
export function calcDaysOnMarket(listedOnDateString) {
  return differenceInDays(new Date(), new Date(listedOnDateString));
}

const defaultCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const centsCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function formatCurrency(value, cents = false) {
  if (!Number.isFinite(parseFloat(value))) {
    return '-';
  }

  return (cents ? centsCurrencyFormatter : defaultCurrencyFormatter).format(value);
}

// abbreviated currency based on value with k, M, B
export const formatCurrencyAbbreviated = (num, precision = 1) => {
  if (!Number.isFinite(parseFloat(num))) {
    return '-';
  }
  if (num >= 1e9) {
    return `$${(num / 1e9).toFixed(precision).replace(/\.0$/, '')}B`;
  }
  if (num >= 1e6) {
    return `$${(num / 1e6).toFixed(precision).replace(/\.0$/, '')}M`;
  }
  if (num >= 1e3) {
    return `$${(num / 1e3).toFixed(precision).replace(/\.0$/, '')}k`;
  }
  return `$${num}`;
};

// adds commas as delimiter for large values
export function formatInteger(integerValue, { prefix = '', suffix = '' } = {}) {
  const parsedInt = parseInt(integerValue, 10);
  if (!Number.isFinite(parsedInt)) {
    return '-';
  }
  return `${prefix}${parsedInt.toLocaleString('en-US')}${suffix}`;
}

export function formatEquityMultiple(number) {
  if (!Number.isFinite(number)) {
    return '-';
  }
  return `${number.toFixed(2)}x`;
}

export function formatPercentage(floatValue, precision = 1) {
  if (!Number.isFinite(parseFloat(floatValue))) {
    return '-';
  }
  return `${(floatValue * 100).toFixed(precision)}%`;
}

export function formatPercentageGrowth(floatValue, precision = 1) {
  if (!Number.isFinite(parseFloat(floatValue))) {
    return '-';
  }
  return `${((floatValue * 100) / 100).toFixed(precision)}%`;
}

export function parseIRR(floatValue, precision = 1) {
  if (isNil(floatValue)) {
    return '-';
  }
  return parseFloat((floatValue * 100).toFixed(precision));
}

export function formatMultiplier(floatValue, precision = 2) {
  if (!Number.isFinite(floatValue)) {
    return '-';
  }
  return `${floatValue.toFixed(precision)}x`;
}

export function formatMinMaxLabel(value) {
  if (isNil(value[0]) && isNil(value[1])) {
    return '-';
  }
  if (value[1] !== 'undefined' && isNil(value[1])) {
    return `> ${value[0]}`;
  }
  if (isNull(value[0]) && value[1] !== 'undefined') {
    return `< ${value[1]}`;
  }
  return `${value[0]} - ${value[1]}`;
}

export function formatRangeInput(value) {
  return isEmpty(value) ? null : value;
}

export function formatAddress({ address, city, state, zipCode } = {}) {
  return `${titleCase(address ?? '')}, ${titleCase(city ?? '')}, ${state?.toUpperCase() ?? ''} ${zipCode ?? ''}`;
}

// *** File Helpers *** //

export const fileIcon = filename => {
  const extension = filename.split('.').pop();
  if (['jpeg', 'jpg', 'png', 'svg', 'bmp', 'gif'].includes(extension)) {
    return 'image_icon';
  }
  switch (extension) {
    case 'xlsx':
      return 'excel_icon';
    case 'docx':
      return 'docx_icon';
    case 'pdf':
      return 'pdf_icon';
    case 'pptx':
      return 'ppt_icon';
    case 'xml':
      return 'xml_icon';
    default:
      return 'unknown_icon';
  }
};

export const fileSize = targetFileSize => {
  if (targetFileSize > 1024) {
    if (targetFileSize > 1048576) {
      return `${Math.round(targetFileSize / 1048576)}MB`;
    }
    return `${Math.round(targetFileSize / 1024)}KB`;
  }
  return `${targetFileSize}B`;
};

export const downloadUrl = (
  url,
  document,
  filename,
  revokeObjectUrl = true,
) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  // necessary to release URL
  const clickHandler = () => {
    setTimeout(() => {
      if (revokeObjectUrl) {
        URL.revokeObjectURL(url);
      }
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
};

// identifier should refer to the unique object being downloaded
//    e.g. the deal name or scenario name
// downloadType refers to the type of asset that is being downloaded
//    e.g. 'Purchase Agreement', 'Model'
export const downloadableFileName = (identifier, downloadType, extension) => {
  const parts = [
    identifier,
    downloadType,
    `${new Date().toISOString().split('T')[0]}.${extension}`,
  ];
  return parts.join('_').replace(/ /g, '_');
};

export const downloadBlob = (blob, document, filename) => {
  const url = URL.createObjectURL(blob);
  downloadUrl(url, document, filename, true);
};

// eslint-disable-next-line no-useless-escape
const sanitizeCsvVal = val => (val ? `"${val.toString().replace(/"/g, '\"')}"` : val);

// csvData is an array of arrays
export const downloadCsv = (csvData, document, filename) => {
  // eslint-disable-next-line quotes
  const csvString = csvData.map(row => row.map(val => sanitizeCsvVal(val)).join(',')).join("\n");
  const blob = new Blob([csvString], { type: 'text/csv' });
  downloadBlob(blob, document, filename);
};

export const svgDataUrl = element => {
  const elementStr = ReactDOMServer.renderToStaticMarkup(element);
  return `data:image/svg+xml;utf8,${elementStr.replaceAll('#', '%23')}`;
};

// eslint-disable-next-line no-param-reassign, no-return-assign
export const hideImgOnError = (event) => event.target.style.display = 'none';

// *** Domain Helpers *** //

// latLngPairs are arrays of [latitude, longitude]
export const distance = (latLngPair1, latLngPair2) => {
  const lat1 = (latLngPair1[0] * Math.PI) / 180;
  const lat2 = (latLngPair2[0] * Math.PI) / 180;
  const dLat = ((latLngPair2[0] - latLngPair1[0]) * Math.PI) / 180;
  const dLong = ((latLngPair2[1] - latLngPair1[1]) * Math.PI) / 180;

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.sin(dLong / 2) ** 2 * Math.cos(lat2);
  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 3958.8;
};

export const cashFlowStartDate = dcfParams => startOfMonth(addMonths(dateFromString(dcfParams.closingDate), 1));

export const capExStabilizedMonth = (closingDateString, capExItem) => {
  if (capExItem.turnBudget === 0 || (capExItem.accountingMethod !== 'follow_on')) { return 0; }
  const closingDate = dateFromString(closingDateString);
  const capExStartDate = dateFromString(capExItem.startDate);
  const startMonth = (capExStartDate.getMonth() - closingDate.getMonth()) + (12 * (capExStartDate.getYear() - closingDate.getYear()));
  return startMonth + capExItem.duration;
};

export const unitStabilizedMonth = (unit) => {
  const unitTurned = unit.turnBudget > 0;
  // stabilizationAdjustment is required to account for scenario where the unit is being turned, but the downtime is 0
  //  because the unit will lease that month, but we don't want to consider it stabilized until the following month
  //  to avoid having the unit turn cap-ex affect the stabilized yield
  const stabilizationAdjustment = (unitTurned && unit.turnDowntime === 0) ? 1 : 0;
  return stabilizationAdjustment + ((unit.vacant || unitTurned) ? unit.rollToMarket + (unitTurned ? unit.turnDowntime : 0) : 0);
};

// returns a simplified deal status only including Active, Closed or Dead
export const simpleDealStatus = (item, statusAccessor = 'stage') => {
  if (item[statusAccessor] === DEAL_STATE_CLOSED || item[statusAccessor] === DEAL_STATE_DEAD) {
    return item[statusAccessor];
  } else {
    return ACTIVE_STATUS;
  }
};

// eslint-disable-next-line arrow-body-style
export const determinePipelineStatus = (pipelineStatus, transactionType) => {
  // For disposition transactions, if the pipelineStatus is DEAD, rename it to WITHDRAWN; otherwise, return the original pipelineStatus.
  // For other transaction types, just return the original pipelineStatus.
  return transactionType === TRANSACTION_TYPES.disposition && pipelineStatus === DEAL_STATE_DEAD
    ? DEAL_STATE_WITHDRAWN
    : pipelineStatus;
};

// formatting abbreviated source names
// - will be updated as more abbreviated sources are introduced
const LISTING_SOURCE_MAPPINGS = {
  'carolina': 'CanopyMLS',
  'ccorm': 'CCOR',
  'cincymls': 'CincyMLS',
  'claw': 'CLAW',
  'Columbus and Central Ohio Regional MLS': 'CCOR',
  'crmls': 'CRMLS',
  'fmls': 'FMLS',
  'galmls': 'GALMLS',
  'gamls': 'GAMLS',
  'hmls': 'Heartland MLS',
  'maar': 'MAAR',
  'mfrmls': 'Stellar MLS',
  'mibor': 'MIBOR',
  'ntreis': 'NTREIS',
  'realmls': 'realMLS',
  'realtrac': 'RealTracs',
  [LISTING_SOURCE_BUILDER]: '',
  [LISTING_SOURCE_OFF_MARKET_MARKETPLACE]: 'Honeycomb Marketplace',
};

const MLS_SOURCES = [
  'carolina',
  'ccorm',
  'cincymls',
  'claw',
  'Columbus and Central Ohio Regional MLS',
  'crmls',
  'fmls',
  'galmls',
  'gamls',
  'hmls',
  'maar',
  'mfrmls',
  'mibor',
  'ntreis',
  'realmls',
  'realtrac',
];
export const isMlsSource = source => MLS_SOURCES.includes(source);

export const formatListingSource = sourceName => LISTING_SOURCE_MAPPINGS[sourceName] ?? sourceName;
export const isListingClosed = listing => listing.standardStatus === CLOSED_STATUS;

export function calcDaysOnMarketFromListing(listing) {
  const {
    closeDate,
    listingContractDate,
    offMarketDate,
    onMarketDate,
  } = listing;

  if (!onMarketDate && !listingContractDate) {
    return null;
  }
  const effectiveOnMarketDate = onMarketDate || listingContractDate;

  if (isListingClosed(listing)) {
    return differenceInDays(new Date(offMarketDate || closeDate), new Date(effectiveOnMarketDate));
  } else if (offMarketDate) {
    return differenceInDays(new Date(offMarketDate), new Date(effectiveOnMarketDate));
  } else {
    return differenceInDays(new Date(), new Date(effectiveOnMarketDate));
  }
}

export const parseUnitMixes = (units) => units.map(unit => [unit.bedrooms, unit.bathrooms, unit.rsf]);
export const unitMixKey = unitMix => `${unitMix[0]}x${unitMix[1]}x${unitMix[2]}`;

// eslint-disable-next-line consistent-return
function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.map((num, idx) => {
      if (isNumber(num)) return num + srcValue[idx];
      return num;
    });
  }
  if (isNumber(objValue)) return objValue + srcValue;
}

export const median = (numbers) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

export const metersSquaredToMilesSquared = meters => meters * 3.86102e-7;

export const combineObjects = objects => mergeWith(...objects, customizer);

export const splitCamelCase = word => word.replace(/([a-z])([A-Z])/g, '$1 $2');

export const isConsecutive = array => array.sort().slice(1).map((n, i) => n - array[i]).every(value => value === 1);

export const formattedLabel = (labelSuffix, useExact, value) => {
  let label;
  if (isEmpty(value)) label = labelSuffix;
  else if (useExact) label = `${value.sort().join(', ')} ${labelSuffix}`;
  else if (isConsecutive(value)) label = `${value[0]}+ ${labelSuffix}`;
  else label = `${value.sort().join(',')} ${labelSuffix}`;
  return label;
};

export const isSingleFamily = (parcel) => (parcel.trulivParcelCode === 'SF') || (parcel.trulivLandUseCode === 'SF');

export const formatNone = (value) => (isNil(value) ? '-' : value);

export const linkify = (text) => {
  if (isNil(text)) return null;

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a class="text-primary hover:text-primary-500 active:text-primary-600" target="_blank" rel="noreferrer" href="${url}">${url}</a>`);
};

// fills in Listing fields if listing is from MarketDataListing
export const standardizeListing = (listing) => {
  if (!listing) return;
  const standardizedListing = { ...listing.details, ...listing };

  standardizedListing.price ??= standardizedListing.listPrice;
  standardizedListing.remarks ??= standardizedListing.publicRemarks;
  standardizedListing.listedOn ??= standardizedListing.listingContractDate;
  standardizedListing.listAgent ??= {
    fullName: standardizedListing.listAgentFullName,
    brokerageName: standardizedListing.listBrokerageName || standardizedListing.listOfficeName,
    directPhone: standardizedListing.listAgentDirectPhone,
    email: standardizedListing.listAgentEmail,
  };
  standardizedListing.status ??= standardizedListing.standardStatus;
  standardizedListing.sourceId ??= standardizedListing.listingId;

  try {
    if (standardizedListing.media && (!standardizeListing.pictures || isEmpty(standardizeListing.pictures))) {
      standardizedListing.pictures = JSON.parse(standardizedListing.media).map(photo => lowerCaseKeys(photo)).map(photo => ({ ...photo, url: photo.mediaurl }));
    }
  } catch {
    // do nothing if JSON parse fails
  }

  return standardizedListing;
};

const PROPERTY_TYPE_UNKNOWN = 'Unknown';

export const coalesceProperty = (property, parcel, listing, units) => {
  const coalescedProperty = cloneDeep(property);
  coalescedProperty.bedrooms = property.propertyBedroomsTotal;
  coalescedProperty.bathrooms = property.propertyBathroomsTotal;
  coalescedProperty.livingArea = property.rentableBuildingArea;
  coalescedProperty.lotSize = property.landAreaSf;
  coalescedProperty.trulivParcelCode = parcel?.trulivLandUseCode || parcel?.trulivParcelCode;
  // TODO: include listing photos
  coalescedProperty.photos = property.pictures;

  if (parcel) {
    // fill in any missing fields using parcel data
    coalescedProperty.bedrooms ??= parseInt(parcel.bedCount, 10);
    coalescedProperty.bathrooms ??= parseInt(parcel.bathCount, 10);
    coalescedProperty.livingArea ??= parseInt(parcel.buildingSqFt, 10);
    coalescedProperty.fipsApn ??= parcel.fipsApn;
    coalescedProperty.numberOfFloors ??= parcel.storiesCount;
    coalescedProperty.yearBuilt ??= parcel.yearBuilt;
    coalescedProperty.constructionMaterial ??= parcel.constructionCode;
    coalescedProperty.sewer ??= parcel.sewerUsageCode;
    coalescedProperty.waterSource ??= parcel.waterSourceCode;
    // prefer parcel county to listing county
    if (parcel.situsCounty) {
      coalescedProperty.county = parcel.situsCounty;
    }
  }

  if (listing) {
    // prioritize listing data for these fields
    coalescedProperty.bedrooms = listing.bedroomsTotal;
    coalescedProperty.bathrooms = listing.bathroomsTotalInteger;
    coalescedProperty.livingArea = listing.livingArea || listing.buildingAreaTotal;
    if (isNil(coalescedProperty.pictures) || isEmpty(coalescedProperty.pictures)) {
      coalescedProperty.pictures = listing.pictures;
    }

    // only fill in if missing (i.e. prioritize parcel data)
    coalescedProperty.latitude ??= listing.latitude;
    coalescedProperty.longitude ??= listing.longitude;
    coalescedProperty.fipsApn ??= listing.fipsApn;
    coalescedProperty.lotSize ??= listing.lotSize;
    coalescedProperty.garageSpaces ??= formatInteger(listing.garageSpaces);
  }

  coalescedProperty.isSingleFamily = (parcel && isSingleFamily(parcel)) || (property.numberOfUnits === 1) || (listing?.propertyType === RESIDENTIAL_PROPERTY_TYPE);

  // prioritize info from model parameters' units info
  if (units && units[0]) {
    // TODO: should we override isSingleFamily based on the model units info?
    if (coalescedProperty.isSingleFamily) {
      coalescedProperty.bedrooms = units[0].bedrooms;
      coalescedProperty.bathrooms = sum(units.map(u => u.bathrooms || 0));
      coalescedProperty.livingArea = sum(units.map(u => u.rsf || 0));
    }

    forEach(groupBy(units, 'bedrooms'), (unitsOfBedroom, numOfBedrooms) => {
      coalescedProperty[parseInt(numOfBedrooms, 10) === 0 ? 'numberOfStudio' : `numberOf${numOfBedrooms}Br`] = unitsOfBedroom.length;
    });
    coalescedProperty.numberOfUnits = units.length;
  }

  coalescedProperty.latitude = coalescedProperty.latitude && Number(coalescedProperty.latitude);
  coalescedProperty.longitude = coalescedProperty.longitude && Number(coalescedProperty.longitude);
  coalescedProperty.subdivision ??= parcel?.subdivision || listing?.subdivisionName;
  coalescedProperty.propertySubType ??= parcel?.propertyUseCodeMapped || listing?.propertySubType;
  coalescedProperty.propertyType ??= parcel?.propertyUseCodeMapped || PROPERTY_TYPE_UNKNOWN;
  coalescedProperty.stateOrProvince ??= property?.state || parcel?.state || listing?.stateOrProvince;
  coalescedProperty.postalCode ??= property?.zipCode || parcel?.zip || listing?.postalCode;

  return coalescedProperty;
};

// fill in missing reso listing fields from parcel
export const coalesceListing = ({ listing, parcel }) => {
  const coalescedListing = cloneDeep(listing);

  coalescedListing.bedroomsTotal ??= parcel.bedCount;
  coalescedListing.bathroomsHalf ??= parcel.partialBathCount;
  coalescedListing.bathroomsFull ??= Math.floor(parcel.bathCount);
  coalescedListing.livingArea ??= parcel.buildingSqFt;
  coalescedListing.lotSizeSquareFeet ??= parcel.lotSizeSqFt;
  coalescedListing.garageSpaces ??= parcel.parkingSpaceCount;
  coalescedListing.sewer ??= parcel.sewerUsageCode;
  coalescedListing.yearBuilt ??= parcel.yearBuilt;
  coalescedListing.stories ??= parcel.storiesCount;
  coalescedListing.propertyType ??= PARCEL_LAND_USE_CODE_TO_PROPERTY_TYPE[parcel.trulivParcelCode];
  coalescedListing.propertySubType ??= parcel.propertyUseCodeMapped;
  coalescedListing.latitude ??= parcel.latitude;
  coalescedListing.longitude ??= parcel.longitude;
  coalescedListing.standardStatus ??= coalescedListing.status;
  coalescedListing.photoUrl ??= coalescedListing.pictureUrl;

  // TODO: update after off-market listing publish/unpublish is implemented
  coalescedListing.listingContractDate ??= coalescedListing.createdAt;

  // prefer parcel address
  coalescedListing.unparsedAddress = parcel.address;
  coalescedListing.city = parcel.city;
  coalescedListing.stateOrProvince = parcel.state;
  coalescedListing.postalCode = parcel.zip;
  coalescedListing.offMarketMarketplace = (coalescedListing.source === 'off-market marketplace');

  return coalescedListing;
};

export const parsePropertyIdFromComp = (comp) => comp.comp.data.attributes.id;

export const isRentCompPath = (path) => path.split('/')[3] === 'rent_comps';

// helper for determining how to display the "name" of a user
export const userDisplay = (user, placeholder = null) => (user ? (user.fullName || user.email) : placeholder);

// Split camelCase and capitalize
export const splitAndCapitalize = (str) => str
  .replace(/([a-z])([A-Z])/g, '$1 $2')
  .replace(/\b\w/g, char => char.toUpperCase());

export const convertToCSV = (objArray) => {
  if (!Array.isArray(objArray) || objArray.length === 0) {
    return '';
  }

  // Extract and format headers
  const headers = Object.keys(objArray[0]).map(splitAndCapitalize);
  let csvString = `${headers.join(',')}\r\n`;

  // Extract data rows
  objArray.forEach(obj => {
    const row = Object.keys(objArray[0]).map(header => {
      if (obj.hasOwnProperty(header)) {
        const value = obj[header] === null || obj[header] === undefined ? '' : obj[header];
        // Handle values containing commas or quotes
        return `"${value.toString().replace(/"/g, '""')}"`;
      }
      return '';
    }).join(',');

    csvString += `${row}\r\n`;
  });

  return csvString;
};

export const determineTextColor = (backgroundColorName) => {
  const lightColors = ['white', 'yellow', 'lightgray', 'lightyellow', 'lightblue', 'lightgreen', 'lightcyan', 'beige', 'blue', 'red', 'gray', 'indigo'];
  return lightColors.includes(backgroundColorName) ? 'black' : 'white';
};

// Some values in listing data are in array form, but are stringified during our ETL processes.
// This checks for those and parses the array to improve the display
export const parseArrayString = (val) => {
  if (!val) return val;
  let displayString = val;
  // check for bracket char to check whether it is an array field
  if (displayString.includes('[')) {
    try {
      displayString = JSON.parse(displayString).join(', ');
    } catch (_) {
      // do nothing if JSON parsing failed
    }
  }
  return displayString;
};

export const formatHourAgo = (date, addSuffix = true) => {
  const formattedDate = formatDistanceToNow(new Date(date), { addSuffix });
  return formattedDate === 'about 24 hours ago' ? '1 day ago' : formattedDate;
}

// maps the source key (defined by the MLS data provider)
// to a more human friendly term
// NOTE: mapped values are used in URLs so do not include special characters (e.g. spaces)
const MLS_SOURCE_MAP = {
  carolina: 'CanopyMLS',
  fmls: 'FMLS',
  realmls: 'realMLS',
};

const formatListingId = (mlsSourceKey, mlsListingId) => {
  if (mlsSourceKey === 'carolina') {
    return mlsListingId.slice(3);
  }
  return mlsListingId;
}

export const formatCounty = (countyString) => countyString?.replace('county', '')?.replace('County', '')?.trim();

// This is needed because our data lake stores IDs that include
// the provider (e.g. MLS Grid, Bridge, etc.), but we do not
// want that to be user-facing, so we clean up the ID to make it
// more user-friendly when showing it in the UI
// TODO: this is not used yet for simplicity of migrating to new
// listing architecture, but eventually want to use it throughout UI
export const formatListingKey = (id) => {
  const idParts = id.split('-');
  const mlsSourceKey = idParts[1];
  const mlsListingId = idParts[2];
  return `${MLS_SOURCE_MAP[mlsSourceKey]}-${formatListingId(mlsSourceKey, mlsListingId)}`;
};

// can be pass as on onClick handler to more accurate
// replicate the behavior of clicking an anchor link by
// allowing the user to open in a new tab using ctrl/meta click
export const mimicAnchorClick = (event, to, navigate) => {
  if (event.metaKey || event.ctrlKey) {
    const win = window.open(to, '_blank', 'noopener');
    win?.focus();
  } else {
    navigate(to);
  }
};

// really simple email validation that checks for @ and domain
export const validateEmail = (email) => {
  if (!email) return false;
  if (email.indexOf('@') === -1) return false;
  if (email.split('@')[1].indexOf('.') === -1) return false;
  return true;
}

const MIN_TARGET_SELECTED_COMPS = 3;
const MAX_TARGET_SELECTED_COMPS = 5;

// Tier the comps by great, good, acceptable and bad
export const calcCompScoreTier = score => {
  if (score >= 2.5) {
    return 3;
  } else if (score >= 2) {
    return 2;
  } else if (score >= 1) {
    return 1;
  }
  return 0;
}

export const getDefaultSelectedComps = comps => {
  const compsByTier = groupBy(comps, comp => calcCompScoreTier(comp.compScores.overall));
  const sortedTiers = Object.keys(compsByTier).sort().reverse();

  const selectedComps = [];
  for (const tier of sortedTiers) {
    const compsInTier = orderBy(compsByTier[tier], [
      comp => comp.compScores.overall,
      comp => comp.compScores.property[0],
      comp => comp.compScores.location[0],
      comp => comp.compScores.recency[0],
      comp => comp.distance,
    ], ['desc', 'desc', 'desc', 'desc', 'asc']);

    for (const comp of compsInTier) {
      selectedComps.push(comp);
      if (selectedComps.length === MAX_TARGET_SELECTED_COMPS) {
        break;
      }
    }
    if (selectedComps.length >= MIN_TARGET_SELECTED_COMPS) {
      break;
    }
  };
  return selectedComps;
};

export const scoreWeightedPrice = (comps) => {
  const weights = comps.map(comp => comp.compScores.overall ** 2);
  const weightedPrices = comps.map(comp => (comp.compScores.overall ** 2) * (comp.closePrice || comp.listPrice));
  return sum(weightedPrices) / sum(weights);
};
