import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isDate, isNil, isString, partial, round } from 'lodash';
import { NumericFormat } from 'react-number-format';
import { dateFromString, formatDate, parseEventValue } from './utils';
import Toggle, { ToggleWithLabels } from './Toggle';
import { VisibilityIcon, VisibilityOffIcon } from './icons';

export const PERCENT_TYPE = 'percent';
export const CURRENCY_TYPE = 'currency';

function InputDecoration({ text, direction }) {
  return (
    <span className={`text-gray-400 ${direction === 'left' ? 'w-0 relative left-2' : 'inline-flex items-center p-2 rounded-r'}`}>
      {text}
    </span>
  );
}

export const formatPercentageInput = (floatValue, precision = 1) => {
  if (isNil(floatValue)) {
    return floatValue;
  }
  return round(floatValue * 100, precision);
};

// auto-highlight input value when clicking into input
const handleMouseDown = (event) => {
  if (event.target !== document.activeElement) {
    event.stopPropagation();
    event.preventDefault();
    event.target.select();
  }
};

const percentOnChange = (onChange, setDisplayValue, event) => {
  const rawValue = event.target.value;
  if (rawValue === '') {
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  } else {
    // eslint-disable-next-line no-param-reassign
    event.target.value = parseEventValue(event) / 100;
  }

  setDisplayValue(rawValue);
  onChange(event);
};

// for use as customInput for react-number-format
function WrappableInput({ className, max, min, name, onChange, onBlur, value, disabled }) {
  return (
    <input
      className={className}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    />
  );
}

export default function Input({ addOnClassName, checked, className, errorMessage, helperText, label, name, value = '', id, min, max, step, rows, cols, type, onChange, onBlur, onKeyPress, precision, required, padding = 'py-1 px-2', width = 'w-auto', disabled, placeholder, textAlign, toggleClassName, toggleLabels }) {
  let addonLeft = null;
  let addonRight = null;

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleIcon = showPassword ? <VisibilityIcon className="w-5 h-5" /> : <VisibilityOffIcon className="w-5 h-5" />;

  if (type === PERCENT_TYPE) {
    addonRight = <InputDecoration text="%" direction="right" />;
  } else if (type === CURRENCY_TYPE) {
    addonLeft = <InputDecoration text="$" direction="left" />;
  }
  const hasAddon = addonLeft || addonRight;
  let classWidth = width;
  if (type === 'checkbox') {
    classWidth = 'w-auto';
  } else if (hasAddon) {
    classWidth = 'w-full';
  }
  const clazz = classNames(
    padding,
    classWidth,
    className,
    {
      'text-right': type !== 'text' && type !== 'text-area' && type !== 'password' && !textAlign,
      'border border-gray-300 rounded': !hasAddon,
      'flex-grow': hasAddon,
    },
    textAlign,
  );

  let inputElem;

  const percentagePrecision = parseInt(precision, 10) || 6;
  const formatValue = () => (value === '' ? '' : formatPercentageInput(value, percentagePrecision));
  const [displayValue, setDisplayValue] = useState(formatValue());

  // Handle value changes from other components, e.g. switching between annual rates/same rate every year.
  useEffect(() => {
    setDisplayValue(formatValue());
  }, [value]);

  if (type === 'password') {
    inputElem = (
      <div className="relative">
        <input
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          className={clazz}
          onChange={onChange}
          onBlur={onBlur}
          onMouseDown={handleMouseDown}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <button
            type="button"
            onClick={toggleShowPassword}
            className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            {toggleIcon}
          </button>
        </div>
      </div>
    );
  } else if (type === 'text') {
    inputElem = (
      <input
        name={name}
        type="text"
        value={value || ''}
        placeholder={placeholder}
        disabled={!!disabled}
        className={clazz}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onMouseDown={handleMouseDown}
      />
    );
  } else if (type === 'radio') {
    return (
      <div className="flex items-center cursor-pointer">
        <input
          checked={checked}
          className={classNames(className, 'cursor-pointer')}
          id={id}
          name={name}
          type="radio"
          value={value}
          disabled={!!disabled}
          onChange={onChange}
        />
        {label && <label onClick={() => onChange({ target: { name, value } })} className="ml-3 cursor-pointer" htmlFor={id}>{label}</label>}
      </div>
    );
  } else if (type === 'text-area') {
    inputElem = (
      <textarea
        rows={rows}
        cols={cols}
        name={name}
        type="text"
        value={value || ''}
        placeholder={placeholder}
        disabled={!!disabled}
        className={clazz}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onMouseDown={handleMouseDown}
      />
    );
  } else if (type === 'checkbox') {
    const onClick = () => {
      onChange({
        target: {
          name,
          value: !value,
        },
      });
    };
    inputElem = toggleLabels ? (
      <ToggleWithLabels
        name={name}
        label1={toggleLabels[0]}
        label2={toggleLabels[1]}
        checked={!!value}
        disabled={!!disabled}
        className={toggleClassName}
        onClick={onClick}
      />
    ) : (
      <Toggle
        name={name}
        checked={!!value}
        disabled={!!disabled}
        className={toggleClassName}
        onClick={onClick}
      />
    );
  } else if (type === 'date') {
    let formattedValue = value;
    if (isString(value) && (value.indexOf('T') > -1)) {
      // date inputs only accept yyyy-MM-dd, so if a date-time is passed, truncate to date
      formattedValue = formatDate(dateFromString(value));
    } else if (isDate(value)) {
      formattedValue = formatDate(value);
    }
    inputElem = (
      <input
        name={name}
        type="date"
        value={formattedValue || ''}
        checked={value}
        disabled={!!disabled}
        className={clazz}
        min={min}
        max={max}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
    );
  } else if (type === 'range') {
    inputElem = (
      <input
        name={name}
        type="range"
        value={value}
        placeholder={placeholder}
        disabled={!!disabled}
        className={classNames(width, className)}
        required={required}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    );
  } else if (type === CURRENCY_TYPE) {
    const onInnerChange = (e) => {
      const targetValue = e.target.value?.replaceAll(',', '');

      onChange({
        target: {
          name: e.target.name,
          value: targetValue ? parseFloat(targetValue) : null,
        },
      });
    };
    inputElem = (
      <NumericFormat
        customInput={WrappableInput}
        className={classNames(clazz, 'pl-6')}
        max={max}
        min={min}
        name={name}
        onChange={onInnerChange}
        onBlur={onBlur}
        thousandSeparator=","
        value={value}
        disabled={disabled}
      />
    );
  } else {
    let formattedValue = value;
    if (type === PERCENT_TYPE) {
      // If this is a percentage input, we need to use a different variable for display
      // to handle x.0x input, otherwise x.0 gets formatted to x before the next number can be entered

      formattedValue = displayValue;
      // eslint-disable-next-line no-param-reassign
      onChange = partial(percentOnChange, onChange, setDisplayValue);
    }
    inputElem = (
      <input
        name={name}
        type="number"
        value={(formattedValue || formattedValue === 0) ? formattedValue : ''}
        placeholder={placeholder}
        step={step}
        disabled={!!disabled}
        className={clazz}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        min={min}
        max={max}
        onMouseDown={handleMouseDown}
      />
    );
  }

  if (hasAddon) {
    const addOnClazz = classNames(
      'flex items-center rounded',
      addOnClassName,
      width,
      {
        'flex-grow': width === 'w-auto',
        'border border-gray-300': !errorMessage,
        'border-2 border-rose-600': errorMessage,
      },
    );
    inputElem = (
      <div className={addOnClazz}>
        {addonLeft}
        {inputElem}
        {addonRight}
      </div>
    );
  }
  return (
    <>
      {inputElem}
      {errorMessage && <div className="mt-1 text-red-500 text-xs font-normal">{errorMessage}</div>}
      {helperText && !errorMessage && <div className="mt-1 text-neutral-light text-xs font-normal">{helperText}</div>}
    </>
  );
}
