import { copyWithStructuralSharing } from '@reduxjs/toolkit/query';
import { userPreferencesApiRoute } from 'components/routes';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { snakeCase } from 'lodash';
import pLimit from 'p-limit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, generatePath } from 'react-router-dom';
import { apiSlice } from './apiSlice';
import { USER_PREFERENCES_TAG } from './utils';

const requestQueues = new Map();
const localStorageCache = new Map();
const unresolvedRequestsInQueueSymbol = Symbol('');

export const getCachedUserPreferenceValue = ({ preferenceKey, preferenceVersion }, { removalSignal } = {}) => {
  const cacheKey = `${preferenceKey}/${preferenceVersion}`;
  removalSignal?.finally(() => { localStorageCache.delete(cacheKey); });

  const cachedValue = localStorageCache.get(cacheKey);
  if (cachedValue !== undefined) {
    return cachedValue;
  }

  const localStorageValue = JSON.parse(localStorage.getItem(preferenceKey));
  const versionedValue = localStorageValue?.version === preferenceVersion ? localStorageValue.value : null;
  localStorageCache.set(cacheKey, versionedValue);
  return versionedValue;
};

const generateUserPrefApiRoute = ({ preferenceKey }) => (
  generatePath(userPreferencesApiRoute, { preferenceKey: encodeURIComponent(preferenceKey) })
);

// wrap baseQuery with a queue to limit concurrency
const queuedQuery = (arg, api, extraOptions, baseQuery, queueKey) => {
  let queue = requestQueues.get(queueKey);
  if (queue === undefined) {
    queue = pLimit(1);
    requestQueues.set(queueKey, queue);
  }

  const query = queue(baseQuery, arg, api, extraOptions);
  query.finally(() => {
    if (queue.pendingCount === 0 && queue.activeCount === 0) {
      requestQueues.delete(queueKey);
    }
  });

  return query.then(({ meta, ...rest }) => ({
    ...rest,
    meta: { ...meta, [unresolvedRequestsInQueueSymbol]: queue.pendingCount + queue.activeCount },
  }));
};

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchUserPreference: builder.query({
        query: ({ preferenceKey, preferenceVersion }) => ({
          url: generateUserPrefApiRoute({ preferenceKey }),
          params: createSearchParams(snakeCaseKeys({ preferenceVersion })),
        }),
        transformResponse: (response) => camelCaseKeys(response, ['preferenceValue']),
        providesTags: (result, error, { preferenceKey }) => [{ type: USER_PREFERENCES_TAG, id: preferenceKey }],
        onCacheEntryAdded: (arg, { updateCachedData, cacheDataLoaded }) => {
          const { preferenceKey, preferenceVersion } = arg;
          // empty update to initialize the entry
          updateCachedData(() => {});
          // populate entry with cached value from localStorage
          updateCachedData(() => ({
            preferenceKey,
            preferenceVersion,
            preferenceValue: getCachedUserPreferenceValue(arg, { removalSignal: cacheDataLoaded }),
          }));
        },
        onQueryStarted: ({ preferenceKey, preferenceVersion }, { queryFulfilled }) => {
          queryFulfilled.then(({ error, data: { preferenceValue } = {} }) => {
            if (error !== undefined) {
              return;
            }

            if (preferenceValue === null) {
              localStorage.removeItem(preferenceKey);
            } else {
              localStorage.setItem(preferenceKey, JSON.stringify({ version: preferenceVersion, value: preferenceValue }));
            }
          });
        },
      }),
      updateUserPreference: builder.mutation({
        queryFn: ({ preferenceKey, preferenceValue, preferenceVersion }, api, extraOptions, baseQuery) => {
          const args = {
            method: 'PUT',
            url: generateUserPrefApiRoute({ preferenceKey }),
            body: snakeCaseKeys({ preferenceValue, preferenceVersion }, [snakeCase('preferenceValue')]),
          };

          // serialize requests that modify the same preference
          // to make sure requests (from the same tab) are not processed out-of-order by the server
          return queuedQuery(args, api, extraOptions, baseQuery, `${api.endpoint}/${preferenceKey}`);
        },
        invalidatesTags: (result, error, { preferenceKey }, { [unresolvedRequestsInQueueSymbol]: pendingMutations = 0 } = {}) => {
          if (pendingMutations !== 0) {
            // defer invalidation until all pending mutations have completed
            return [];
          }

          return [{ type: USER_PREFERENCES_TAG, id: preferenceKey }];
        },
      }),
    };
  },
});

export const useUpdateFetchUserPreferenceQueryData = (args) => {
  const dispatch = useDispatch();

  return useCallback((update) => {
    dispatch(
      apiSlice.util.updateQueryData('fetchUserPreference', args, (draft) => (
        copyWithStructuralSharing(draft, typeof update === 'function' ? update(draft) : update)
      )),
    );

    const { data } = dispatch((_, getState) => apiSlice.endpoints.fetchUserPreference.select(args)(getState()));
    return data;
  }, [dispatch, args]);
};

export const {
  useFetchUserPreferenceQuery,
  useUpdateUserPreferenceMutation,
} = extendedApiSlice;
