import NotifiedBy from './NotifiedBy';
import NoteNotification from './NoteNotification';
import { MENTIONED_IN_LISTING_NOTE_NOTIFICATION, NEW_LISTING_NOTE_NOTIFICATION } from './notification.constant';

const NOTIFICATION_TITLE = Object.freeze({
  [MENTIONED_IN_LISTING_NOTE_NOTIFICATION]: 'Mentioned in Listing Note',
  [NEW_LISTING_NOTE_NOTIFICATION]: 'New Listing Note',
});

const NOTIFIED_THROUGH = Object.freeze({
  [MENTIONED_IN_LISTING_NOTE_NOTIFICATION]: 'mention',
  [NEW_LISTING_NOTE_NOTIFICATION]: 'create',

});

export default function ListingNoteNotification({ notification }) {
  const { notificationType, listingNote: { body, listing: { id: listingId, offMarketMarketplace } }, property: { address, city, state }, taskOwnershipUpdatedAt, taskWhodunnitName } = notification.attributes;

  if (!offMarketMarketplace) {
    // listing notes are only supported for off-market listings
    return null;
  }

  return (
    <NoteNotification
      notification={notification}
      noteBody={body}
      notificationTitle={NOTIFICATION_TITLE[notificationType]}
    >
      <div className="mt-3 h-6 flex items-center">
        <a
          href={`/off_market_marketplace/inventory?${new URLSearchParams({ id: listingId, panel: 'notes' })}`}
          className="after:absolute after:inset-0 text-neutral-dark font-medium text-base text-nowrap focus:outline-none focus-visible:outline-none"
        >
          {[address, state && city, city && state].filter(Boolean).join(', ')}
        </a>
      </div>
      <NotifiedBy
        notifiedByName={taskWhodunnitName}
        notifiedAt={taskOwnershipUpdatedAt}
        notifiedThrough={NOTIFIED_THROUGH[notificationType]}
        className="mt-3"
      />
    </NoteNotification>
  );
}
