import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList as ListWindow } from 'react-window';
import { includes, isEmpty } from 'lodash';
import Dropdown from 'components/Dropdown';
import { ArrowDropDown, Chevron } from 'components/icons';
import Chip from 'components/shared/Chip';
import { formatInteger } from 'components/utils';
import AdvancedFilters from 'components/DealSourcing/Filters/AdvancedFilters';
import EmptyState from 'components/DealSourcing/List/EmptyState';
import FilterSection from 'components/DealSourcing/List/FilterSection';
import HighlightedProperty from 'components/DealSourcing/List/HighlightedProperty';
import ListingRow from 'components/DealSourcing/List/ListingRow';
import NewBuildRow, { NEW_BUILD_ROW_HEIGHT } from 'components/DealSourcing/List/NewBuildRow';
import OffMarketRow from 'components/DealSourcing/List/OffMarketRow';
import VirtualizedListInnerContainer from 'components/DealSourcing/List/VirtualizedListInnerContainer';
import {
  CHANNEL_LISTED,
  CHANNEL_NEW_BUILD,
  CHANNEL_OFF_MARKET,
  CHANNEL_OFF_MARKET_MARKETPLACE,
} from 'components/constants';
import { HOME_MODEL_ID_PREFIX } from '../dealSourcing';

const sortOptions = (channel) => {
  if (channel === CHANNEL_LISTED) {
    return [
      { id: 'listingContractDate-desc', name: 'Listing Date (Most Recent First)' },
      { id: 'listingContractDate-asc', name: 'Listing Date (Oldest First)' },
      { id: 'listPrice-desc', name: 'Purchase Price (Highest First)' },
      { id: 'listPrice-asc', name: 'Purchase Price (Lowest First)' },
      { id: 'pricePerSquareFoot-desc', name: 'Purchase Price $ PSF (Highest First' },
      { id: 'pricePerSquareFoot-asc', name: 'Purchase Price $ PSF (Lowest First' },
      { id: 'numberOfUnits-desc', name: 'Unit Count (Highest First)' },
      { id: 'numberOfUnits-asc', name: 'Unit Count (Lowest First)' },
      { id: 'goingInCapRate-desc', name: 'Asking Cap Rate (Highest First)' },
      { id: 'goingInCapRate-asc', name: 'Asking Cap Rate (Lowest First)' },
      { id: 'grossStabilizedYield-desc', name: 'Gross Yield (Highest First)' },
      { id: 'grossStabilizedYield-asc', name: 'Gross Yield (Lowest First)' },
      { id: 'stabilizedYield-desc', name: 'Stabilized Yield (Highest First)' },
      { id: 'stabilizedYield-asc', name: 'Stabilized Yield (Lowest First)' },
      { id: 'y1Yield-desc', name: 'Year 1 Cash on Cash (Highest First)' },
      { id: 'y1Yield-asc', name: 'Year 1 Cash on Cash (Lowest First)' },
      { id: 'leveredIrr-desc', name: 'Levered IRR (Highest First)' },
      { id: 'leveredIrr-asc', name: 'Levered IRR (Lowest First)' },
      { id: 'unleveredIrr-desc', name: 'Unlevered IRR (Highest First)' },
      { id: 'unleveredIrr-asc', name: 'Unlevered IRR (Lowest First)' },
    ];
  } else {
    return [
      { id: 'priceMin-desc', name: 'Purchase Price (Highest First)' },
      { id: 'priceMin-asc', name: 'Purchase Price (Lowest First)' },
      { id: 'grossStabilizedYield-desc', name: 'Gross Yield (Highest First)' },
      { id: 'grossStabilizedYield-asc', name: 'Gross Yield (Lowest First)' },
      { id: 'stabilizedYield-desc', name: 'Stabilized Yield (Highest First)' },
      { id: 'stabilizedYield-asc', name: 'Stabilized Yield (Lowest First)' },
      { id: 'y1Yield-desc', name: 'Year 1 Cash on Cash (Highest First)' },
      { id: 'y1Yield-asc', name: 'Year 1 Cash on Cash (Lowest First)' },
      { id: 'leveredIrr-desc', name: 'Levered IRR (Highest First)' },
      { id: 'leveredIrr-asc', name: 'Levered IRR (Lowest First)' },
      { id: 'unleveredIrr-desc', name: 'Unlevered IRR (Highest First)' },
      { id: 'unleveredIrr-asc', name: 'Unlevered IRR (Lowest First)' },
    ];
  }
};

const LISTED_ITEM_HEIGHT = 234;
const UNLISTED_ITEM_HEIGHT = 60;

const itemKey = (index, data) => data[index].id;

function ListSort({ settings: { channel, sortBy }, setSettings }) {
  const options = sortOptions(channel);
  const key = 'sortBy';
  const selectedItem = options.find(option => option.id === { sortBy }[key]) ?? options[0];

  return (
    <Dropdown
      buttonComponent={<Chip label={`Sort by: ${selectedItem.name}`} trailingIcon={<ArrowDropDown />} />}
      options={options}
      optionsWidth="w-max min-w-full"
      selectedItem={selectedItem}
      setSelectedItem={(option) => setSettings(prevSettings => ({ ...prevSettings, [key]: option.id }))}
    />
  );
}

function RenderSortIcon(col, sortColumn) {
  if (sortColumn === `${col}-desc`) {
    return (<Chevron direction="down" className="w-3" />);
  // eslint-disable-next-line no-else-return
  } else if (sortColumn === `${col}-asc`) {
    return (<Chevron direction="up" className="w-3" />);
  }
}

function List(props, ref) {
  const {
    currentUser,
    filters,
    isFetching,
    items,
    portfolio,
    portfolios,
    propertySubTypes,
    homeModelBuilders,
    homeModelSubdivisions,
    setFilters,
    setSettings,
    settings,
    listHeading,
    selectedItem,
    setSelectedItem,
    idVisibilityMap,
    mapRef,
  } = props;

  const { channel, sortBy: sortColumn } = settings;
  const shapeCleared = (!filters.area && !filters.circle && !filters.polygon);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    if (isEmpty(items)) return;
    if (!shapeCleared && isEmpty(selectedItem)) {
      setSelectedItem(null);
    }
  }, [filters]);

  const handleColumnSort = (col) => {
    let column = col;

    if (!includes(sortColumn, column)) {
      column = `${column}-desc`;
    }
    if (!includes(sortColumn, 'desc') && !includes(sortColumn, 'asc')) {
      column = `${column}-desc`;
    }
    if (includes(sortColumn, column) && includes(sortColumn, 'desc')) {
      column = `${column}-asc`;
    } else if (includes(sortColumn, column) && includes(sortColumn, 'asc')) {
      column = `${column}-desc`;
    }
    setSettings((prev) => ({ ...prev, sortBy: column }));
  };

  // use useMemo instead of useCallback to silence hook warning
  const MemoizedOffMarketRow = useMemo(() => memo(
    ({ index, data, style }) => (
      <OffMarketRow
        style={style}
        item={data[index]}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    ),
    areEqual,
  ), [selectedItem, setSelectedItem]);

  const currentDate = new Date();

  // use useMemo instead of useCallback to silence hook warning
  const MemoizedNewBuildRow = useMemo(() => memo(
    ({ index, data, style }) => {
      const item = data[index];
      const hasGeo = item.latitude && item.longitude;
      const markerId = `${HOME_MODEL_ID_PREFIX}${item.subdivision.id}`;

      return (
        <NewBuildRow
          style={style}
          item={item}
          currentDate={currentDate}
          currentUser={currentUser}
          portfolio={portfolio}
          availabilityFilter={filters.availability}
          onMouseEnter={(hasGeo && mapRef) && (() => {
            mapRef.current?.setMarkerFocused(markerId, true);
          })}
          onMouseLeave={(hasGeo && mapRef) && (() => {
            mapRef.current?.setMarkerFocused(markerId, false);
          })}
        />
      );
    },
    areEqual,
  ), [mapRef, filters.availability]);

  // use useMemo instead of useCallback to silence hook warning
  const MemoizedListingRow = useMemo(() => memo(
    ({ data, index, style }) => {
      const item = data[index];
      const hasGeo = item.latitude && item.longitude;

      return (
        <ListingRow
          currentUser={currentUser}
          item={item}
          onMouseEnter={(hasGeo && mapRef) && (() => {
            mapRef.current?.setMarkerFocused(item.id, true);
          })}
          onMouseLeave={(hasGeo && mapRef) && (() => {
            mapRef.current?.setMarkerFocused(item.id, false);
          })}
          portfolio={portfolio}
          style={style}
        />
      );
    },
    areEqual,
  ), [currentUser, mapRef]);

  const filteredItems = useMemo(
    () => items.filter(({ id }) => idVisibilityMap?.get(id) ?? true),
    [idVisibilityMap, items],
  );

  const listRef = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      scrollToProperty: (id, ...args) => {
        const idIdx = filteredItems.findIndex(({ id: itemId, subdivision: { id: subdivisionId } = {} }) => (
          id === (channel === CHANNEL_NEW_BUILD ? `${HOME_MODEL_ID_PREFIX}${subdivisionId}` : itemId)
        ));
        if (idIdx > -1) {
          listRef.current?.scrollToItem(idIdx, ...args);
        }
      },
    }),
    [channel, filteredItems],
  );

  // scroll to top when settings or filters changed
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0);
    }
  }, [items]);

  const emptyStateClasses = 'h-full w-full flex flex-col justify-center items-center z-20 bg-white shadow';
  let emptyState;
  if (isFetching) {
    emptyState = <EmptyState type="loading" className={emptyStateClasses} />;
  } else if ((channel === CHANNEL_OFF_MARKET) && listHeading) {
    emptyState = <EmptyState type="shapeTooBig" className={emptyStateClasses} />;
  } else if ((channel === CHANNEL_OFF_MARKET) && !listHeading && (!filters.area && !filters.circle && !filters.polygon)) {
    emptyState = <EmptyState type="needsShape" className={emptyStateClasses} />;
  } else if (items.length === 0) {
    emptyState = <EmptyState type="empty" className={emptyStateClasses} />;
  }

  let itemOptions;
  if (channel === CHANNEL_LISTED || channel === CHANNEL_OFF_MARKET_MARKETPLACE) {
    itemOptions = {
      itemSize: LISTED_ITEM_HEIGHT,
      overscanCount: 20,
      component: MemoizedListingRow,
    };
  } else if (channel === CHANNEL_NEW_BUILD) {
    itemOptions = {
      itemSize: NEW_BUILD_ROW_HEIGHT,
      overscanCount: 20,
      component: MemoizedNewBuildRow,
    };
  } else {
    itemOptions = {
      itemSize: UNLISTED_ITEM_HEIGHT,
      overscanCount: 500,
      component: MemoizedOffMarketRow,
    };
  }

  return (
    <div className="relative flex flex-col w-full z-20 bg-white divide-y max-w-screen-lg">
      {showAdvancedFilters && (
        <AdvancedFilters
          filters={filters}
          propertySubTypes={propertySubTypes}
          homeModelBuilders={homeModelBuilders}
          homeModelSubdivisions={homeModelSubdivisions}
          portfolio={portfolio}
          setFilters={setFilters}
          setShowAdvancedFilters={setShowAdvancedFilters}
          settings={settings}
        />
      )}
      <FilterSection
        filters={filters}
        portfolio={portfolio}
        portfolios={portfolios}
        propertySubTypes={propertySubTypes}
        homeModelBuilders={homeModelBuilders}
        homeModelSubdivisions={homeModelSubdivisions}
        setFilters={setFilters}
        setSettings={setSettings}
        setShowAdvancedFilters={setShowAdvancedFilters}
        settings={settings}
      />
      {emptyState ? (
        <div className="flex-auto">
          { emptyState }
        </div>
      ) : (
        <>
          <div className={`w-full flex flex-row align-center py-2.5 px-6 ${channel !== CHANNEL_OFF_MARKET ? 'justify-between' : 'justify-center'}`}>
            {(channel !== CHANNEL_OFF_MARKET) && <ListSort settings={settings} setSettings={setSettings} />}
            <div className="w-full flex items-center justify-end">
              <div className="text-center text-sm font-medium text-neutral-medium">
                {`${formatInteger(filteredItems.length)} of ${formatInteger(items?.length)} Results`}
              </div>
            </div>
          </div>
          {((channel === CHANNEL_OFF_MARKET) && !isEmpty(items) && selectedItem) && (
            <>
              <HighlightedProperty item={selectedItem} />
              <div className="w-full px-2 py-4 flex text-xs font-medium border-b">
                <div className={`w-3/12 flex items-center mx-2 cursor-pointer ${includes(sortColumn, 'address') && 'font-bold'}`} onClick={() => handleColumnSort('address')}>
                  { RenderSortIcon('address', sortColumn) }
                  Address
                </div>
                <div className={`w-2/12 flex items-center mx-2 cursor-pointer ${includes(sortColumn, 'propertyType') && 'font-bold'}`} onClick={() => handleColumnSort('propertyType')}>
                  { RenderSortIcon('propertyType', sortColumn) }
                  Parcel Use
                </div>
                <div className={`w-1/12 flex justify-end items-center mx-2 cursor-pointer text-right ${includes(sortColumn, 'numberOfUnits') && 'font-bold'}`} onClick={() => handleColumnSort('numberOfUnits')}>
                  { RenderSortIcon('numberOfUnits', sortColumn) }
                  Units
                </div>
                <div className={`w-1/12 flex justify-end items-center mx-2 cursor-pointer text-right ${includes(sortColumn, 'rentableBuildingArea') && 'font-bold'}`} onClick={() => handleColumnSort('rentableBuildingArea')}>
                  { RenderSortIcon('rentableBuildingArea', sortColumn) }
                  RSF/FAR
                </div>
                <div className={`w-1/12 flex justify-end items-center mx-2 cursor-pointer text-right ${includes(sortColumn, 'yearBuilt') && 'font-bold'}`} onClick={() => handleColumnSort('yearBuilt')}>
                  { RenderSortIcon('yearBuilt', sortColumn) }
                  Built/Eff
                </div>
                <div className={`w-2/12 flex items-center mx-2 cursor-pointer ${includes(sortColumn, 'lastSaleDate') && 'font-bold'}`} onClick={() => handleColumnSort('lastSaleDate')}>
                  { RenderSortIcon('lastSaleDate', sortColumn) }
                  Last Sale
                </div>
                <div className={`w-2/12 flex items-center mx-2 cursor-pointer ${includes(sortColumn, 'ownerType') && 'font-bold'}`} onClick={() => handleColumnSort('ownerType')}>
                  { RenderSortIcon('ownerType', sortColumn) }
                  Owner Type
                </div>
              </div>
            </>
          )}
          {/* id used by map to highlight a listing */}
          <div id="items" className="flex-auto">
            <AutoSizer>
              {({ height, width }) => (
                <ListWindow
                  ref={listRef}
                  height={height}
                  itemCount={filteredItems.length}
                  itemSize={itemOptions.itemSize}
                  itemKey={itemKey}
                  itemData={filteredItems}
                  width={width}
                  overscanCount={itemOptions.overscanCount}
                  innerElementType={VirtualizedListInnerContainer}
                >
                  {itemOptions.component}
                </ListWindow>
              )}
            </AutoSizer>
          </div>
        </>
      )}
    </div>
  );
}

export default forwardRef(List);
