import { generatePath } from 'react-router-dom';
import { DEAL_DASHBOARD_ROUTE } from 'components/routes';
import NotifiedBy from './NotifiedBy';
import NoteNotification from './NoteNotification';

export const formatMention = (text, { disableLinks = false } = {}) => {
  // Updated regex to match the new format "@[Name](email)"
  const mentionRegex = /@\[([^\]]+)\]\(([^\)]+)\)/g;
  const parts = [];
  let match;

  // While there is a match in the string
  while ((match = mentionRegex.exec(text)) !== null) {
    // Push the preceding text if any
    if (match.index > 0) parts.push(text.substring(0, match.index));
    // Push the match part - email as a mailto link
    parts.push({ name: match[1], email: match[2] });
    // Remove the processed part from the text
    text = text.substring(match.index + match[0].length);
  }
  // Push any remaining text
  if (text) parts.push(text);

  return parts.map((part, index) => {
    if (typeof part === 'object') {
      return (
        <a key={index} className="text-blue-600" href={`mailto:${encodeURIComponent(part.email)}`} inert={disableLinks ? '' : undefined}>
          {`@${part.name}`}
        </a>
      );
    }
    return part; // Regular text
  });
};

export default function MentionedInDealNotification({ notification }) {
  const { dealId, dealStage, dealNote, property: { address }, taskOwnershipUpdatedAt, taskWhodunnitName } = notification.attributes;

  return (
    <NoteNotification notification={notification} noteBody={dealNote} notificationTitle="Mentioned in Deal Note">
      <div className="mt-3 h-6 flex gap-x-2 items-center ">
        <a
          href={generatePath(DEAL_DASHBOARD_ROUTE, { dealId })}
          className="after:absolute after:inset-0 text-neutral-dark font-medium text-base text-nowrap focus:outline-none focus-visible:outline-none"
        >
          {address}
        </a>
        <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
      </div>
      <NotifiedBy notifiedByName={taskWhodunnitName} notifiedAt={taskOwnershipUpdatedAt} notifiedThrough="mention" className="mt-3" />
    </NoteNotification>
  );
}
