import { CHANNEL_OFF_MARKET_MARKETPLACE } from 'components/constants';
import { camelCaseKeys, coalesceListing, snakeCaseKeys, standardizeListing } from 'components/utils';
import { createSearchParams } from 'react-router-dom';
import { LISTING_ATTACHMENTS_TAG, OFF_MARKET_LISTINGS_TAG, OFF_MARKET_OFFERS_TAG } from './utils';
import { apiSlice } from './apiSlice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      createListingAttachments: builder.mutation({
        query: ({ id, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: `/api/listings/${id}/attachments`,
            method: 'POST',
            body: formData,
          });
        },
        invalidatesTags: [LISTING_ATTACHMENTS_TAG, OFF_MARKET_LISTINGS_TAG],
      }),
      deleteListingAttachment: builder.mutation({
        query: ({ listingId, id }) => ({
          url: `/api/listings/${listingId}/attachments/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [LISTING_ATTACHMENTS_TAG],
      }),
      updateListingAttachment: builder.mutation({
        query: ({ attachmentType, listingId, id }) => ({
          url: `/api/listings/${listingId}/attachments/${id}`,
          method: 'PUT',
          body: snakeCaseKeys({ attachmentType }),
        }),
        invalidatesTags: [LISTING_ATTACHMENTS_TAG, OFF_MARKET_LISTINGS_TAG, OFF_MARKET_OFFERS_TAG],
      }),
      fetchOffMarketMarketplaceListings: builder.query({
        query({ market }) {
          return {
            url: '/api/listings',
            params: createSearchParams({ channel: CHANNEL_OFF_MARKET_MARKETPLACE, 'markets[]': market }),
          };
        },
        transformResponse: (response) => (
          camelCaseKeys(response).map(({ parcel, ...listing }) => (
            coalesceListing({ listing: standardizeListing(listing), parcel })
          ))
        ),
        providesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
      fetchListingAttachments: builder.query({
        query: id => `/api/listings/${id}/attachments`,
        transformResponse: (response) => camelCaseKeys(response).data.map(d => d.attributes),
        providesTags: [LISTING_ATTACHMENTS_TAG],
      }),
      updateOffMarketListings: builder.mutation({
        query: ({ listings }) => ({
          url: '/api/listings/off_market',
          method: 'POST',
          body: snakeCaseKeys({ listings }),
        }),
        invalidatesTags: [OFF_MARKET_LISTINGS_TAG],
      }),
    };
  },
});

export const {
  useCreateListingAttachmentsMutation,
  useDeleteListingAttachmentMutation,
  useFetchListingAttachmentsQuery,
  useFetchOffMarketMarketplaceListingsQuery,
  useUpdateListingAttachmentMutation,
  useUpdateOffMarketListingsMutation,
} = extendedApiSlice;
