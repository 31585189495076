import { flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import IndeterminateCheckbox from 'components/shared/IndeterminateCheckbox';
import { naturalSortComparator } from 'components/utils';
import useUserPreference from 'hooks/useUserPreference';
import { createContext, useCallback, useContext, useId, useMemo, useState, useTransition } from 'react';
import {
  useActivePreset,
  useColumnVisibility,
  useIsColumnVisibilityToggleable,
  useToggleSectionColumns,
  useToggleSectionVisibility,
  useVisibilityConfigurableLeafHeaders,
} from '../DataTableContext';
import {
  FALLBACK_VISIBILITY,
  isColumnVisibilityToggleable,
  presetConfigStorageKey,
  STORAGE_VERSION,
  visibilityConfigPath,
  visibilitySectionConfigPath,
} from './utils';

const SectionVisibilityContext = createContext(Object.freeze({}));
SectionVisibilityContext.displayName = 'SectionVisibilityContext';

const useSectionVisibilityState = () => useContext(SectionVisibilityContext);
const setColumnVisibilityStorageValue = ({ columnId, defaultVisibility, visible, setColumnVisibility }) => {
  // store updated visibility, remove if the new value is equal to the default value
  const visibilityValue = !!visible === !!defaultVisibility ? null : !!visible;
  setColumnVisibility(({ [columnId]: _, ...prev } = {}) => ({
    ...prev,
    ...(visibilityValue === null ? {} : { [columnId]: visibilityValue }),
  }));
};

function VisibilitySection({ toggleSection, children }) {
  const preset = useActivePreset();
  const storageKey = presetConfigStorageKey({ presetId: preset.id });
  const toggleSectionVisibilityPath = useMemo(() => visibilitySectionConfigPath({ toggleSection }), [toggleSection]);
  const [sectionVisible, setSectionVisible] = useUserPreference(storageKey, null, STORAGE_VERSION, toggleSectionVisibilityPath);
  const [, setColumnVisibility] = useUserPreference(storageKey, undefined, STORAGE_VERSION, visibilityConfigPath(), { skip: true });

  const resolvedSectionVisibility = sectionVisible ?? preset.toggleSection[toggleSection] ?? preset.defaultVisibility ?? FALLBACK_VISIBILITY;
  const storeColumnVisibility = useCallback(({ columnId, visible }) => {
    const defaultVisibility = preset.visibility[columnId] ?? resolvedSectionVisibility;
    setColumnVisibilityStorageValue({ columnId, defaultVisibility, visible, setColumnVisibility });
  }, [preset.visibility, resolvedSectionVisibility, setColumnVisibility]);

  const toggleSectionColumns = useToggleSectionColumns({ toggleSection });
  const defaultSectionVisibility = preset.toggleSection[toggleSection] ?? preset.defaultVisibility ?? FALLBACK_VISIBILITY;
  const storeSectionVisibility = useCallback((visible) => {
    setSectionVisible(visible === defaultSectionVisibility ? null : visible);

    const updatedColumnVisibility = {};
    const batchedSetter = (update) => {
      Object.assign(updatedColumnVisibility, typeof update === 'function' ? update({}) : update);
    };

    toggleSectionColumns.forEach((column) => {
      const { id: columnId, getIsVisible } = column;

      const defaultVisibility = preset.visibility[columnId] ?? visible;
      const columnDesiredVisibility = isColumnVisibilityToggleable(column) ? visible : getIsVisible();
      setColumnVisibilityStorageValue({
        columnId,
        defaultVisibility,
        visible: columnDesiredVisibility,
        setColumnVisibility: batchedSetter,
      });
    });
    setColumnVisibility(updatedColumnVisibility);
  }, [preset.visibility, setSectionVisible, defaultSectionVisibility, toggleSectionColumns, setColumnVisibility]);

  const contextValue = useMemo(() => ({
    storeSectionVisibility,
    storeColumnVisibility,
  }), [storeColumnVisibility, storeSectionVisibility]);

  return (
    <SectionVisibilityContext.Provider value={contextValue}>
      {children}
    </SectionVisibilityContext.Provider>
  );
}

/**
 * @param {boolean} isVisible
 * @param {HTMLInputElement['indeterminate']} [indeterminate]
 * @param {import('react').ChangeEventHandler<HTMLInputElement>} onChange
 * @param {import('react').ComponentPropsWithoutRef<typeof IndeterminateCheckbox>} props
 */
function BaseVisibilityCheckbox({ isVisible, indeterminate, onChange, ...props }) {
  const [checked, setChecked] = useState(true);
  const [isPending, startTransition] = useTransition();

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const wrappedOnChange = useCallback((evt) => {
    setChecked(evt.currentTarget.checked);
    startTransition(() => {
      onChange(evt);
    });
  }, [onChange]);

  return (
    <IndeterminateCheckbox
      {...props}
      checked={isPending ? checked : isVisible}
      indeterminate={isPending ? true : indeterminate}
      onChange={wrappedOnChange}
      className="size-3.5 accent-primary-dark focus:outline-none"
    />
  );
}

/**
 * @param {import('@tanstack/react-table').Column} column
 * @param {import('react').ComponentPropsWithoutRef<'input'>} [props]
 */
function ColumnVisibilityCheckbox({ column, ...props }) {
  const { storeColumnVisibility } = useSectionVisibilityState();
  const [isVisible, toggle] = useColumnVisibility({ columnId: column.id });

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const onChange = useCallback((evt) => {
    toggle(evt.currentTarget.checked);
    storeColumnVisibility({ columnId: column.id, visible: evt.currentTarget.checked });
  }, [storeColumnVisibility, column.id, toggle]);

  return (
    <BaseVisibilityCheckbox
      {...props}
      type="checkbox"
      isVisible={isVisible}
      onChange={onChange}
    />
  );
}

/**
 *
 * @param toggleSection
 * @param {import('react').ComponentPropsWithoutRef<'input'>} [props]
 */
function SectionVisibilityCheckbox({ toggleSection, ...props }) {
  const { storeSectionVisibility } = useSectionVisibilityState();
  const [{ allVisible, someVisible, someToggleableVisible }, toggle] = useToggleSectionVisibility({ toggleSection });

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const onChange = useCallback((evt) => {
    toggle(evt.currentTarget.checked);
    storeSectionVisibility(evt.currentTarget.checked);
  }, [storeSectionVisibility, toggle]);

  return (
    <BaseVisibilityCheckbox
      {...props}
      isVisible={someToggleableVisible}
      indeterminate={someVisible && !allVisible}
      onChange={onChange}
    />
  );
}

function BaseVisibilityToggle({ Component, className, children }) {
  return (
    <Component
      className={cx(
        'grid col-span-2 items-center text-body-md font-medium rounded',
        '[&:has(input:not(:disabled))]:cursor-pointer hover:[&:has(input:not(:disabled))]:bg-primary-hover focus-within:bg-primary-focus',
        className,
      )}
    >
      {children}
    </Component>
  );
}

/**
 * @param {string} [className]
 * @param {import('react').ReactNode} children
 * @param {import('react').ComponentProps<'label'>} [props]
 */
function VisibilityToggleLabel({ className, children, ...props }) {
  return (
    <label
      {...props}
      className={cx('cursor-[inherit] [*:has(>&)]:relative after:absolute after:inset-0', className)}
    >
      {children}
    </label>
  );
}

function SectionVisibilityToggle({ toggleSection, className, children }) {
  const id = useId();

  return (
    <BaseVisibilityToggle Component="div" className={className}>
      <VisibilityToggleLabel
        htmlFor={id}
        className="text-neutral-light"
      >
        {children}
      </VisibilityToggleLabel>
      <SectionVisibilityCheckbox id={id} toggleSection={toggleSection} />
    </BaseVisibilityToggle>
  );
}

/**
 *
 * @param {import('@tanstack/react-table').Column} column
 * @param className
 * @param {import('react').ReactNode} children
 */
function ColumnVisibilityToggle({ column, className, children }) {
  const id = useId();
  const toggleable = useIsColumnVisibilityToggleable({ columnId: column.id });

  return (
    <BaseVisibilityToggle Component="li" className={className}>
      <VisibilityToggleLabel htmlFor={id}>
        {children}
      </VisibilityToggleLabel>
      <ColumnVisibilityCheckbox id={id} column={column} disabled={!toggleable} />
    </BaseVisibilityToggle>
  );
}

const useVisibilityToggleGroups = () => {
  const headers = useVisibilityConfigurableLeafHeaders();

  return useMemo(() => {
    /** @type {Map<any, import('@tanstack/table-core').Header[]>}  */
    const toggleSections = new Map();
    const nullSection = [];
    headers.forEach((header) => {
      const { column: { columnDef: { meta: { toggleSection } = {} } } } = header;

      const sectionName = toggleSection ?? null;
      if (sectionName === null) {
        nullSection.push(header);
        return;
      }

      const section = toggleSections.get(sectionName);
      if (section === undefined) {
        toggleSections.set(sectionName, [header]);
      } else {
        section.push(header);
      }
    });

    const comparator = naturalSortComparator();
    const sorted = Array
      .from(toggleSections.entries())
      .toSorted(([left], [right]) => comparator(left.toString(), right.toString()));

    if (nullSection.length) {
      sorted.push([null, nullSection]);
    }
    return sorted;
  }, [headers]);
};

export default function VisibilityGroups() {
  const visibilityToggleGroups = useVisibilityToggleGroups();
  const nullSectionLabel = visibilityToggleGroups.length === 1 ? 'All Columns' : 'Other Columns';

  return (
    <ul className="grid auto-cols-max gap-x-3 gap-y-1">
      {visibilityToggleGroups.map(([section, groupHeaders]) => (
        <VisibilitySection key={section ?? ''} toggleSection={section}>
          <li className="grid grid-cols-subgrid auto-rows-min col-span-2">
            <SectionVisibilityToggle toggleSection={section} className="grid-cols-subgrid">
              {section ?? nullSectionLabel}
            </SectionVisibilityToggle>
            <ul className="grid grid-cols-subgrid auto-rows-min col-span-2 gap-y-1">
              {groupHeaders.map(({ id, column, getContext }) => (
                <ColumnVisibilityToggle key={id} column={column} className="grid-cols-subgrid pl-2">
                  {flexRender(column.columnDef.header, getContext())}
                </ColumnVisibilityToggle>
              ))}
            </ul>
          </li>
        </VisibilitySection>
      ))}
    </ul>
  );
}
