import { startTransition, useMemo, useRef } from 'react';
import useIsInitialRender from './useIsInitialRender';

const useTransitionOnSecondRender = (transitionFn = startTransition) => {
  const isInitial = useRef(true);
  isInitial.current = useIsInitialRender();

  return useMemo(() => (
    (fn) => (isInitial.current ? fn?.() : transitionFn(fn))
  ), [transitionFn]);
};

export default useTransitionOnSecondRender;
