import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu } from '@headlessui/react';
import cx from 'classnames';
import useUserPreference from 'hooks/useUserPreference';
import { groupBy, includes, isEmpty, isFunction, isNil, omit, sortBy, xor } from 'lodash';
import { Config, FilledChevron, CheckboxFilled, CheckboxEmpty, X } from 'components/icons';
import { workflowPath } from 'components/routes';
import { formatCurrencyAbbreviated, mimicAnchorClick } from 'components/utils';
import Chip from 'components/shared/Chip';
import { getColumns } from 'components/pipeline/PipelineColumns';
import DataTable from 'components/shared/Table/DataTable';
import { ALL_DEALS_STAGE, INACTIVE_DEAL_STATES } from 'components/constants';
import PipelineConfigModal from 'components/pipeline/PipelineConfigModal';

const LOCAL_STORAGE_PIPELINE_DATA_TABLE_VERSION = 3;

const aggregateDeals = (deals) => {
  let units = 0;
  let value = 0;
  deals.forEach((deal) => {
    units += deal.units;
    value += deal.purchasePrice;
  });

  return {
    count: deals.length,
    units,
    value,
  };
};

function MultiSelectDropdown({ filters, setFilters, localFilters, setLocalFilters, name, filterKey, selectOptions }) {
  // if any current filter values no longer exists as an option, automatically clear that value from the filter
  const invalidFilterValues = filters[filterKey]?.filter(filterValue => isNil(selectOptions.find(op => op.value === filterValue)));
  if (invalidFilterValues?.length) {
    setLocalFilters(omit(localFilters, [filterKey]));
    setFilters(omit(filters, [filterKey]));
  }

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button
            className={cx(
              'cursor-pointer flex mt-0.5 h-8 text-xs font-medium justify-center items-center text-center rounded-lg px-3 py-1.5 border outline-neutral-light select-none hover:bg-blue-200',
              { 'bg-blue-100': !isEmpty(filters[filterKey]) || open },
            )}
          >
            { /* eslint-disable-next-line no-nested-ternary */}
            { isEmpty(filters[filterKey]) ? name : (filters[filterKey]?.length === 1 ? selectOptions.find(op => op.value === filters[filterKey][0])?.label : `${filters[filterKey]?.length} ${name}`)}
            <div className="h-full items-center flex space-x-2">
              {!isEmpty(filters[filterKey]) ? (
                <X
                  className="ml-4 w-4 h-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                />
              ) : (<FilledChevron className="w-5" direction={open ? 'up' : 'down'} />)}
            </div>
          </Menu.Button>
          <Menu.Items className="absolute w-92 mt-0.5 rounded-lg bg-white border z-30 shadow-lg">
            <div
              className="w-92 flex p-4 items-center justify-between cursor-pointer text-base select-none relative font-normal text-[#1C1B1F] border-b"
              onClick={() => setLocalFilters({ ...localFilters, [filterKey]: selectOptions.length === localFilters[filterKey]?.length ? [] : selectOptions.map(({ value }) => value) })}
            >
              <span className="pr-24">
                {`All ${name}`}
              </span>
              { selectOptions.length === localFilters[filterKey]?.length ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
            </div>
            {sortBy(selectOptions, 'label').map(({ label, value }, index) => (
              <div
                key={index}
                className="w-92 h-14 flex items-center justify-between cursor-pointer text-base select-none relative py-6 px-4 font-normal text-[#1C1B1F]"
                onClick={() => setLocalFilters({ ...localFilters, [filterKey]: xor(localFilters[filterKey] || [], [value]) })}
              >
                <span className="pr-24">{label}</span>
                { includes(localFilters[filterKey], value) ? <CheckboxFilled className="w-8" /> : <CheckboxEmpty className="w-8" /> }
              </div>
            ))}
            <Menu.Item as="div" className="h-12 mt-4 p-4 flex justify-between">
              <div />
              <div className="flex h-full item-center items-end">
                <div
                  className="mx-2 px-4 py-2 font-medium text-primary-dark text-sm cursor-pointer"
                  onClick={() => {
                    setLocalFilters(omit(localFilters, [filterKey]));
                    setFilters(omit(filters, [filterKey]));
                  }}
                >
                  Reset
                </div>
                <div
                  className="flex h-10 w-20 items-center px-5 text-sm rounded-full bg-primary-dark text-white cursor-pointer font-medium"
                  onClick={() => {
                    setFilters(localFilters);
                  }}
                >
                  Apply
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

export default function DealTable(props) {
  const {
    deals,
    dealStage,
    filters,
    searchTerm,
    setFilters,
    showMarkDealDeadModal,
    stages,
    tableHeight,
    transactionType,
    user,
    uniqueClients,
    uniqueEntities,
    uniqueLeads,
    uniqueMarkets,
    uniquePortfolios,
    usersById,
  } = props;
  const { settings } = user;
  const { pipelineDateFormat: dateFormat } = settings;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localFilters, setLocalFilters] = useState(filters);
  const [showPipelineConfigModal, setShowPipelineConfigModal] = useState(false);

  useEffect(() => {
    // keep local filters in sync
    setLocalFilters(filters);
  }, [filters]);

  const columns = useMemo(
    () => getColumns({ transactionType, dispatch, usersById, showMarkDealDeadModal, dateFormat }),
    [transactionType, dispatch, usersById, showMarkDealDeadModal, dateFormat],
  );

  // TODO: only run this initialState logic if the localStorage config value is uninitialized
  const toggleableColumns = columns.filter(c => c.enableHiding !== false).map(c => c.id);
  const pipelineTableInitialState = [ALL_DEALS_STAGE, ...stages].reduce((result, stage) => ({
    ...result,
    [stage]: { visibleColumns: toggleableColumns },
  }), {});
  const [pipelineTableConfig, setPipelineTableConfig] = useUserPreference(
    `Pipeline.DataTable.${transactionType}`,
    pipelineTableInitialState,
    LOCAL_STORAGE_PIPELINE_DATA_TABLE_VERSION,
  );

  const updatePipelineConfig = (key, valueArg) => {
    setPipelineTableConfig(prevPipelineTableConfig => {
      const prevStageConfig = prevPipelineTableConfig[dealStage] || {};
      const value = isFunction(valueArg) ? valueArg(prevStageConfig[key]) : valueArg;
      return {
        ...prevPipelineTableConfig,
        [dealStage]: { ...prevStageConfig, [key]: value },
      };
    });
  };

  const setColumnVisibility = (columnId) => {
    updatePipelineConfig('visibleColumns', xor(pipelineTableConfig[dealStage]?.visibleColumns, [columnId]));
  };
  const setSorting = (sortingArg) => {
    updatePipelineConfig('sorting', sortingArg);
  };

  const LOCAL_STORAGE_COLUMN_ORDER_KEY = `Pipeline.ColumnOrder.${transactionType}`;

  const [columnOrder, setColumnOrder] = useUserPreference(
    LOCAL_STORAGE_COLUMN_ORDER_KEY,
    columns.map(c => c.id),
    LOCAL_STORAGE_PIPELINE_DATA_TABLE_VERSION,
  );

  // if config for dealStage does not yet exist, initialize using default columnVisibility
  const { visibleColumns, sorting } = pipelineTableConfig[dealStage] || { visibleColumns: toggleableColumns };
  const columnVisibility = useMemo(
    () => Object.fromEntries(columns.map(c => [c.id, (c.enableHiding === false) || visibleColumns?.includes(c.id)])),
    [columns, visibleColumns],
  );

  const stagedDeals = groupBy(deals, 'stage');
  const activeDeals = deals.filter(deal => !INACTIVE_DEAL_STATES.includes(deal.stage));
  const summaryDeals = dealStage === ALL_DEALS_STAGE ? activeDeals : (stagedDeals[dealStage] || []);
  const { count, units, value: totalPurchasePrice } = aggregateDeals(summaryDeals);

  const options = [
    { name: 'Markets', filterKey: 'marketIds', selectOptions: uniqueMarkets },
    { name: 'Clients', filterKey: 'clients', selectOptions: uniqueClients },
    { name: 'Entities', filterKey: 'entities', selectOptions: uniqueEntities },
    { name: 'Portfolios', filterKey: 'portfolioIds', selectOptions: uniquePortfolios },
    { name: 'Leads', filterKey: 'leadIds', selectOptions: uniqueLeads },
  ];

  const onRowClick = (row, event) => {
    mimicAnchorClick(event, workflowPath({ id: row.original.id }), navigate);
  };

  return (
    <div className="border border-gray-200 rounded bg-white">
      <div className="h-12 px-2 flex justify-between border-b">
        <div className="mt-1.5 flex space-x-2">
          {options.map((optionObject, index) => (
            <div className="flex h-full" key={index}>
              <MultiSelectDropdown
                {...optionObject}
                filters={filters}
                setFilters={setFilters}
                localFilters={localFilters}
                setLocalFilters={setLocalFilters}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between gap-x-4 py-3 items-center">
          <div className="text-neutral-medium font-base">
            <b>{count}</b>
            {' '}
            deals ·
            {' '}
            <b>{units}</b>
            {' '}
            units ·
            {' '}
            <b>{formatCurrencyAbbreviated(totalPurchasePrice)}</b>
            {' '}
            value
          </div>
          <Chip
            preferLeadingIcon
            label="Config Table"
            leadingIcon={<Config />}
            onClick={() => setShowPipelineConfigModal(true)}
          />
        </div>
      </div>
      <DataTable
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        columns={columns}
        data={deals}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        sorting={sorting}
        setSorting={setSorting}
        onRowClick={onRowClick}
        tableHeight={tableHeight}
        emptyStateComponent={searchTerm?.length > 0 ? `No deals were found for the search term '${searchTerm}'` : 'No deals were found for the active filters'}
      />
      {showPipelineConfigModal && (
        <PipelineConfigModal
          columnOrder={columnOrder}
          columnVisibility={columnVisibility}
          columns={columns}
          dealStage={dealStage}
          pipelineTableConfig={pipelineTableConfig}
          setColumnOrder={setColumnOrder}
          setColumnVisibility={setColumnVisibility}
          setShowPipelineConfigModal={setShowPipelineConfigModal}
          updatePipelineConfig={updatePipelineConfig}
        />
      )}
    </div>
  );
}
