import { useLayoutEffect, useRef } from 'react';

/**
 * @return {boolean}
 */
const useIsInitialRender = () => {
  const isInitial = useRef(true);

  useLayoutEffect(() => {
    isInitial.current = false;

    // need to unset for strict mode
    return () => {
      isInitial.current = true;
    };
  }, []);

  return isInitial.current;
};

export default useIsInitialRender;
