import { useMemo } from 'react';
import { naturalSortComparator } from 'components/utils';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import { useFetchSubdivisionsQuery } from '../../../redux/subdivisionApiSlice';

const selectMarkets = ({ currentData, isFetching, isUninitialized }) => ({
  currentData,
  isFetching: isFetching || isUninitialized,
});

const useSortedOptions = (currentData) => (
  useMemo(() => {
    const uniqMarkets = currentData ? [...new Set(currentData.map(({ market }) => market))] : null;
    return uniqMarkets?.toSorted(naturalSortComparator);
  }, [currentData])
);

export default function SubdivisionMarketFilter() {
  const { currentData, isFetching } = useFetchSubdivisionsQuery(undefined, { selectFromResult: selectMarkets });
  const sortedMarketOptions = useSortedOptions(currentData);

  return (
    <ComboboxFilter
      isLoading={isFetching}
      options={sortedMarketOptions}
    />
  );
}
