import Alert from 'components/Alert';
import Dropdown from 'components/Dropdown';
import Button from 'components/shared/NewButton';
import { downloadUrl, naturalSortComparator, titleCase } from 'components/utils';
import { isArray, isEmpty, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetchHomeBuildersQuery } from 'redux/homeBuilderApiSlice';
import { useLazyFetchHomeModelImportTemplateCsvQuery, useFetchHomeModelImportStatsQuery, usePostHomeModelCsvDataMutation } from 'redux/homeModelApiSlice';
import Input from 'components/Input';
import InfoPopover from 'components/portfolio/Menu/InfoPopover';
import { LoadingIndicator } from 'components/icons';

const CSV_TEMPLATE_FILENAME = 'new_build_import_template.csv';

const formatError = (error) => {
  if (!error) return null;
  if (isArray(error)) {
    if (isArray(error[0])) {
      // nested array
      return error.map(errPair => `Line ${errPair[1]}: ${errPair[0]}`).join("\n");
    } else {
      return error.join("\n");
    }
  }
  return error;
};

function StatRow({ homeBuilderById, homeBuilderId, count }) {
  const homeBuilder = homeBuilderById[homeBuilderId.toString()];

  return (
    <>
      <div>{homeBuilder.name}</div>
      <div>{count}</div>
    </>
  );
}

const parseStatArr = (key, importStats) => {
  if (!importStats) return null;

  const { asOfDate } = importStats;
  const statObj = asOfDate[key];
  const statArr = Object.entries(statObj).map(entry => ([entry[0], entry[1]]));
  return sortBy(statArr, arr => -1 * arr[1]);
};

function StatSection({ statKey, importStats, homeBuilderById }) {
  const statArr = parseStatArr(statKey, importStats);

  let statContents;
  if (!homeBuilderById) {
    statContents = <LoadingIndicator className="w-8 text-gray-500" />;
  } else if (isEmpty(statArr)) {
    statContents = <div className="mt-6 font-light text-gray-400">No data imported during this time period</div>;
  } else {
    statContents = (
      <div className="w-128 text-sm mt-6 grid grid-cols-2 gap-y-2">
        <div className="border-b font-sm font-medium">Builder</div>
        <div className="border-b font-sm font-medium">Home Models Updated</div>
        {statArr.map(arr => (
          <StatRow
            key={arr[0]}
            homeBuilderById={homeBuilderById}
            homeBuilderId={arr[0]}
            count={arr[1]}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <h3 className="text-sm text-gray-600 font-medium mt-12">{titleCase(statKey)}</h3>
      {statContents}
    </>
  );
}

export default function SettingsForm({ csvUploadOptions, setCsvUploadOptions, setImportData, uploadSuccessAlert, setUploadSuccessAlert }) {
  const [uploadError, setUploadError] = useState(null);

  const { data: homeBuilders } = useFetchHomeBuildersQuery();
  const { data: importStats } = useFetchHomeModelImportStatsQuery();

  const homeBuilderById = useMemo(() => homeBuilders?.reduce((obj, builder) => ({ ...obj, [builder.id]: builder }), {}), [homeBuilders]);

  const sortedHomeBuilders = useMemo(() => {
    if (!homeBuilders?.length) {
      return homeBuilders;
    }

    const comparator = naturalSortComparator();
    const sorted = [...homeBuilders];
    sorted.sort(({ name: left }, { name: right }) => comparator(left, right));
    return sorted;
  }, [homeBuilders]);

  const promiseRef = useRef(null);
  const fileUploadRef = useRef(null);

  // TODO: handle errors
  const [fetchTemplate, { data: csvObjectUrl, error: templateError }] = useLazyFetchHomeModelImportTemplateCsvQuery();
  const [importResults, { isLoading: isImportingCsv, data: importResultsData, error: importError }] = usePostHomeModelCsvDataMutation();
  useEffect(() => {
    setImportData(importResultsData);
  }, [importResultsData, setImportData]);

  useEffect(() => {
    if (csvObjectUrl) {
      downloadUrl(csvObjectUrl, window.document, CSV_TEMPLATE_FILENAME, false);
    }
  }, [csvObjectUrl]);

  const downloadTemplate = useCallback(
    () => {
      promiseRef.current = fetchTemplate(csvUploadOptions.homeBuilderId);
    },
    [fetchTemplate, csvUploadOptions.homeBuilderId],
  );

  const handleFileChange = async (e) => {
    setUploadError(null);
    setUploadSuccessAlert(null);

    const files = Array.from(e.target.files);
    if (files.length !== 1) {
      setUploadError('May only upload a single CSV file at a time');
      return;
    }
    if (files[0].type !== 'text/csv') {
      setUploadError('Upload file must have a .csv file extension');
      return;
    }
    const csvFile = files[0];
    importResults({ ...csvUploadOptions, file: csvFile });
  };

  const alert = (templateError ?? uploadError ?? importError) ? {
    text: formatError(templateError?.data?.error ?? uploadError ?? importError?.data?.error),
    type: 'danger',
  } : null;

  return (
    <div className="flex gap-x-16">
      <div className="w-128 h-fit bg-white rounded p-6">
        <div className="text-lg text-gray-900">Add New Build Inventory</div>
        <div className="flex my-6 text-tertiary text-sm underline gap-x-2">
          <Link to="/geocode">Batch Geocode Addresses</Link>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-medium text-sm text-gray-900">Builder</div>
          <Link className="font-medium text-sm text-primary-dark underline" to="/home_builders/new">Add Builder</Link>
        </div>
        <div>
          <Dropdown
            name="builder"
            width="w-full"
            className="mt-2 mb-6"
            optionsWidth="w-full"
            options={sortedHomeBuilders}
            selectedItem={sortedHomeBuilders?.find(hb => hb.id === csvUploadOptions.homeBuilderId)}
            setSelectedItem={homeBuilder => setCsvUploadOptions(prev => ({ ...prev, homeBuilderId: homeBuilder.id }))}
          />
        </div>
        <div className="mb-6">
          <div className="flex items-center">
            <div className="text-neutral-dark font-medium text-sm mr-2">Set Subdivision Inventory</div>
            <InfoPopover
              title="Set Subdivision Inventory"
              detail="When selected, it will zero out the inventory (both current and future) of all home plans that are within subdivisions included in the upload but not included in the CSV"
            />
          </div>
          <Input
            name="clearSubdivisionInventory"
            type="checkbox"
            value={csvUploadOptions.clearSubdivisionInventory}
            onChange={() => setCsvUploadOptions({
              ...csvUploadOptions,
              clearSubdivisionInventory: !csvUploadOptions.clearSubdivisionInventory,
            })}
          />
        </div>
        {alert && <Alert className="whitespace-pre-line" {...alert} />}
        {uploadSuccessAlert && <Alert {...uploadSuccessAlert} className="whitespace-pre-line" type="success" />}
        <div className="mt-12 flex justify-between items-center">
          <Button
            outlined
            disabled={!csvUploadOptions.homeBuilderId}
            label="Download CSV Template"
            onClick={downloadTemplate}
          />
          <Button
            filled
            disabled={!csvUploadOptions.homeBuilderId}
            label="Upload CSV"
            isLoading={isImportingCsv}
            onClick={(e) => {
              e.preventDefault();
              fileUploadRef.current.click();
            }}
          />
          <input
            id="fileUpload"
            type="file"
            accept="text/csv"
            className="hidden"
            ref={fileUploadRef}
            onChange={handleFileChange}
            onClick={(e) => { e.currentTarget.value = null; }}
          />
        </div>
      </div>
      <div className="bg-white rounded p-6 flex-grow overflow-y-scroll" style={{ maxHeight: 'calc(100vh - 128px)' }}>
        <div className="text-lg text-gray-900">Import Stats</div>
        <StatSection statKey="pastWeek" importStats={importStats} homeBuilderById={homeBuilderById} />
        <StatSection statKey="pastMonth" importStats={importStats} homeBuilderById={homeBuilderById} />
        <StatSection statKey="allTime" importStats={importStats} homeBuilderById={homeBuilderById} />
      </div>
    </div>
  );
}
