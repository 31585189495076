import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { useBlocker } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import { cloneDeep, isEmpty, isEqual, isNil, partial, set, sum, sumBy, uniq } from 'lodash';
import { clearToast, showToast } from 'redux/toastSlice';
import { showSaveChangesModal } from 'actions/deal_navigation';
import { useUpdateScenariosMutation } from 'redux/dealApiSlice';
import Button from 'components/shared/NewButton';
import {
  calcCompScoreTier,
  coalesceProperty,
  dotProductBy,
  formatCurrency,
  formatDate,
  formatEquityMultiple,
  formatInteger,
  formatPercentage,
  parseEventValue,
} from 'components/utils';
import { HOA_FEE_EXPENSE_ITEM_NAME, LAYOUT, SCORE_COLORS } from 'components/constants';
import { TOAST_UNSAVED_CHANGES } from 'components/toast';
import { calcCashFlows, calcReturnMetrics, calcReturnMetricsOfIndividualScenarios } from 'components/dcf/dcf';
import DataTable from 'components/shared/Table/DataTable';
import { AddressCell, BuyBoxCell } from 'components/shared/Table/Cells';
import Toggle from 'components/Toggle';
import useElementHeight from 'hooks/useElementHeight';
import Input from 'components/Input';
import { Arrow, Check, LoadingIndicator, X } from 'components/icons';
import { ITEMIZED_INPUT_METHOD_DPM } from 'components/dcf/itemizedItem';
import { dataTableMeta, enableConfigPresets, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { InlineFormField, InlineInfoField } from 'components/Form';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import { EMPTY_PRESET, getDefaultPresetId } from 'components/shared/Table/dataTableConfig/utils';
import { DataTableContent, useFilteredRows } from 'components/shared/Table/DataTableContext';
import ChipGroup from 'components/shared/ChipGroup';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import RangeFilter from 'components/shared/newfilters/RangeFilter';
import FilterChip from 'components/shared/newfilters/FilterChip';
import TrueFalseToggle from 'components/shared/newfilters/TrueFalseToggle';
import IndeterminateCheckbox from 'components/shared/IndeterminateCheckbox';
import useRangeDisplayValue from 'components/shared/newfilters/useRangeDisplayValue';
import { rangeFilter } from 'components/shared/Table/table.filterFns';
import { useSelectedFilters } from 'components/shared/newfilters/filterHooks';
import { useFetchHomeModelQuery } from '../../redux/homeModelApiSlice';

const columnHelper = createColumnHelper();
const TABLE_INITIAL_STATE = { columnVisibility: { isBuyBoxMatch: false } };
const meetsBuyBoxFilterDisplayFn = (value) => {
  const icon = value ? <Check /> : <X />;
  return (
    <div className="flex items-center">
      <div className="mr-2 w-4">{icon}</div>
      <div>Matches Buy Box</div>
    </div>
  );
};

const getTableId = ({ newBuildDeal = false }) => {
  if (newBuildDeal) {
    return 'PortfolioDealSummaryNewBuild';
  }

  return 'PortfolioDealSummaryIndividual';
};

const ALL_TABLE_IDS = Object.freeze([
  getTableId({ individuallyModelled: true }),
  getTableId({ newBuildDeal: true }),
]);

const DEFAULT_PRESET = Object.freeze({
  ...EMPTY_PRESET,
  visibility: Object.freeze({
    capital: false,
    stabilizationMonth: false,
    taxes: false,
    hoa: false,
    rollToMarket: false,
  }),
});
const CONFIG_PRESETS = Object.freeze(Object.fromEntries(ALL_TABLE_IDS.map((tableId) => (
  [
    tableId,
    Object.freeze({
      [getDefaultPresetId({ tableId })]: Object.freeze({
        ...DEFAULT_PRESET,
        id: getDefaultPresetId({ tableId }),
      }),
    }),
  ]
))));

const getScenario = (property, scenarios) => scenarios.find(s => s.primary && (property.id === s.propertyId));
const getUnits = (property, scenario) => scenario.parameters.units;
const getParcel = (property, parcels) => parcels.find(parcel => parcel.apn === property.apnMin);
const getRent = (scenario, rentType) => sumBy(scenario.parameters.units, rentType);
const getReturnMetric = (scenario, metric) => scenario?.returnMetrics?.[metric];

function FilterMessage({ tableData }) {
  const selectedFilters = useSelectedFilters();
  const filteredRows = useFilteredRows();
  if (!selectedFilters.length) {
    return null;
  }

  return (
    <div className="text-sm text-gray-500">{`Filters Match ${filteredRows.length} of ${tableData.length} Properties`}</div>
  );
}

function ActiveHeader({ column: { columnDef: { meta } } }) {
  const { selectedProperties, setSelectedProperties } = meta;
  const filteredRows = useFilteredRows();
  const [checked, indeterminate] = useMemo(() => {
    const activePropertiesCount = filteredRows.filter(r => selectedProperties[r.original.property.id]).length;
    const isChecked = activePropertiesCount > 0;
    const isIndeterminate = isChecked && (activePropertiesCount < filteredRows.length);
    return [isChecked, isIndeterminate];
  }, [filteredRows, selectedProperties]);

  const onChange = useCallback(() => {
    const isEnabling = !checked || indeterminate;
    setSelectedProperties(prevSelectedProperties => {
      const updatedSelectedProperties = { ...prevSelectedProperties };
      filteredRows.forEach(row => {
        updatedSelectedProperties[row.original.property.id] = isEnabling
      });
      return updatedSelectedProperties;
    });
  }, [checked, filteredRows, indeterminate, setSelectedProperties]);

  return (
    <div className="mx-auto">
      <IndeterminateCheckbox
        className="size-4 accent-primary-dark focus:outline-none"
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
      />
    </div>
  );
}

function ActiveCell({ row, column: { columnDef: { meta } } }) {
  const { selectedProperties, togglePropertyActive } = meta;
  return (
    <div
      className="flex justify-center px-1 py-1"
      onClick={() => togglePropertyActive(row.original.property.id)}
    >
      <Toggle checked={selectedProperties[row.original.property.id]} />
    </div>
  )
}

function TableConfigButton() {
  return (
    <Popover className="relative">
      <Popover.Button as="div">
        <Button label="Toggle Columns" outlined small />
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-30 w-max max-h-96 overflow-auto bg-white rounded shadow">
        <DataTableConfigPanelBody className="p-2" />
      </Popover.Panel>
    </Popover>
  );
}

function CompRentCell({ getValue }) {
  const value = getValue();
  if (!value) return null;
  const [rent, score] = value;
  return (
    <div className="flex justify-between gap-x-2 items-center">
      <div>{formatCurrency(rent)}</div>
      <div className={cx(SCORE_COLORS[calcCompScoreTier(score)], 'size-4 rounded-full')} />
    </div>
  );
}

function InputCell({ column, getValue, table, row: { original } }) {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const initialScenarioValue = useMemo(() => column.accessorFn({ scenario: original.initialScenario }), [column, original]);
  const isChanged = initialScenarioValue !== value;

  return (
    <div className="w-24">
      <Input
        className={isChanged ? 'bg-blue-50' : null}
        addOnClassName="overflow-hidden"
        type="currency"
        value={value}
        onChange={(e) => table.options.meta?.onScenarioChange(original.scenario?.id, column.columnDef.meta.name, parseEventValue(e))}
      />
    </div>
  );
}

function HomeModelQuantityCell({
  getValue,
  row: {
    original: {
      property: { homeModelId },
      scenario: {
        id: scenarioId,
        parameters: { homeModelDeliverySchedule },
      },
    },
  },
  table: { options: { meta: { onScenarioChange } } },
}) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  // combine dates specified in the scenario and dates in home model
  // there could be dates in the scenario that are not in the home model
  const deliveryDates = useMemo(() => (
    Array
      .from(new Set(
        [...(futureDeliveries ?? []), ...(homeModelDeliverySchedule ?? [])].map(([date]) => date),
      ))
      .toSorted()
  ), [homeModelDeliverySchedule, futureDeliveries]);

  const futureDeliveriesMap = useMemo(() => (
    Object.fromEntries(futureDeliveries ?? [])
  ), [futureDeliveries]);
  const deliveryScheduleMap = useMemo(() => (
    Object.fromEntries(homeModelDeliverySchedule ?? [])
  ), [homeModelDeliverySchedule]);

  return (
    <Popover className="relative w-20">
      <Popover.Button className="rounded border cursor-text py-1 px-2 w-full bg-white text-right">
        {getValue()}
      </Popover.Button>

      <Popover.Panel className="absolute left-0 w-80 mt-1 p-2 z-30 flex flex-col gap-y-3 rounded border bg-white shadow">
        {!currentData ? (
          <LoadingIndicator className="size-6 mx-auto text-tertiary" />
        ) : (
          <>
            <InlineInfoField label="Availability">
              <div className="flex flex-col text-right tabular-nums text-sm">
                <div>{`Available Now: ${numAvailable}`}</div>
                {deliveryDates.map((date) => (
                  <div key={date}>{`${formatDate(date, 'MMM yyyy')}: ${futureDeliveriesMap[date] ?? 0}`}</div>
                ))}
              </div>
            </InlineInfoField>
            <InlineFormField
              type="number"
              name="quantity"
              value={getValue()}
              onChange={(evt) => onScenarioChange(scenarioId, 'multiplicity', parseEventValue(evt))}
            />
            {deliveryDates.map((date) => (
              <InlineFormField
                key={date}
                type="number"
                name={`quantity${date}`}
                label={`Quantity (${formatDate(date, 'MMM yyyy')})`}
                value={deliveryScheduleMap?.[date] ?? 0}
                onChange={(evt) => (
                  onScenarioChange(
                    scenarioId,
                    'homeModelDeliverySchedule',
                    Object.entries({ ...deliveryScheduleMap, [date]: parseEventValue(evt) }),
                  )
                )}
              />
            ))}
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
}

function HomeModelAvailableNow({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable } = currentData ?? {};
  return numAvailable;
}

function HomeModelAvailableTotal({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  const totalFuture = futureDeliveries ? sumBy(futureDeliveries, pair => pair[1]) : 0;
  return numAvailable + totalFuture;
}

const ADDRESS_COLUMN = {
  id: 'address',
  header: 'Address',
  enableHiding: false,
  accessorKey: 'property.address',
  cell: AddressCell,
  sortingFn: 'text',
  meta: { className: 'pl-3', ...tableConfigMeta({ order: 'readonly' }) },
};

const COMMON_COLUMNS = [
  columnHelper.accessor(row => !row.property.buyBoxMatch.length, {
    id: 'isBuyBoxMatch',
    filterFn: 'equals',
    meta: { ...dataTableMeta.disableTableConfig },
  }),
  ADDRESS_COLUMN,
  {
    id: 'market',
    header: 'Market',
    accessorKey: 'property.market',
  },
  {
    id: 'propertyType',
    header: 'Property Type',
    accessorKey: 'property.propertyType',
  },
  {
    id: 'bed',
    header: 'Bed',
    accessorKey: 'property.bedrooms',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'bath',
    header: 'Bath',
    accessorKey: 'property.bathrooms',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'sqft',
    header: 'Sq Ft',
    accessorKey: 'property.livingArea',
    cell: ({ getValue }) => formatInteger(getValue()),
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yearBuilt',
    header: 'Year Built',
    accessorKey: 'property.yearBuilt',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
];

const NEW_BUILD_COLUMNS = [
  {
    id: 'multiplicity',
    header: 'Quantity',
    accessorKey: 'scenario.parameters.multiplicity',
    cell: HomeModelQuantityCell,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableNow',
    header: 'Available Now',
    cell: HomeModelAvailableNow,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableTotal',
    header: 'Available Total',
    cell: HomeModelAvailableTotal,
    meta: { ...dataTableMeta.textRight },
  },

];

const INDIVIDUAL_COLUMNS = [
  {
    id: 'listPrice',
    header: 'List Price',
    accessorFn: row => row.scenario?.parameters?.listPrice,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'underwrittenPrice',
    header: 'Underwritten Price',
    accessorFn: row => row.scenario?.parameters?.purchasePrice,
    cell: InputCell,
    meta: { name: 'purchasePrice' },
  },
  {
    id: 'avm',
    header: 'AVM',
    accessorFn: row => row.property?.data?.avm?.estimatedValueAmount,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'totalCost',
    header: 'Total Cost',
    accessorFn: row => row.scenario?.returnMetrics?.unleveredBasis?.[0],
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'arv',
    header: 'ARV',
    accessorFn: row => row.scenario?.parameters?.arv,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'builtInEquity',
    header: 'Built-In Equity',
    accessorFn: row => {
      const arv = row.scenario?.parameters?.arv;
      const totalAcquistionCost = row.scenario?.returnMetrics?.unleveredBasis?.[0];
      return (arv && totalAcquistionCost) ? (arv / totalAcquistionCost) - 1 : null;
    },
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'inPlaceRent',
    header: 'In-Place Rent',
    accessorFn: row => getRent(row.scenario, 'inPlaceRent'),
    cell: InputCell,
    meta: { name: 'units[0].inPlaceRent', ...dataTableMeta.textRight },
  },
  {
    id: 'marketRent',
    header: 'Market Rent',
    accessorFn: row => getRent(row.scenario, 'marketRent'),
    cell: InputCell,
    meta: { name: 'units[0].marketRent', ...dataTableMeta.textRight },
  },
  {
    id: 'compRent',
    header: 'Comp Rent',
    accessorFn: row => getRent(row.scenario, 'compRent'),
    cell: CompRentCell,
    meta: { name: 'units[0].compRent', ...dataTableMeta.textRight },
  },
  {
    id: 'capital',
    header: 'Renovation',
    accessorFn: row => getReturnMetric(row.scenario, 'totalCapital'),
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'stabilizationMonth',
    header: 'Stab. Month',
    accessorFn: row => row.scenario?.returnMetrics?.stabilizationMonth,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'taxes',
    header: 'Taxes',
    accessorFn: row => {
      const taxes = row.scenario?.returnMetrics?.taxes;
      return taxes ? sum(taxes.slice(0, 12)) : null;
    },
    cell: ({ getValue }) => (getValue() ? formatCurrency(getValue()) : '-'),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'hoa',
    header: 'HOA',
    accessorFn: row => {
      const hoaExpenseItem = row.scenario?.parameters?.expenseItems?.find(ei => ei.name === HOA_FEE_EXPENSE_ITEM_NAME);
      if (hoaExpenseItem) {
        const { inputMethod, inputValue } = hoaExpenseItem;
        return inputMethod === ITEMIZED_INPUT_METHOD_DPM ? inputValue * 12 : inputValue;
      } else {
        return null;
      }
    },
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'rollToMarket',
    header: 'Lease End Month',
    accessorFn: row => row.scenario?.parameters?.units?.[0]?.rollToMarket,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yield',
    header: 'Yield',
    accessorFn: row => getReturnMetric(row.scenario, 'stabilizedYield'),
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'irr',
    header: 'IRR',
    accessorFn: row => getReturnMetric(row.scenario, 'unleveredIrr'),
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'equityMultiple',
    header: 'Eq Mult',
    accessorFn: row => getReturnMetric(row.scenario, 'unleveredEquityMultiple'),
    cell: ({ getValue }) => formatEquityMultiple(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
];

const getColumns = ({ newBuildDeal, selectedProperties, setSelectedProperties, togglePropertyActive }) => {
  const columns = [
    ...COMMON_COLUMNS,
    ...(newBuildDeal ? NEW_BUILD_COLUMNS : []),
    ...INDIVIDUAL_COLUMNS,
  ];

  columns.push({
    id: 'buybox',
    header: 'Buy Box',
    accessorKey: 'property.buyBoxMatch',
    cell: BuyBoxCell,
  });

  columns.push({
    id: 'active',
    header: ActiveHeader,
    enableHiding: false,
    cell: ActiveCell,
    meta: {
      togglePropertyActive,
      selectedProperties,
      setSelectedProperties,
      textAlign: 'center',
      ...dataTableMeta.disableTableConfig,
    },
  });

  if (newBuildDeal) {
    const addressCellIdx = columns.indexOf(ADDRESS_COLUMN);
    columns[addressCellIdx] = { ...ADDRESS_COLUMN, header: 'Home Model' };
  }
  return columns;
};

function StatCell({ label, value }) {
  return (
    <div className="bg-white px-3 py-1.5 rounded shadow text-center">
      <div>{value}</div>
      <div className="text-xs text-gray-500">{label}</div>
    </div>
  );
}

const SummarySection = forwardRef(({ newBuildDeal, scenarios, tableData }, ref) => {
  const {
    stabilizedYield,
    unleveredIrr,
    unleveredEquityMultiple,
  } = useMemo(() => (scenarios.length ? calcReturnMetricsOfIndividualScenarios(scenarios) : {}), [scenarios]);

  const listPrice = dotProductBy(scenarios, 'parameters.listPrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  const underwrittenPrice = dotProductBy(scenarios, 'parameters.purchasePrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  const underwrittenListPercentDiff = (underwrittenPrice > 0) ? (1 - (underwrittenPrice / listPrice)) : null;
  const underwrittenListPercentDiffComp = (isNil(underwrittenListPercentDiff) || (underwrittenListPercentDiff === 0)) ? null : (
    <div className="flex items-center text-xs text-gray-600">
      <Arrow className="fill-gray-600 w-4" fill={null} direction={(underwrittenListPercentDiff > 0) ? 'down' : 'up'} />
      {`${formatPercentage(Math.abs(underwrittenListPercentDiff))} List`}
    </div>
  );

  const stats = [
    {
      label: newBuildDeal ? 'Home Models' : 'Properties',
      value: (scenarios.length < tableData.length) ? (
        <div className="flex justify-center items-center">
          <div>{scenarios.length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${tableData.length}`}</div>
        </div>
      ) : scenarios.length,
    },
    {
      label: 'List Price',
      value: formatCurrency(listPrice),
    },
    {
      label: 'Underwritten Price',
      value: underwrittenListPercentDiffComp ? (
        <div className="flex items-center gap-x-2">
          <div>{formatCurrency(underwrittenPrice)}</div>
          {underwrittenListPercentDiffComp}
        </div>
      ) : formatCurrency(underwrittenPrice),
    },
    {
      label: 'Yield',
      value: formatPercentage(stabilizedYield),
    },
    {
      label: 'IRR',
      value: formatPercentage(unleveredIrr),
    },
    {
      label: 'Equity Multiple',
      value: formatEquityMultiple(unleveredEquityMultiple),
    },
    {
      label: 'Buy Box Match',
      value: (
        <div className="flex justify-center items-center">
          <div>{tableData.filter(td => isEmpty(td.property.buyBoxMatch)).length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${tableData.length}`}</div>
        </div>
      ),
    },
  ];

  return (
    <div ref={ref} className="h-16 ml-12 flex items-center gap-x-4 border-b">
      {stats.map(stat => (
        <StatCell key={stat.label} {...stat} />
      ))}
    </div>
  );
});

export default function PortfolioDealSummary({ context }) {
  const dispatch = useDispatch();
  const { data, modelData } = context;
  const { deal, parcels, properties, homeModels } = data;
  const { model: { scenarios: persistedScenarios } } = modelData;
  const newBuildDeal = homeModels && !isEmpty(homeModels);
  const [initialScenarios, setInitialScenarios] = useState(persistedScenarios);
  const [scenarios, setScenarios] = useState(initialScenarios);
  const onReset = useCallback(() => setScenarios(initialScenarios), [initialScenarios, setScenarios]);
  const onScenarioChange = (scenarioId, path, value) => {
    setScenarios(previousScenarios => previousScenarios.map(scenario => {
      if (scenario.id === scenarioId) {
        const updatedParameters = cloneDeep(scenario.parameters);
        set(updatedParameters, path, value);
        const returnMetrics = calcReturnMetrics(calcCashFlows(updatedParameters), updatedParameters);
        return { ...scenario, returnMetrics, parameters: updatedParameters };
      } else {
        return scenario;
      }
    }));
  };
  if (newBuildDeal) {
    const findMin = ({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => (
      Math.max(
        multiplicity ?? 0,
        homeModelAddresses?.length ?? 0,
        sum(homeModelDeliverySchedule?.map(([, value]) => value)),
      )
    );

    if (scenarios.some(({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => {
      const minMultiplicity = findMin({ parameters: { homeModelAddresses, homeModelDeliverySchedule } });
      return (multiplicity ?? 0) < minMultiplicity;
    })) {
      setScenarios((prev) => (
        prev.map((scenario) => ({
          ...scenario,
          parameters: {
            ...scenario.parameters,
            multiplicity: findMin(scenario),
          },
        }))
      ));
    }
  }
  const [selectedProperties, setSelectedProperties] = useState(properties.reduce((result, property) => ({ [property.id]: true, ...result }), {}));
  const togglePropertyActive = useCallback(propertyId => {
    setSelectedProperties(prevSelected => ({ ...prevSelected, [propertyId]: !prevSelected[propertyId] }));
  }, [setSelectedProperties]);
  const [updateScenariosMutation] = useUpdateScenariosMutation();

  const columns = useMemo(
    () => getColumns({
      newBuildDeal,
      selectedProperties,
      setSelectedProperties,
      togglePropertyActive,
    }),
    [newBuildDeal, selectedProperties, setSelectedProperties, togglePropertyActive],
  );

  const tableData = useMemo(() => properties.map(property => {
    const parcel = getParcel(property, parcels);
    const scenario = getScenario(property, scenarios);
    const initialScenario = getScenario(property, initialScenarios);
    const normalizedProperty = coalesceProperty(property, parcel, null, getUnits(property, scenario));
    return {
      parcel,
      property: normalizedProperty,
      initialScenario,
      scenario,
    };
  }), [initialScenarios, parcels, properties, scenarios]);

  const selectedScenarios = useMemo(
    () => scenarios.filter(s => s.primary && selectedProperties[s.propertyId]),
    [scenarios, selectedProperties],
  );

  const containerRef = useRef();
  const containerHeight = useElementHeight(containerRef);
  const filterRef = useRef();
  const filterHeight = useElementHeight(filterRef);
  const summaryRef = useRef();
  const summaryHeight = useElementHeight(summaryRef);

  const pendingChanges = useMemo(
    () => !isEqual(initialScenarios.map(s => s.parameters), scenarios.map(s => s.parameters)),
    [scenarios, initialScenarios],
  );

  const onSave = useCallback(async (onSuccess) => {
    const updatedScenarios = scenarios.filter(s => s.parameters !== initialScenarios.find(is => is.id === s.id).parameters);
    const response = await updateScenariosMutation({ dealId: deal.id, scenarios: updatedScenarios });
    if (response.error) {
      console.error(response.error);
    } else {
      const newScenarios = persistedScenarios.map(scenario => {
        const updatedScenario = response.data.scenarios.find(s => s.id === scenario.id);
        if (updatedScenario) {
          return updatedScenario;
        } else {
          return { ...scenario };
        }
      });
      setScenarios(newScenarios);
      setInitialScenarios(newScenarios);
      onSuccess();
    }
  }, [scenarios, initialScenarios]);

  useEffect(() => {
    if (pendingChanges) {
      dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: onReset, save: partial(onSave, () => dispatch(clearToast())) })));
    } else {
      dispatch(clearToast());
    }
  }, [dispatch, pendingChanges, onReset, onSave]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(pendingChanges);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        onReset();
        dispatch(clearToast());
        proceed();
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, partial(onSave, () => proceed())));
    }
  }, [blockerState]);

  const filterOptions = useMemo(() => ({
    market: uniq(tableData.map(td => td.property.market)).sort(),
    propertyType: uniq(tableData.map(td => td.property.propertyType)).sort(),
  }), [tableData]);
  const bedRangeDisplayValue = useRangeDisplayValue({ suffix: ' bd' });
  const bathRangeDisplayValue = useRangeDisplayValue({ suffix: ' ba' });
  const sqftRangeDisplayValue = useRangeDisplayValue({ suffix: ' sqft' });
  const basicRangeDisplayValue = useRangeDisplayValue();
  const yieldRangeDisplayValue = useRangeDisplayValue({ formatter: formatPercentage, suffix: ' yield' });
  const currencyRangeDisplayValue = useRangeDisplayValue({ formatter: formatCurrency });

  const tableId = getTableId({ newBuildDeal });
  return (
    <div
      className="bg-gray-50"
      ref={containerRef}
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <DataTable
        columns={columns}
        data={tableData}
        tableHeight={containerHeight - (filterHeight + summaryHeight)}
        initialState={TABLE_INITIAL_STATE}
        meta={{ onScenarioChange, ...enableConfigPresets({ presets: CONFIG_PRESETS[tableId] }) }}
      >
        <div className="w-full">
          <SummarySection
            ref={summaryRef}
            newBuildDeal={newBuildDeal}
            scenarios={selectedScenarios}
            tableData={tableData}
          />
          <div
            ref={filterRef}
            className="flex justify-between gap-x-8 px-3 py-1 items-center bg-white"
          >
            <ReactTableFilter>
              <ChipGroup>
                {(filterOptions.market.length > 1) && (
                  <ReactTableColumnFilter
                    label="Market"
                    columnId="market"
                    displayValue="Market"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.market}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                {(filterOptions.propertyType.length > 1) && (
                  <ReactTableColumnFilter
                    label="Property Type"
                    columnId="propertyType"
                    displayValue="Property Type"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.propertyType}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                <ReactTableColumnFilter
                  label="Beds"
                  columnId="bed"
                  displayValue={bedRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Baths"
                  columnId="bath"
                  displayValue={bathRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Sq Ft"
                  columnId="sqft"
                  displayValue={sqftRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Year Built"
                  columnId="yearBuilt"
                  displayValue={basicRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="List Price"
                  columnId="listPrice"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="In-Place Rent"
                  columnId="inPlaceRent"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Market Rent"
                  columnId="marketRent"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Yield"
                  columnId="yield"
                  displayValue={yieldRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="percent" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Matches Buy Box"
                  displayValue={meetsBuyBoxFilterDisplayFn}
                  columnId="isBuyBoxMatch"
                >
                  <BooleanFilterChip leadingIcon={false}>
                    <TrueFalseToggle />
                  </BooleanFilterChip>
                </ReactTableColumnFilter>
              </ChipGroup>
              <FilterMessage tableData={tableData} />
            </ReactTableFilter>
            <DataTableConfig tableId={tableId}>
              <TableConfigButton />
            </DataTableConfig>
          </div>
          <DataTableContent />
        </div>
      </DataTable>
    </div>
  );
}
