import { mean } from 'lodash';
import { formatCurrency, formatInteger, scoreWeightedPrice } from 'components/utils';

function ShortSummaryItem({ label, value }) {
  return (
    <div className="flex flex-col items-center gap-y-0.5 my-2 px-4">
      <div className="text-xl">{value ?? '-'}</div>
      <div className="text-neutral-light font-medium text-xs tracking-wide">{label}</div>
    </div>
  );
}

export default function ShortSummary({ activeSaleCompSet, comps }) {
  const closedComps = comps.filter(comp => comp.closePrice);
  const averageClosePrice = closedComps.length ? mean(closedComps.map(comp => comp.closePrice)) : null;
  const weightedPrice = closedComps.length ? scoreWeightedPrice(comps) : null;

  return (
    <div className="flex flex-row justify-end flex-grow divide-x">
      <ShortSummaryItem label="Selected Comps" value={formatInteger(comps.length)} />
      <ShortSummaryItem label="Avg Price" value={formatCurrency(averageClosePrice)} />
      <ShortSummaryItem label="Weighted Price" value={formatCurrency(weightedPrice)} />
      <ShortSummaryItem label="Underwritten Value" value={formatCurrency(activeSaleCompSet?.compPrice || '-')} />
    </div>
  );
}
